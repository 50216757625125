import { BreadcrumbsSistel, ButtonSistel } from 'npm-styles-sistel'

export default function EmployeeHeader({
  employee,
  showDelete,
  showEdit,
  goEmpleados
}) {
  return (
    <div className="flex-between">
      <BreadcrumbsSistel
        baseLink={goEmpleados}
        baseTitle="Empleados"
        current={`${employee.name} ${employee.lastname}`}
      />
      <div className="mb-5">
        <ButtonSistel danger onClick={() => showDelete()}>
          Dar de baja
        </ButtonSistel>
        <ButtonSistel edit className="ml-2" onClick={() => showEdit('form')}>
          Editar empleado
        </ButtonSistel>
      </div>
    </div>
  )
}
