import BoxAttachments from './BoxAttachments'
import { Attachments } from '.'
import { FUSIONADOR } from 'utils/constants'
import { DataGroupSistel } from 'npm-styles-sistel'

export default function ValidatedEmployeeDelivery({ data }) {
  const populatedSupervisor = data.validator

  const attachmentComponent =
    data.order.type === FUSIONADOR ? (
      <BoxAttachments data={data.boxPhotos} />
    ) : (
      <Attachments data={data.photos} />
    )

  const supervisorName = populatedSupervisor
    ? `${populatedSupervisor.name} ${populatedSupervisor.lastname}`
    : '-'

  return (
    <div>
      <UsersAndBrigadeInfo
        users={data.users}
        brigadeComment={data.brigadeComment}
        employeeComment={
          <DataGroupSistel
            title="Comentarios del empleado"
            data={data.employeeComment || 'Sin datos'}
          />
        }
      />
      <div className="row">{attachmentComponent}</div>

      <BaremoTable baremoList={data.baremoEntries} />

      <DataGroupSistel
        title={`Comentarios del supervisor | ${supervisorName}`}
        data={data.supervisorComment || 'Sin datos'}
      />
    </div>
  )
}

function UsersAndBrigadeInfo({ users, brigadeComment, employeeComment }) {
  const displayUsers = users.map((user) => (
    <span style={{ display: 'block' }} key={user._id} className="data-info">
      {user.name} {user.lastname}
    </span>
  ))

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <DataGroupSistel title="Usuarios" data={displayUsers} />
        </div>
        <div className="col-md-6">
          <DataGroupSistel
            title="Comentarios de la brigada"
            data={brigadeComment || 'Sin comentarios'}
          />
          {employeeComment}
        </div>
      </div>
    </>
  )
}

function BaremoTable({ baremoList }) {
  const displayTableElements = baremoList.map((element) =>
    element.qty ? (
      <tr key={element.baremo._id}>
        <td scope="row">{element.baremo.description}</td>
        <td align="right">{element.qty}</td>
      </tr>
    ) : null
  )

  return (
    <div>
      <table className="table table-striped ">
        <thead>
          <tr>
            <th scope="col">Baremo</th>
            <th scope="col" className="text-right">
              Cantidad
            </th>
          </tr>
        </thead>
        <tbody>{displayTableElements}</tbody>
      </table>
    </div>
  )
}
