import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputSelectSistel, InputSistel } from 'npm-styles-sistel'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { postApi } from 'redux/actions/fetchActions'
import Row from 'components/common/Row'
import { postNotifications } from 'redux/actions/notificationActions'

export default function NotificationForm({
  handleClose,
  allUsers,
  multiplier
}) {
  const dispatch = useDispatch()
  const [currentNotification] = useState(initialValues())

  const formik = useFormik({
    initialValues: currentNotification,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      const instanceBody = {
        ...values,
        qty: qty * multiplier,
        users: values.users.map((u) => u.value)
      }

      dispatch(
        postApi(instanceBody, '/notification', [postNotifications, handleClose])
      )
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik

  const handleSelectMulti = (field) => (e) => setFieldValue(field, e)

  const { comments, qty, users } = values

  const userOptions = allUsers.map((u) => ({
    label: `${u.name} ${u.lastname}`,
    value: u._id
  }))

  return (
    <form className="mt-5" onSubmit={handleSubmit} id="form">
      <Row>
        <InputSistel
          type="text"
          label="Comentarios"
          name="comments"
          col="col-md-12"
          value={comments}
          errors={errors.comments}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          min="0"
          step="1"
          label="Cantidad de puntos"
          name="qty"
          col="col-md-12"
          value={qty}
          errors={errors.qty}
          onChange={handleChange}
        />
        <InputSelectSistel
          label="Usuarios"
          name="users"
          col="col-md-12"
          isMulti
          placeholder="Selecciona trabajadores..."
          errors={errors.users}
          options={userOptions}
          value={users || []}
          onChange={handleSelectMulti('users')}
        />
      </Row>
    </form>
  )
}

function initialValues() {
  return {
    comments: '',
    qty: 0,
    users: [],
    type: 'Points'
  }
}

function validationSchema() {
  return {
    comments: Yup.string(),
    qty: Yup.number(),
    users: Yup.array().min(1)
  }
}
