export const GET_CLIENTS = 'GET_CLIENTS'
export const POST_CLIENT = 'POST_CLIENT'
export const PUT_CLIENT = 'PUT_CLIENT'
export const PATCH_CLIENT = 'PATCH_CLIENT'

// ACTIONS
export const getClients = (data) => ({
  type: GET_CLIENTS,
  payload: data
})

export const postClient = (data) => ({
  type: POST_CLIENT,
  payload: data
})

export const putClient = (data) => ({
  type: PUT_CLIENT,
  payload: data
})

export const patchClient = (data) => ({
  type: PATCH_CLIENT,
  payload: data
})
