import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'
import { CardGradientPrimary } from './dashboardStyles'

const Bar = styled.div`
  height: 25px;
  position: relative;
  width: 100%;
  background-color: rgb(213 209 225 / 0.3);
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 24px;
`
const ValidatedBar = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ validated }) => validated}%;
  background: ${themeSistel.colors.success_mid};
  border-radius: 24px;
`

export default function DashboardProgressBar({ employees }) {
  const formatedEmployees = employees.map((e) => {
    const sumPoints = (curr, acc) => curr + acc.qty
    const totalPoints = e.points.reduce(sumPoints, 0)
    return {
      ...e,
      totalPoints,
      hasAchievedTarget: totalPoints > e.department.monthlyGoal
    }
  })

  const numberEmployeesValidated = formatedEmployees.filter(
    (e) => e.hasAchievedTarget
  ).length

  const validatedProgress =
    (100 * numberEmployeesValidated) / formatedEmployees.length

  return (
    <CardGradientPrimary>
      <Bar>
        <ValidatedBar validated={validatedProgress} />
      </Bar>
      <p>Objetivos cumplidos</p>
      <h1 className="data">
        {numberEmployeesValidated}/{formatedEmployees.length}
      </h1>
    </CardGradientPrimary>
  )
}
