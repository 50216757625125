import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import MainContainer from 'components/common/MainContainer'
import LastDeliveriesTable from 'components/Tables/LastDelivieriesTable'

import useFilter from 'hooks/useFilter'
import useLatestDeliveries from 'hooks/useLatestDeliveries'
import { putApi } from 'redux/actions/fetchActions'

import {
  ConfirmationModalSistel,
  HeaderFiltersSistel,
  LoaderSistel
} from 'npm-styles-sistel'

export default function UltimasEntregas() {
  const me = useSelector((s) => s.me)
  const dispatch = useDispatch()

  const [showConfirm, setShowConfirm] = useState(null)
  const [deliveries, loading, refetch] = useLatestDeliveries()
  const [handleChange, foundDeliveries] = useFilter(deliveries, ['title'])

  if (loading) return <LoaderSistel />

  const handleClose = () => setShowConfirm(null)

  const handleConfirmDocumentated = (deliveryId) => {
    const confirmDocumented = () => {
      const instanceBody = {
        isDocumented: true,
        documentedUser: me._id,
        _id: deliveryId
      }
      const editCb = () => {
        handleClose()
        refetch()
      }
      dispatch(
        putApi(instanceBody, `/employeeDelivery/document/${deliveryId}`, [
          null,
          editCb
        ])
      )
    }
    setShowConfirm(
      <ConfirmationModalSistel
        modalContent="¿Quieres marcar esta entrega como documentada?"
        title="Marcar como documentado"
        onClose={handleClose}
        modalFunction={confirmDocumented}
      />
    )
  }

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="documentos"
        title="Últimas entregas"
        handleSearch={handleChange}
      />
      <LastDeliveriesTable
        data={foundDeliveries}
        handleDocument={handleConfirmDocumentated}
      />
      {showConfirm}
    </MainContainer>
  )
}
