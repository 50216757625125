import MainContainer from 'components/common/MainContainer'
import { EmployeeCardStyled } from 'components/Employees/employeeStyles'
import PasswordForm from 'components/forms/PasswordForm'

import { AvatarImageSistel, BadgeSistel, IconosSistel } from 'npm-styles-sistel'
import { useSelector } from 'react-redux'

export default function Perfil() {
  const me = useSelector((s) => s.me)

  return (
    <MainContainer>
      <div className="d-flex mb-5">
        <IconosSistel icon="configuracion" />
        <h1 className="ml-2">Ajustes</h1>
      </div>
      <div className="row">
        <div className="col-md-4">
          <EmployeeCardStyled>
            <div className="card">
              <AvatarImageSistel image={me.avatar} width="80px" />
              <h2 className="mt-3">
                {me.name} {me.lastname}
              </h2>
              <BadgeSistel>{me.role}</BadgeSistel>
            </div>
          </EmployeeCardStyled>
        </div>
        <div className="col-md-6">
          <PasswordForm />
        </div>
      </div>
    </MainContainer>
  )
}
