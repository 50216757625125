import { TOKEN, USER } from 'utils/constants'

import { LOGIN, LOGOUT } from '../actions/meActions'

const initialState = JSON.parse(localStorage.getItem(USER)) || null

// TODO: Convertir en un context con el user y la company
export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem(TOKEN, JSON.stringify(action.token))
      localStorage.setItem(USER, JSON.stringify(action.user))
      return { ...action.user, token: action.token }
    case LOGOUT: {
      localStorage.removeItem(TOKEN)
      localStorage.removeItem(USER)
      return null
    }
    default:
      return state
  }
}
