import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Row from 'components/common/Row'
import {
  ButtonSistel,
  InputCheckboxSistel,
  InputSelectSistel,
  InputSistel
} from 'npm-styles-sistel'
import { postApi, putApi } from 'redux/actions/fetchActions'
import { postUser, putUser } from 'redux/actions/userActions'
import FileService from 'services/FileService'
import AddAvatar from 'components/Employees/AddAvatar'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { getDepartments } from 'redux/actions/departmentActions'

export default function EmployeeForm({ user, handleClose }) {
  const dispatch = useDispatch()
  const departments = useDynamicFetch('department', getDepartments)
  const [currentUser] = useState(initialValues(user))

  const formik = useFormik({
    initialValues: currentUser,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)

      const avatarURL = await FileService.uploadFiles(
        values.avatarToUpload.map(({ file }) => file)
      )

      const instanceBody = {
        ...values,
        avatar: avatarURL[0] || values.avatar
      }

      if (user) {
        return dispatch(
          putApi(instanceBody, `/user/${values._id}`, [putUser, handleClose])
        )
      }

      dispatch(postApi(instanceBody, '/signup', [postUser, handleClose]))
    }
  })

  const {
    handleSubmit,
    errors,
    handleChange,
    values,
    setFieldValue,
    isSubmitting
  } = formik

  const departmentOptions = departments.data.map((d) => {
    return {
      label: d.name,
      value: d._id,
      role: d.role
    }
  })

  const {
    name,
    lastname,
    dni,
    phoneNumber,
    department,
    canBeManager,
    driverLicense
  } = values

  const handleFile = (imageFiles) => setFieldValue('avatarToUpload', imageFiles)
  const handleSelect = (field) => (e) => setFieldValue(field, e.value)
  const handleCheckbox =
    (field) =>
    ({ target }) =>
      setFieldValue(field, target.checked)

  const buttonText = isSubmitting ? 'Guardando' : 'Guardar'

  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <Row>
        <div className="col-md-2">
          <AddAvatar
            images={values.avatarToUpload}
            currentImage={user?.avatar || '/images/avatar_default.jpg'}
            setImages={handleFile}
          />
        </div>
        <div className="col-md-10">
          <Row>
            <InputSistel
              type="text"
              label="Nombre"
              col="col-md-6"
              name="name"
              value={name}
              errors={errors.name}
              onChange={handleChange}
            />
            <InputSistel
              type="text"
              label="Apellidos"
              col="col-md-6"
              name="lastname"
              value={lastname}
              errors={errors.lastname}
              onChange={handleChange}
            />
            <InputSistel
              type="text"
              label="DNI o NIE"
              col="col-md-6"
              name="dni"
              value={dni}
              errors={errors.dni}
              onChange={handleChange}
            />
            <InputSistel
              type="text"
              label="Teléfono"
              col="col-md-6"
              name="phoneNumber"
              value={phoneNumber}
              errors={errors.phoneNumber}
              onChange={handleChange}
            />
            <InputSelectSistel
              label="Departamento"
              name="department"
              col="col-md-12"
              placeholder="Selecciona departamento..."
              errors={errors.department}
              options={departmentOptions}
              value={department}
              onChange={handleSelect('department')}
            />
            <div className="col-auto mt-4 d-flex">
              <InputCheckboxSistel
                label="Carnet de conducir"
                name="driverLicense"
                defaultChecked={driverLicense}
                value={driverLicense}
                errors={errors.driverLicense}
                onChange={handleCheckbox('driverLicense')}
              />
              <InputCheckboxSistel
                label="Puede ser responsable"
                name="canBeManager"
                defaultChecked={canBeManager}
                value={canBeManager}
                errors={errors.canBeManager}
                onChange={handleCheckbox('canBeManager')}
              />
            </div>
          </Row>
        </div>
        <div className="col-md-12">
          <div className="d-flex justify-content-end">
            <ButtonSistel
              edit
              className="mr-2"
              type="button"
              onClick={handleClose}
            >
              Cancelar
            </ButtonSistel>
            <ButtonSistel
              success={!isSubmitting}
              type="submit"
              disabled={isSubmitting}
              className="float-right"
            >
              {buttonText}
            </ButtonSistel>
          </div>
        </div>
      </Row>
    </form>
  )
}

function initialValues(user) {
  return {
    _id: user?._id,
    name: user?.name || '',
    lastname: user?.lastname || '',
    dni: user?.dni || '',
    phoneNumber: user?.phoneNumber || '',
    department: user?.department?._id || '',
    driverLicense: user?.driverLicense || false,
    canBeManager: user?.canBeManager || false,
    avatarToUpload: [],
    avatar: user?.avatar || undefined
  }
}

function validationSchema() {
  return {
    name: Yup.string().required('El nombre es obligatorio'),
    lastname: Yup.string().required('Apellido obligatorio'),
    dni: Yup.string().required('Documento de identidad obligatorio'),
    phoneNumber: Yup.string(),
    department: Yup.string().required('El departamento es obligatorio'),
    driverLicense: Yup.bool(),
    canBeManager: Yup.bool()
  }
}
