import { BadgeSistel } from 'npm-styles-sistel'
import { DeliveryCardStyled } from '../orderStyle'

export default function BoxDetails({ box }) {
  return (
    <DeliveryCardStyled>
      <div className="info">
        <div className="date">
          <div className="mr-4">
            <h2>{box.name}</h2>
            <p className="uuii">{box.uuii} UUII</p>
          </div>
          <BadgeSistel grey>Sin entrega</BadgeSistel>
        </div>
      </div>
    </DeliveryCardStyled>
  )
}
