import useStateWithDep from 'hooks/useStateWithDep'
import { useMemo } from 'react'
import Table from './Table'

export default function DepartmentsTable({ data, cb }) {
  const [departments, setDepartments] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      departments.map((dep) => ({
        name: (
          <p className="table-link" onClick={() => cb(dep)}>
            {dep.name}
          </p>
        ),
        role:
          dep.role === 'Worker'
            ? 'Trabajador'
            : dep.role === 'Admin'
            ? 'Administrador'
            : dep.role === 'Designer'
            ? 'Diseñador'
            : dep.role,
        monthlyGoal: { value: dep.monthlyGoal || '-', className: 'text-right' },
        key: dep._id
      })),
    [departments]
  )

  const headers = useMemo(
    () => [
      { header: 'Nombre', sort: true },
      { header: 'Rol', sort: false },
      { header: 'Objetivo mensual', sort: false, className: 'text-right' }
    ],
    []
  )

  return (
    <Table
      {...{
        data: departments,
        tableData,
        headers,
        cb: setDepartments,
        col: 'col-md-12'
      }}
    />
  )
}
