import { LightboxImageSistel } from 'npm-styles-sistel'
import styled from 'styled-components'

export const GrupoAdjuntos = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`

export default function BoxAttachments({ data }) {
  const photoKeys = Object.keys(data)

  const titleHashMap = {
    panoramic: 'panoramicas',
    closedBox: 'de torpedo/CTO cerrado',
    openBox: 'de torpedo/CTO abierto',
    cableLabeling: 'cableado',
    powerMeasure: 'medición de potencia',
    bells: 'timbres',
    portal: 'portal',
    ctoTrays: 'bandejas de torpedo/CTO'
  }

  const displayImageFields = photoKeys.map((selector) => (
    <div className="col-md-12" key={selector}>
      {data[selector]?.length > 0 && (
        <>
          <h2 className="title-fotos">Fotos {titleHashMap[selector]}</h2>
          <GrupoAdjuntos>
            <LightboxImageSistel images={data[selector]} />
          </GrupoAdjuntos>
        </>
      )}
    </div>
  ))

  return displayImageFields
}
