import BoxCard from 'components/DocsManager/BoxCard'
import NoResults from 'components/common/NoResults'
import BreadCrumbs from 'components/DocsManager/BreadCrumbs'
import MainContainer from 'components/common/MainContainer'
import useDocumentsCluster from 'hooks/useDocumentsCluster'
import { downloadImgZip } from 'utils/zip'
import { CELADOR, FUSIONADOR } from 'utils/constants'
import { LightboxImageSistel, LoaderSistel } from 'npm-styles-sistel'

export default function GestorDocumentalCluster({ match }) {
  const { clusterRef, projectRef } = match.params

  const [project, loading] = useDocumentsCluster(projectRef, clusterRef)

  if (loading) return <LoaderSistel />

  const celadorOrders = project.orders.filter((o) => o.type === CELADOR)
  const fusionadorOrders = project.orders.filter((o) => o.type === FUSIONADOR)

  const supervisorImgs = getImgs(project.orders, 'attachments')
  const celadorImgs = celadorOrders.flatMap((o) =>
    getImgs(o.employeeDeliveries, 'photos')
  )

  const displayBoxCards = fusionadorOrders.flatMap((o) =>
    o.boxList.map((box) => {
      const delivery = o.employeeDeliveries.find((d) => d.boxId === box._id)
      return (
        <BoxCard
          key={box._id}
          box={box}
          delivery={delivery}
          cb={downloadImgZip}
        />
      )
    })
  )

  return (
    <MainContainer>
      <BreadCrumbs
        projectTitle={project.title}
        projectRef={projectRef}
        cluster={clusterRef}
      />
      <div className="row">
        <div className="col-md-4">
          <div className="card card-dark">
            <h1>{clusterRef}</h1>
          </div>

          {supervisorImgs.length ? (
            <div>
              <LightboxImageSistel images={supervisorImgs} hasMain />
              {supervisorImgs.length > 1 && (
                <p>{supervisorImgs.length} imágenes en total</p>
              )}
            </div>
          ) : (
            <NoResults text="No hay imágenes del supervisor" />
          )}
          <h3 className="title-underlined">Fotos celadores</h3>
          {celadorOrders.length ? (
            <LightboxImageSistel images={celadorImgs} />
          ) : (
            <NoResults text="No hay fotos de celadores" />
          )}
        </div>
        <div className="col-md-8 container-boxes">
          <div className="px-2">{displayBoxCards}</div>
        </div>
      </div>
    </MainContainer>
  )
}

function getImgs(arr, key) {
  return arr.reduce(
    (acc, o) =>
      o[key].length
        ? acc.length === 0
          ? [...o[key]]
          : [...acc, ...o[key]]
        : acc,
    []
  )
}
