import axios from 'axios'
import { useEffect, useState } from 'react'

export default function useEditCreateOrder(orderRef, type) {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)

  const getData = async () => {
    try {
      const url = new URL('/order/action', 'http://localhost:5000')
      if (orderRef) url.searchParams.append('ref', orderRef)
      if (type) url.searchParams.append('type', type)
      const response = await axios.get(`/order/action${url.search}`)
      if (response?.status < 400) {
        return response.data
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData().then((data) => {
      if (data) {
        setData(data)
        setIsLoading(false)
      }
    })
  }, [])

  return [isLoading, data]
}
