import { useState } from 'react'

import NotificationForm from 'components/forms/NotificationForm'
import NotificationsTable from 'components/Tables/NotificationsTable'
import { populateInstance } from 'utils/maps'
import useFilter from 'hooks/useFilter'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { getNotifications } from 'redux/actions/notificationActions'
import { getUsers } from 'redux/actions/userActions'

import { HeaderFiltersSistel, LoaderSistel, ModalSistel } from 'npm-styles-sistel'
import MainContainer from 'components/common/MainContainer'

export default function PuntosGestion() {
  const { data, loaded } = useDynamicFetch('notification', getNotifications)
  const allUsers = useDynamicFetch('user', getUsers)

  const formatedData = data
    .map((not) => ({
      ...not,
      users: not.users.map((u) =>
        typeof u === 'string' ? populateInstance(allUsers.data, u) : u
      )
    }))
    .reverse()

  const [show, setShow] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalIcon, setModalIcon] = useState('')
  const [multiplier, setMultiplier] = useState(null)

  const handleClose = () => {
    setShow(false)
  }

  const handlePenalty = () => {
    setMultiplier(-1)
    setModalTitle('Nueva penalización')
    setModalIcon('puntosPenalizacion')
    setShow(true)
  }

  const handleBonus = () => {
    setMultiplier(1)
    setModalTitle('Nueva bonificación')
    setModalIcon('puntosBono')
    setShow(true)
  }

  const [handleChange, filteredNotifications] = useFilter(formatedData, [
    'comments',
    'users'
  ])

  if (!loaded || !allUsers.loaded) return <LoaderSistel />

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="puntos"
        title="Gestión de puntos"
        buttonText="Bonificación"
        buttonAction={handleBonus}
        dangerButtonText="Penalización"
        dangerbuttonAction={handlePenalty}
        handleSearch={handleChange}
      />

      <NotificationsTable data={filteredNotifications} />
      {show && (
        <ModalSistel handleClose={handleClose} title={modalTitle} iconTitle={modalIcon} size="xs">
          <NotificationForm
            allUsers={allUsers.data}
            handleClose={handleClose}
            multiplier={multiplier}
          />
        </ModalSistel>
      )}
    </MainContainer>
  )
}
