import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import Table from './Table'
import useStateWithDep from 'hooks/useStateWithDep'
import { euroFormat } from 'utils/strings'
import { AvatarImageSistel } from 'npm-styles-sistel'

export default function PointsSummaryTable({ data, company }) {
  const [users, setUsers] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      users
        .filter((u) => u.department?.role === 'Worker')
        .map((user) => ({
          name: (
            <div className="flex-center">
              <AvatarImageSistel image={user.avatar} width="42px" />
              <Link
                to={`/empleados/${user._id}`}
                className="table-link mb-1 mx-3"
              >{`${user.name} ${user.lastname}`}</Link>
            </div>
          ),
          target: (
            <div className="flex-center">
              <progress
                className={user.points > user.target ? 'success ' : ''}
                title={`${Math.round(user.points)}/${user.target}`}
                value={Math.round(user.points)}
                max={user.target}
              />
              <p className="ml-3 goals-text">
                {Math.round(user.points)}/{user.target}
              </p>
            </div>
          ),
          total: {
            value: (
              <p
                className={
                  company.config.pointPrice * user.extraPoints > 0
                    ? 'success-text'
                    : ''
                }
              >
                {euroFormat(user.total)}
              </p>
            ),
            className: 'text-right'
          },
          key: user._id
        })),
    [users]
  )

  const headers = useMemo(
    () => [
      { header: 'Empleado', sort: true },
      {
        header: 'Puntos/Objetivo',
        sort: true,
        sortField: 'progress',
        reverse: true
      },
      { header: 'Total', sort: true, className: 'text-right', reverse: true }
    ],
    []
  )

  return <Table {...{ data: users, tableData, headers, cb: setUsers }} />
}
