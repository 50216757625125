import { useState } from 'react'

import useFilter from 'hooks/useFilter'
import useProjects from 'hooks/useProjects'
import useCheckboxFilter from 'hooks/useCheckbox'

import MainContainer from 'components/common/MainContainer'
import ProjectsTable from 'components/Tables/ProjectsTable'
import { ProjectForm } from 'components/forms'
import {
  HeaderFiltersSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'

export default function Proyectos() {
  const [show, setShow] = useState(null)
  const [data, fetching, refetch] = useProjects()
  const { projects, clients } = data

  const [handleChange, foundProjects] = useFilter(projects, ['ref', 'title'])

  const [displayClientsFilter, filteredProjects] = useCheckboxFilter(
    [{ key: 'client', subKey: '_id' }],
    clients,
    foundProjects
  )

  if (fetching) return <LoaderSistel />

  const modal = (
    <ModalSistel
      title="Nuevo proyecto"
      iconTitle="proyectoNuevo"
      handleClose={() => setShow(null)}
      buttons={false}
    >
      <ProjectForm
        handleClose={() => setShow(null)}
        clients={clients}
        refetch={refetch}
      />
    </ModalSistel>
  )

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="proyectos"
        title="Proyectos"
        handleSearch={handleChange}
        buttonAction={() => setShow(modal)}
        buttonText="Crear proyecto"
        filters={
          <div style={{ width: '300px' }}>
            <h3>Clientes</h3>
            {displayClientsFilter}
          </div>
        }
      />
      <ProjectsTable data={filteredProjects} />
      {show}
    </MainContainer>
  )
}
