import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { IconosSistel } from 'npm-styles-sistel'

const CardClusterStyled = styled.div`
  width: 20%;
  padding: 10px;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 20px;
    padding: 24px;
    transition: all 0.6s ease;

    .folder {
      width: 100px;
    }

    .title {
      font-weight: 500;
      margin-top: 16px;
      text-align: center;
      font-size: 14px;
    }
  }

  &:hover {
    .content {
      background: #f1edfb;
    }
  }
`

export default function CardCluster({ cluster, project }) {
  const history = useHistory()

  return (
    <CardClusterStyled
      onClick={() => history.push(`/documentos/${project}/${cluster}`)}
    >
      <div className="content">
        <IconosSistel icon="carpeta" width="70" />
        <p className="title">{cluster}</p>
      </div>
    </CardClusterStyled>
  )
}
