import useFinishedOrders from 'hooks/useFinishedOrders'
import useCheckboxFilter from 'hooks/useCheckbox'
import useFilter from 'hooks/useFilter'
import { CERTIFIED, FINISHED } from 'utils/constants'

import MainContainer from 'components/common/MainContainer'
import CertifiedOrders from 'components/Orders/CertifiedOrders'
import FinishedOrders from 'components/Orders/FinishedOrders'
import { StateColumnTitleStyled } from 'components/Orders/orderStyle'

import { HeaderFiltersSistel, LoaderSistel } from 'npm-styles-sistel'

export default function OrdenesFinalizadas() {
  // TODO: Implementar carga con scroll de la columna
  const [data, fetching, refetch] = useFinishedOrders()
  const { orders, projects } = data

  const projectChecks = projects.map((project) => ({
    _id: project?._id,
    name: project?.title
  }))

  const [handleChange, foundOrders] = useFilter(orders, ['cluster', 'ref'])

  const [displayProjectChecks, filteredOrders] = useCheckboxFilter(
    [{ key: 'project', subKey: '_id' }],
    projectChecks,
    foundOrders
  )

  if (fetching) return <LoaderSistel />

  const certifiedPopulated = filteredOrders.filter(
    (o) => o.status === CERTIFIED
  )

  const finishedPopulated = filteredOrders.filter((o) => o.status === FINISHED)

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="ordenesFinalizadas"
        title="Órdenes finalizadas"
        handleSearch={handleChange}
        filters={
          <>
            <h4>Proyecto</h4>
            {displayProjectChecks}
          </>
        }
      />
      <div className="row">
        <div className="col-md-6">
          <StateColumnTitleStyled>
            <h3>
              Finalizadas <span>{finishedPopulated.length}</span>
            </h3>
          </StateColumnTitleStyled>
          {finishedPopulated.map((order) => (
            <FinishedOrders order={order} key={order._id} refetch={refetch} />
          ))}
        </div>
        <div className="col-md-6">
          <StateColumnTitleStyled>
            <h3>
              Certificadas <span>{certifiedPopulated.length}</span>
            </h3>
          </StateColumnTitleStyled>
          {certifiedPopulated.map((order) => (
            <CertifiedOrders order={order} key={order._id} />
          ))}
        </div>
      </div>
    </MainContainer>
  )
}
