import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Table from 'components/Tables/Table'
import GapReason from './GapReason'
import useStateWithDep from 'hooks/useStateWithDep'
import { GapOrderReason } from './orderStyle'
// import { Dropdown } from 'react-bootstrap'
// import { IconosSistel } from 'npm-styles-sistel'
// import SelectBrigade from 'components/forms/FastActionForms/SelectBrigade'

export default function TableGapOrders({ data, ...rest }) {
  const [orders, setOrders] = useStateWithDep(data)

  // TODO: Activar botón de acción rápida
  const tableData = useMemo(
    () =>
      orders.map((order) => ({
        cluster: (
          <Link to={`/ordenes/${order.ref}`} className="table-link">
            {order.cluster}
          </Link>
        ),
        project: order.project?.title,
        gaps: order.gaps.map((gap, i) => (
          <GapOrderReason key={i} reason={gap.reason}>
            <p className="title-gap">{gap.box}</p>
            <p className="comment-gap">{gap.comment}</p>
            <div className="gap">{<GapReason gap={gap.reason} />}</div>
          </GapOrderReason>
        )),
        // fastAction: {
        //   value: <FastAction {...rest} {...{ order }} />,
        //   className: 'text-right'
        // },
        key: order._id
      })),
    [orders]
  )

  const headers = useMemo(
    () => [
      { header: 'Cluster', sort: true },
      { header: 'Proyecto', sort: true },
      { header: 'Huecos', sort: false }
      // { header: '', sort: false, className: 'text-right' }
    ],
    []
  )
  return <Table {...{ data: orders, tableData, headers, cb: setOrders }} />
}

// function FastAction({ ...rest }) {
//   return (
//     <Dropdown>
//       <Dropdown.Toggle>
//         <IconosSistel icon="brigadas" />
//       </Dropdown.Toggle>
//       <Dropdown.Menu className="dropdown-brigade">
//         <SelectBrigade {...rest} />
//       </Dropdown.Menu>
//     </Dropdown>
//   )
// }
