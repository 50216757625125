import { useState } from 'react'
import { HeaderFiltersSistel, LoaderSistel } from 'npm-styles-sistel'

import ActiveOrdersTab from 'components/Orders/Tabs/ActiveOrdersTab'
import NewOrderModal from 'components/common/NewOrderModal'
import MainContainer from 'components/common/MainContainer'

import useOrderFilters from 'hooks/useOrderFilters'
import useGetOrderFilters from 'hooks/useGetOrderFilters'

export default function Ordenes() {
  const [show, setShow] = useState(null)
  const [showConfirm, setShowConfirm] = useState(null)

  const [isLoading, selectors] = useGetOrderFilters()

  const [filtersComponent, handleSearch, filters] = useOrderFilters(
    selectors,
    isLoading
  )

  if (isLoading && !filtersComponent) return <LoaderSistel />

  return (
    <MainContainer
      className="d-flex"
      style={{ height: '86vh', overflow: 'hidden' }}
    >
      <div style={{ flex: 1, height: '86vh', overflow: 'hidden' }}>
        <HeaderFiltersSistel
          iconTitle="ordenes"
          title="Órdenes de trabajo activas"
          handleSearch={handleSearch}
          buttonAction={() => setShow(true)}
          buttonText="Crear orden"
          filters={filtersComponent}
        />
        <ActiveOrdersTab {...{ setShowConfirm, filters }} />
        {show && <NewOrderModal handleClose={() => setShow(null)} />}
        {showConfirm}
      </div>
    </MainContainer>
  )
}
