/**
 *
 * @param {{}} match Match object from params
 * @returns Boolean if path includes 'editar'
 */

export function isEditRoute(match) {
  return match.path.includes('editar')
}

export function euroFormat(num) {
  return `${Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num)}€`
}

export function capitalize(str) {
  return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1)
}

export function getFeedbackTypeFromPath(path) {
  let formattedPath = path.slice(1)

  if (formattedPath.includes('/'))
    formattedPath = formattedPath.slice(0, formattedPath.indexOf('/'))

  const typeHashmap = {
    order: 'Orden',
    baremo: 'Baremo',
    brigade: 'Brigada',
    client: 'Cliente',
    department: 'Departamento',
    employeedelivery: 'Entrega',
    point: 'Puntos',
    user: 'Usuario',
    vehicle: 'Vehículo',
    signup: 'Usuario'
  }

  return typeHashmap[formattedPath] || ''
}
