import MainContainer from 'components/common/MainContainer'
import { OrderForm } from 'components/forms'

import useEditCreateOrder from 'hooks/useEditCreateOrder'
import { isEditRoute } from 'utils/strings'

import { IconosSistel, LoaderSistel } from 'npm-styles-sistel'

export default function OrdenAction({ match, location }) {
  const { state } = location
  const {
    params: { orderRef }
  } = match

  const [isLoading, data] = useEditCreateOrder(orderRef, state?.type)

  if (isLoading) return <LoaderSistel />

  const { allBrigades, allProjects, allBaremos, order } = data

  const title = isEditRoute(match)
    ? 'Editar orden'
    : `Nueva orden de ${state.type.toLowerCase()}`

  return (
    <MainContainer>
      <div className="mw-900">
        <div className="d-flex">
          <IconosSistel icon="ordenes" width="26" />
          <h1 className="ml-2">{title}</h1>
        </div>

        <OrderForm
          order={order || { type: state.type }}
          allBrigades={allBrigades}
          allProjects={allProjects}
          allBaremos={allBaremos}
        />
      </div>
    </MainContainer>
  )
}
