import styled from 'styled-components'

export const DashboardStyled = styled.div`
  background-color: rgba(213, 209, 225, 0.25);
  min-height: 100vh;

  .nueva-orden {
    margin-top: 16px;
    background: transparent;
    display: flex;
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.grey_dark};
    font-weight: 700;
    transition: color 0.5s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.success};
    }
  }

  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  .data-orders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.text};
    }

    h6 {
      font-size: 48px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .centered-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const CardGradientPrimary = styled.div`
  background: ${({ theme }) => theme.colors.gradient_primary};
  border-radius: 20px;
  padding: 24px;

  .data {
    color: white;
    font-size: 48px;
    font-weight: 600;
  }

  p {
    color: white;
    font-size: 12px;
  }
`

export const DashboardOrderStyled = styled.div`
  border-radius: 10px;
  margin-top: 16px;

  .content-order {
    display: flex;
    padding: 9px 12px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
    box-shadow: ${({theme}) => theme.shadows.main_shadow};

    .progress {
      width: 20%;
    }

    .title {
      width: 25%;
      font-size: 19px;
      font-weight: 800;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.text};

      img {
        width: 18px;
        margin-right: 8px;
      }
    }

    .brigade {
      width: 25%;
      display: flex;
      align-items: center;
      ${({ theme }) => theme.fonts.text_s_bold};
    }

    svg {
      margin-right: 6px;
    }

    .project {
      width: 30%;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.text_light};
      ${({ theme }) => theme.fonts.text_s};
    }
  }
`

export const ColumnDepartmentStlyed = styled.div`
  .title {
    background: ${({ theme, department }) =>
      department === 'Fusionadores'
        ? theme.colors.blue
        : department === 'Celadores'
        ? theme.colors.purple
        : theme.colors.dark_purple};
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 10px;

    svg {
      margin-right: 8px;
    }
  }

  .employee {
    display: flex;
    align-items: center;
    margin-top: 24px;

    .name {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.2em;
    }

    .puntos {
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme, department }) =>
        department === 'Fusionadores'
          ? theme.colors.blue
          : department === 'Celadores'
          ? theme.colors.purple
          : theme.colors.dark_purple};
    }
  }
`
