import axios from 'axios'
import { useEffect, useState, useCallback } from 'react'
import { ORDER_COLUMN_LIMIT } from 'utils/constants'

export default function useOrders(orderStatus, filters) {
  const [page, setPage] = useState(1)
  const [fetching, setFetching] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [orderData, setOrderData] = useState({ data: [], totalCount: 0 })

  const paginate = () => setPage((oldPage) => oldPage + 1)

  const getOrders = useCallback(
    async (forcePage) => {
      try {
        const url = new URL('/order', 'http://localhost:5000')

        url.searchParams.append(
          'offset',
          filters.force ? 0 : ORDER_COLUMN_LIMIT * ((forcePage ?? page) - 1)
        )
        url.searchParams.append('limit', ORDER_COLUMN_LIMIT)
        url.searchParams.append('status', orderStatus)
        for (const [filterKey, filterValue] of Object.entries(filters)) {
          if (filterKey === 'search') {
            if (filterValue) url.searchParams.append('search', filterValue)
          } else {
            if (filterValue.length)
              filterValue.forEach((value) =>
                url.searchParams.append(filterKey, value)
              )
          }
        }
        const response = await axios.get(`/order${url.search}`)
        const responseOk = response && response.status < 400
        if (!responseOk) throw new Error(response.data)

        const { totalResults, data } = response.data

        setOrderData((prev) => ({
          totalResults,
          data: [...(!filters.force ? prev?.data : []), ...data]
        }))
      } catch (error) {
        console.error(error)
      }
    },
    [page, filters]
  )

  const fetchData = () => {
    setFetching(true)
    getOrders().then(() => setFetching(false))
  }

  useEffect(() => {
    if (!isInitialized) {
      fetchData()
      setIsInitialized(true)
    }
    if (fetching || !isInitialized) return
    fetchData()
  }, [page])

  useEffect(() => {
    if (fetching || !isInitialized) return
    setPage(1)
    fetchData()
  }, [filters])

  return {
    ...orderData,
    fetching,
    paginate,
    page
  }
}
