import styled from 'styled-components'

export const EmployeeCardStyled = styled.div`
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  hr {
    border: 1px solid ${({ theme }) => theme.colors.grey_mid};
    width: 100%;
  }

  h2 {
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 6px;
  }

  .password {
     background: transparent;
     border-bottom: 2px solid ${({ theme }) => theme.colors.text_light};
     font-size: 14px;
     color: ${({ theme }) => theme.colors.text_light};
     display: block;
     margin: auto;
     margin-top: 24px;
  }
`
