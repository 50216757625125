import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'

export const BrigadeColumnStyle = styled.div`
  box-shadow: 0px 2px 16px rgba(197, 193, 211, 0.5);
  border-radius: 10px;
  min-height: 275px;
  margin-bottom: 24px;

  .heading {
    background: ${({ brigade }) =>
      brigade === 'FUSIONADOR'
        ? themeSistel.colors.blue
        : brigade === 'CELADOR'
        ? themeSistel.colors.purple
        : themeSistel.colors.dark_purple};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 10px 10px 0 0;

    button {
      background: rgb(255 255 255 / 25%);
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
    }

    h3 {
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 4px;
    }
  }

  .vehicle {
    background: ${({ brigade }) =>
      brigade === 'FUSIONADOR'
        ? themeSistel.colors.blue_light
        : brigade === 'CELADOR'
        ? themeSistel.colors.purple_light
        : themeSistel.colors.dark_purple_light};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 12px 16px;
    color: ${({ brigade }) =>
      brigade === 'FUSIONADOR'
        ? themeSistel.colors.blue
        : brigade === 'CELADOR'
        ? themeSistel.colors.purple
        : themeSistel.colors.dark_purple};

    p {
      font-size: 10px;
      font-weight: 500;
    }

    h4 {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }

  .main-content {
    background: white;
    padding: 0 16px 16px 16px;
    border-radius: 0 0 10px 10px;
  }
`
export const EmployeeBrigadeStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  .avatar-brigade {
    margin-right: 16px;
  }

  .name {
    font-size: 13px;
    font-weight: 500;
    color: ${themeSistel.colors.text_light};
  }

  .icons {
    display: flex;

    .mini-icon {
      width: 32px;
      height: 32px;
      background-color: ${themeSistel.colors.grey_mid};
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6px;
    }
  }
`

export const BrigadeInfoStyled = styled.div`
  margin-bottom: 24px;
  box-shadow: 0px 2px 16px rgba(197, 193, 211, 0.5);
  border-radius: 10px;

  .title {
    cursor: pointer;
    background: white;
    color: ${themeSistel.colors.text};
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: ${({ open }) => (open ? '10px 10px 0 0' : '10px')};

    h3 {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .content {
    padding: 16px;

    .info {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      p {
        font-size: 12px;
        margin-left: 8px;
        color: ${themeSistel.colors.text};
      }
      .mini-icon {
        border-radius: 100%;
        background-color: ${themeSistel.colors.grey_light};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -3px;
        width: 32px;
        height: 32px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
