import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'services/axios'

import useModal from 'hooks/useModal'
import useCompany from 'hooks/useCompany'
import { getLastClose } from 'utils/dates'
import { getPointsOfMonth } from 'utils/filters'
import { patchUser } from 'redux/actions/userActions'
import { putBrigade } from 'redux/actions/brigadeActions'
import { patchApi } from 'redux/actions/fetchActions'

import EmployeeData from 'components/Employees/EmployeeData'
import EmployeeHeader from 'components/Employees/EmployeeHeader'
import EmployeeDarkCards from 'components/Employees/EmployeeDarkCards'
import MainContainer from 'components/common/MainContainer'
import UserPointsForm from 'components/forms/UserPointsForm'
import UserPointsTable from 'components/Tables/UserPointsTable'
import { EmployeeForm } from 'components/forms'

import {
  ConfirmationModalSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'

export default function EmpleadoDetalles() {
  const { userId } = useParams()
  const [employeeData, setEmployeeData] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  const [action, setAction] = useState('form') // form || pointPrice || monthlyGoal
  const [modalContent, setModalContent] = useState('')
  const [modalFunction, setModalFunction] = useState(undefined)

  const me = useSelector((s) => s.me)
  const company = useCompany(me)

  const { actions, title, show } = useModal()
  const { onClose, handleShow } = actions

  const dispatch = useDispatch()
  const history = useHistory()
  const goBack = () => history.goBack()
  const goEmpleados = () => history.push('/empleados')

  const loadData = async () => {
    if (userId) {
      const response = await axios.get(`/user/${userId}`)
      if (response) {
        setEmployeeData(response.data)
      }
    }
  }

  useEffect(async () => {
    loadData()
  }, [])

  if (!employeeData || !company.loaded) return <LoaderSistel />

  const handleDeleteEmployee = () => {
    const checkBrigadeAndPatch = (employeeId) => {
      if (employeeData.brigade) {
        const { driver, manager } = employeeData.brigade
        const newBrigade = {
          ...employeeData.brigade,
          driver: driver === employeeId ? null : driver,
          manager: manager === employeeId ? null : manager
        }
        dispatch(putBrigade(newBrigade))
      }
      return patchUser(employeeId)
    }
    dispatch(
      patchApi(userId, `/user/${userId}`, [checkBrigadeAndPatch, goBack])
    )
  }

  const handleResetPassword = () =>
    dispatch(
      patchApi(userId, `/user/resetpassword/${userId}`, [patchUser, onClose])
    )

  const handleShowDeleteEmployee = () => {
    handleShow('Desactivar empleado')
    setModalContent('¿Seguro que quieres desactivar este empleado?')
    setModalFunction(() => handleDeleteEmployee)
  }

  const handleShowResetPassword = () => {
    handleShow('Resetear contraseña')
    setModalContent('¿Seguro que quieres resetear la contraseña?')
    setModalFunction(() => handleResetPassword)
  }

  const handleShowUpdate = (action) => {
    setAction(action)
    setShowEdit(true)
  }

  const pointPrice =
    employeeData.pointPrice || company.data.config?.pointPrice || 0
  const monthlyGoal =
    employeeData.monthlyGoal || employeeData.department?.monthlyGoal || 0

  const lastClose = getLastClose(company.data)
  const monthlyPoints = employeeData.points
    .filter(getPointsOfMonth(lastClose))
    .reduce((prev, curr) => prev + (curr.qty || 0), 0)

  const modalOnClose = () => {
    setShowEdit(false)
    loadData()
  }

  const modalChildrens = {
    form: <EmployeeForm user={employeeData} handleClose={modalOnClose} />,
    pointPrice: (
      <UserPointsForm
        user={employeeData}
        onClose={modalOnClose}
        type="pointPrice"
        value={pointPrice}
      />
    ),
    monthlyGoal: (
      <UserPointsForm
        user={employeeData}
        onClose={modalOnClose}
        type="monthlyGoal"
        value={monthlyGoal}
      />
    )
  }

  return (
    <MainContainer>
      <EmployeeHeader
        showDelete={handleShowDeleteEmployee}
        showEdit={handleShowUpdate}
        employee={employeeData}
        {...{ goEmpleados }}
      />
      <div className="row mt-4">
        <div className="col-md-3">
          <EmployeeData
            employee={employeeData}
            {...{ handleShowResetPassword }}
          />
        </div>
        <div className="col-md-9">
          <EmployeeDarkCards
            showModal={handleShowUpdate}
            employee={employeeData}
            {...{ pointPrice, monthlyGoal, monthlyPoints }}
          />
          <UserPointsTable points={employeeData.points.reverse()} />
        </div>
      </div>
      {show && (
        <ConfirmationModalSistel
          {...{ title, modalContent, modalFunction, onClose }}
        />
      )}
      {showEdit && (
        <ModalSistel
          title={`Editar ${employeeData.name} ${employeeData.lastname}`}
          iconTitle="empleados"
          buttons={false}
          handleClose={() => setShowEdit(false)}
          size="xl"
        >
          {modalChildrens[action]}
        </ModalSistel>
      )}
    </MainContainer>
  )
}
