import axios from 'services/axios'
import { toast } from 'react-toastify'
import { getFeedbackTypeFromPath } from 'utils/strings'

// CONSTANTS
export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'

export const fetchRequest = () => ({
  type: FETCH_REQUEST
})

export const fetchSuccess = () => ({
  type: FETCH_SUCCESS
})

export const fetchFailure = (error) => ({
  type: FETCH_FAILURE,
  payload: error
})

/**
 *
 * @param {Object} values Object with value of instance body
 * @param {string} path String of path with "/". Ex: "/info"
 * @param {[function, function]} cb Cb functions. [0] dispatch action, [1] callback Ex: history.push()
 */
export function postApi(values, path, cb) {
  return async (dispatch) => {
    dispatch(fetchRequest())

    const response = await axios.post(path, values)
    const responseOk = response && response.status < 400
    if (!responseOk) {
      const errorResponse = await response.text()
      const feedbackMessage = `Error al crear ${getFeedbackTypeFromPath(
        path
      )} ${errorResponse}`

      toast.error(feedbackMessage, { autoClose: false })
      return dispatch(fetchFailure(errorResponse))
    }

    const [action, callback] = cb

    dispatch(fetchSuccess())
    if (action) dispatch(action(response.data))
    callback()

    const feedbackMessage = `${getFeedbackTypeFromPath(
      path
    )} creado satisfactoriamente`
    toast.success(feedbackMessage, { autoClose: 5000 })
  }
}

/**
 *
 * @param {Object} values Object with value of instance body
 * @param {string} path String of path with "/". Ex: "/info"
 * @param {[function, function]} cb Cb functions. [0] dispatch action, [1] callback Ex: history.push()
 */
export function putApi(values, path, cb) {
  return async (dispatch) => {
    dispatch(fetchRequest())

    const response = await axios.put(path, values)
    const responseOk = response && response.status < 400

    if (!responseOk) {
      const errorResponse = await response.text()
      const feedbackMessage = `Error al actualizar ${getFeedbackTypeFromPath(
        path
      )} ${errorResponse}`
      toast.error(feedbackMessage, { autoClose: false })
      return dispatch(fetchFailure(errorResponse))
    }

    const [action, callback] = cb

    dispatch(fetchSuccess())
    if (action) dispatch(action(values))
    callback()

    const feedbackMessage = `${getFeedbackTypeFromPath(
      path
    )} actualizado satisfactoriamente`
    toast.success(feedbackMessage, { autoClose: 5000 })
  }
}

/**
 *
 * @param {Object} values Object with value of instance body
 * @param {string} path String of path with "/". Ex: "/info"
 */
export function getApi(path, cb) {
  return async (dispatch) => {
    dispatch(fetchRequest())

    const response = await axios.get(path)
    const responseOk = response && response.status < 400
    if (!responseOk) return dispatch(fetchFailure(await response.text()))

    dispatch(fetchSuccess())
    dispatch(cb(response.data))
  }
}

/**
 *
 * @param {string} value Id of element to be toggled
 * @param {string} path String of path with "/". Ex: "/info"
 * @param {[function, function]} cb Cb functions. [0] dispatch action, [1] callback Ex: history.push()
 */
export function patchApi(value, path, cb) {
  return async (dispatch) => {
    dispatch(fetchRequest())

    const response = await axios.patch(path)
    const responseOk = response && response.status < 400
    if (!responseOk) {
      const errorResponse = await response.text()
      const feedbackMessage = `Error al actualizar ${getFeedbackTypeFromPath(
        path
      )} ${errorResponse}`
      toast.error(feedbackMessage, { autoClose: false })
      return dispatch(fetchFailure(errorResponse))
    }

    const [action, callback] = cb

    dispatch(fetchSuccess())
    if (action) dispatch(action(value))
    callback()

    const feedbackMessage = `${getFeedbackTypeFromPath(
      path
    )} actualizado satisfactoriamente`
    toast.success(feedbackMessage, { autoClose: 5000 })
  }
}
