import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

import useModal from 'hooks/useModal'
import useCompleteOrder from 'hooks/useCompleteOrder'
import useUsers from 'hooks/useUsers'
import {
  CERTIFIED,
  FINISHED,
  IN_PROGRESS,
  PENDING_VALIDATE
} from 'utils/constants'

import { patchApi } from 'redux/actions/fetchActions'

import GapsDetails from 'components/Orders/GapsDetails'
import OrderDetails from 'components/Orders/OrderDetails'
import MainContainer from 'components/common/MainContainer'
import EstimatedBaremos from 'components/Orders/EstimatedBaremos'
import ValidationButton from 'components/Orders/ValidationButton'
import ValidateOrderForm from 'components/forms/ValidateOrderForm'
import EmployeeDeliveries from 'components/Orders/EmployeeDeliveries/EmployeeDeliveries'

import {
  BadgeSistel,
  BreadcrumbsSistel,
  ButtonSistel,
  ConfirmationModalSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'

export default function OrdenDetalles({ match }) {
  const { orderRef } = match.params
  const dispatch = useDispatch()
  const history = useHistory()
  const { data, fetching, refetch } = useCompleteOrder(orderRef)
  const [users, fetchingUsers] = useUsers()

  const { actions: validateActions, show: showValidate } = useModal()
  const { onClose: onCloseValidate, handleShow: handleShowValidate } =
    validateActions
  const validateOrder = () => handleShowValidate()

  const {
    actions: deleteActions,
    title: titleDelete,
    show: showDelete
  } = useModal()
  const { onClose: onCloseDelete, handleShow: handleShowDelete } = deleteActions
  const deleteOrder = () => handleShowDelete('Eliminar orden')

  if (fetching || !data || fetchingUsers) return <LoaderSistel />

  const goToOrders = () => history.push('/ordenes')
  const handleDeleteOrder = () =>
    dispatch(patchApi(data._id, `/order/${data._id}`, [null, goToOrders]))

  const orderStatus = getStatusBadge(data.status, data.brigade)

  return (
    <MainContainer>
      <div className="flex-between">
        <div className="d-flex align-items-start">
          <BreadcrumbsSistel
            baseLink={() => history.push('/ordenes')}
            baseTitle="Órdenes"
            current={orderRef}
          />
          <div className="ml-3">{orderStatus}</div>
        </div>

        <div className="pb-5">
          {!data.employeeDeliveries.length && (
            <ButtonSistel danger onClick={deleteOrder} className="mr-2">
              Eliminar orden
            </ButtonSistel>
          )}

          <ButtonSistel
            edit
            onClick={() => history.push(`/ordenes/editar/${orderRef}`)}
          >
            Editar orden
          </ButtonSistel>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <OrderDetails order={data} />
        </div>

        <div className="col-md-8">
          <EstimatedBaremos order={data} />
          {data.gaps?.length > 0 && <GapsDetails gaps={data.gaps} />}
          <EmployeeDeliveries
            type={data.type}
            boxList={data.boxList}
            deliveries={data.employeeDeliveries}
            refetch={refetch}
            users={users}
          />
          <ValidationButton order={data} cb={validateOrder} />
        </div>
      </div>
      {showValidate && (
        <ModalSistel
          handleClose={onCloseValidate}
          size="xl"
          title="Validar orden"
        >
          <ValidateOrderForm
            handleClose={onCloseValidate}
            order={data}
            refetch={refetch}
          />
        </ModalSistel>
      )}

      {showDelete && (
        <ConfirmationModalSistel
          title={titleDelete}
          onClose={onCloseDelete}
          modalContent={'¿Seguro que quieres eliminar esta orden?'}
          modalFunction={handleDeleteOrder}
        />
      )}
    </MainContainer>
  )
}

function getStatusBadge(status, brigade) {
  return status === IN_PROGRESS ? (
    <BadgeSistel blue>En curso</BadgeSistel>
  ) : status === PENDING_VALIDATE ? (
    <BadgeSistel warning>Validación pendiente</BadgeSistel>
  ) : !status && brigade ? (
    <BadgeSistel>Asignada</BadgeSistel>
  ) : status === FINISHED ? (
    <BadgeSistel success>Finalizada</BadgeSistel>
  ) : status === CERTIFIED ? (
    <BadgeSistel success>Certificada</BadgeSistel>
  ) : (
    <BadgeSistel grey>Sin asignar</BadgeSistel>
  )
}
