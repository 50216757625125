import { Link } from 'react-router-dom'
import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'

export const ColumnStyled = styled.div`
  overflow: scroll;
  max-height: 65vh;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StateColumnTitleStyled = styled.div`
  position: sticky;
  top: 70px;
  z-index: 2;
  background-color: white;
  padding: 32px 0 6px 0;
  box-shadow: 0px 4px 12px 12px #ffff;
  margin-bottom: 18px;

  h3 {
    position: relative;
    padding: 8px 0 8px 16px;
    border-radius: 10px;
    ${themeSistel.fonts.h4};
    background-color: ${themeSistel.colors.grey_mid};
    color: ${themeSistel.colors.text};
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    min-width: 32px;
    font-size: 14px;
    font-weight: 700;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const OrderCardStyled = styled(Link)`
  color: ${themeSistel.colors.text};
  display: block;
  padding: 16px 20px;
  border-radius: 10px;
  margin-top: 12px;
  box-shadow: ${themeSistel.shadows.main_shadow};

  &:hover {
    box-shadow: 0px 4px 26px rgba(213, 209, 225, 0.7);
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 16px;
    width: 5px;
    height: ${({ size }) =>
      size === 's' ? '72px' : size === 'm' ? '90px' : '112px'};
    border-radius: 0 10px 10px 0;
    background-color: ${({ priority }) =>
      priority === 'P01' ? themeSistel.colors.danger : 'transparent'};
  }

  .title-notifications {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      width: 80%;
    }
  }

  .dropdown-menu {
    box-shadow: ${themeSistel.shadows.main_shadow};
    border: none;
    margin-top: 8px !important;
    width: 460px !important;
    padding: 24px;
    border-radius: 10px;
  }

  .dropdown-toggle {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: ${themeSistel.colors.grey_light};

    svg {
      margin-right: 0;
    }
  }

  h4 {
    font-size: 19px !important;
    font-weight: 800;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text};
    line-height: 1em;

    img {
      width: 18px;
      margin-right: 8px;
    }
  }

  .priority {
    color: ${themeSistel.colors.warning};
  }

  .brigade {
    ${themeSistel.fonts.text_s_bold};
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text};
  }

  .project {
    ${themeSistel.fonts.text_s};
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text_light};
  }

  svg {
    margin-right: 6px;
  }

  .progress-order {
    display: flex;
    align-items: center;

    progress {
      width: 82%;
    }

    p {
      color: ${themeSistel.colors.success};
      font-size: 12px;
      font-weight: 500;
      margin-left: 12px;
    }
  }

  .uuii {
    h5 {
      font-size: 14px;
    }

    p {
      text-transform: uppercase;
      font-size: 10px;
    }
  }

  .notifications {
    margin-top: -8px;

    svg {
      margin-top: 18px;
    }

    span {
      background-color: ${themeSistel.colors.warning};
      padding: 2px 6px;
      border-radius: 6px;
      font-size: 10px;
      font-weight: 600;
      color: white;
      margin-left: -12px;
    }
  }

  .footer-info {
    display: flex;
    align-items: center;
    margin-top: 8px;

    span {
      width: 18px;
      height: 18px;
      ${themeSistel.fonts.text_xs_bold};
      border-radius: 5px;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .f {
      background-color: ${themeSistel.colors.blue_light};
      color: ${themeSistel.colors.blue};
    }

    .c {
      background-color: ${themeSistel.colors.purple_light};
      color: ${themeSistel.colors.purple};
    }

    .a {
      background-color: ${themeSistel.colors.dark_purple_light};
      color: ${themeSistel.colors.dark_purple};
    }

    .order-ref {
      ${themeSistel.fonts.text_xs};
      color: #b9b5cb;
    }
  }
`

export const DeliveryCardStyled = styled.div`
  background: white;
  box-shadow: ${themeSistel.shadows.main_shadow};
  margin-top: 24px;
  border-radius: 20px;

  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${({ open }) => (open ? '20px 20px 0 0' : '20px')};
    background: white;
    cursor: pointer;
    .date {
      padding: 24px;
      display: flex;
      align-items: center;
      border-radius: ${({ open }) => (open ? '20px 0 0 0' : '20px 0 0 20px')};
      min-width: 200px;

      h2 {
        color: ${themeSistel.colors.text};
        ${themeSistel.fonts.h3};
      }

      .uuii {
        ${themeSistel.fonts.text_s_bold};
        color: ${themeSistel.colors.text_light};
      }

      img {
        width: 24px;
        margin-right: 12px;
      }
    }

    .user {
      padding-right: 24px;
    }
  }

  .main-info {
    padding: 24px;
  }

  .delivery {
    padding: 24px;
  }

  .title-fotos {
    background-color: ${themeSistel.colors.purple_light};
    text-align: center;
    color: ${themeSistel.colors.purple};
    font-weight: 600;
    font-size: 14px;
    padding: 12px;
    border-radius: 10px;
    margin: 12px 0;
  }

  .baremos-delivery {
    margin: 24px 0;

    .baremo-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${themeSistel.colors.grey_light};
      padding: 12px;
      border-radius: 10px;
      margin-bottom: 12px;

      p {
        color: ${themeSistel.colors.text_light};
        font-weight: 600;
        font-size: 14px;
      }
    }

    .baremo-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border-bottom: 1px solid ${themeSistel.colors.grey_mid};

      label {
        width: 70%;
        font-size: 14px;
      }

      input {
        width: 20%;
        text-align: right;
        background-color: ${themeSistel.colors.grey_light};
        border-radius: 8px;
        padding: 6px;
        font-size: 14px;
      }
    }
  }
`

export const CertifyOrderCard = styled.div`
  background: ${themeSistel.colors.blue};
  width: 32%;
  margin-top: 12px;
  border-radius: 10px;
  margin-left: -2%;
  display: flex;
  position: relative;

  .form {
    width: 50%;
    background: white;
    margin: 2px;
    border-radius: 8px 0 0 8px;
    padding: 8px;
    padding-top: 16px;

    input {
      width: 80px;
      border-bottom: 1px solid ${themeSistel.colors.grey_mid};
      color: ${themeSistel.colors.text};
      text-align: center;
      outline: none;
      font-size: 14px;
    }
  }

  button {
    background: transparent;
    color: white;
    font-size: 12px;
    width: 50%;
  }
`

export const CertifiedCardStyled = styled.div`
  display: flex;
  justify-content: stretch;

  .order-details {
    width: 70%;
  }
`

export const GapOrderReason = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  .title-gap {
    width: 20%;
    font-weight: 700;
    color: ${themeSistel.colors.text};
  }

  .comment-gap {
    width: 60%;
    color: ${themeSistel.colors.text};
    font-size: 14px;
  }
  .gap {
    width: 20%;
  }
`

export const InputBaremos = styled.input`
  outline: none;
  width: 100px;
  padding: 6px;
  border-radius: 6px;
  border: 1px solid ${themeSistel.colors.grey_mid};
`

export const DetailsOrderStyled = styled.div`
  svg {
    margin-right: 12px;
  }
  h4 {
    ${themeSistel.fonts.text_s_bold};
    display: flex;
    align-items: center;
  }
  h5 {
    ${themeSistel.fonts.text_s};
    color: ${themeSistel.colors.text_light};
    display: flex;
    align-items: center;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0px;
    width: 5px;
    height: 80px;
    border-radius: 0 10px 10px 0;
    background-color: ${({ priority }) =>
      priority ? themeSistel.colors.danger : 'transparent'};
  }
`
