import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_NOTIFICATIONS,
  POST_NOTIFICATION
} from '../actions/notificationActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return { data: [...action.payload], loaded: true }
    case POST_NOTIFICATION:
      return { ...state, data: [...state.data, action.payload] }
    default:
      return state
  }
}
