import { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import { BrigadeInfoStyled } from './brigadeStyles'
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { IconosSistel } from 'npm-styles-sistel'

export default function BrigadesInfo() {
  const [open, setOpen] = useState(false)
  const toggleCollapse = () => setOpen(!open)

  return (
    <BrigadeInfoStyled open={open}>
      <div
        className="title"
        onClick={toggleCollapse}
        aria-controls="info"
        aria-expanded={open}
      >
        <h3>Leyenda</h3>
        <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
      </div>
      <Collapse in={open}>
        <div id="info" className="content">
          <span className="badge alteros">Alteros</span>
          <br />
          <span className="badge celadores">Celadores</span>
          <br />
          <span className="badge fusionadores">Fusionadores</span>

          <hr />
          <div className="info">
            <div className="mini-icon">
              <IconosSistel icon="leader" />
            </div>
            <p>Responsable de brigada</p>
          </div>
          <div className="info">
            <div className="mini-icon">
              <IconosSistel icon="vehiculos" width="18" />
            </div>
            <p>Conductor</p>
          </div>
        </div>
      </Collapse>
    </BrigadeInfoStyled>
  )
}
