import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApi } from 'redux/actions/fetchActions'

/**
 *
 * @param {string} path String with api path (singular) Ex: baremo
 * @param {Function} cb Callback dispatch function (Redux)
 * @returns {{ data: Array, boolean}} Returns data object and loader boolean
 */
export default function useDynamicFetch(path, cb) {
  const dispatch = useDispatch()
  const { loaded, data } = useSelector((s) => s[`${path}s`])
  useEffect(() => {
    if (loaded) return
    dispatch(getApi(`/${path}`, cb))
  }, [])

  return { data, loaded }
}
