import useStateWithDep from 'hooks/useStateWithDep'
import { BadgeSistel } from 'npm-styles-sistel'
import { useMemo } from 'react'
import { euroFormat } from 'utils/strings'
import Table from './Table'

export default function BaremosTable({ data, cb }) {
  const [baremos, setBaremos] = useStateWithDep(data)

  const tableData = useMemo(() => {
    return baremos.map((baremo) => ({
      ref: {
        value: (
          <p className="table-link" onClick={() => cb(baremo)}>
            {baremo.ref.slice(3)}
          </p>
        )
      },
      description: { value: baremo.description },
      pvp: { value: euroFormat(baremo.pvp), className: 'text-right' },
      unit: { value: baremo.unit, className: 'text-right' },
      assignation: {
        value: baremo.types.map(
          (b) =>
            b && (
              <BadgeSistel
                blue={b === 'FUSIONADOR'}
                purple={b === 'CELADOR'}
                key={b}
                className="ml-2"
              >
                {b}
              </BadgeSistel>
            )
        ),
        className: 'text-right'
      },
      key: baremo._id
    }))
  }, [baremos])

  const headers = useMemo(
    () => [
      { header: 'Referencia', sort: true },
      { header: 'Descripción', sort: true },
      { header: 'Precio de coste', sort: true, className: 'text-right' },
      { header: 'Unidad', sort: false, className: 'text-right' },
      { header: 'Asignación', sort: false, className: 'text-right' }
    ],
    []
  )

  return <Table {...{ data: baremos, tableData, headers, cb: setBaremos }} />
}
