export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const POST_DEPARTMENT = 'POST_DEPARTMENT'
export const PUT_DEPARTMENT = 'PUT_DEPARTMENT'
export const PATCH_DEPARTMENT = 'PATCH_DEPARTMENT'

// ACTIONS
export const getDepartments = (data) => ({
  type: GET_DEPARTMENTS,
  payload: data
})

export const postDepartment = (data) => ({
  type: POST_DEPARTMENT,
  payload: data
})

export const putDepartment = (data) => ({
  type: PUT_DEPARTMENT,
  payload: data
})

export const patchDepartment = (data) => ({
  type: PATCH_DEPARTMENT,
  payload: data
})
