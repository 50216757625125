import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Row from 'components/common/Row'

import useDynamicFetch from 'hooks/useDynamicFetch'
import { postApi, putApi } from 'redux/actions/fetchActions'
import { putBrigade, postBrigade } from 'redux/actions/brigadeActions'
import {
  getUsers,
  resetUserBrigades,
  updateUserBrigade
} from 'redux/actions/userActions'
import { formatVehicleSelectOption, populateInstance } from 'utils/maps'
import { CELADOR, FUSIONADOR } from 'utils/constants'

import {
  InputCheckboxSistel,
  InputSelectSistel,
  InputSistel
} from 'npm-styles-sistel'

export default function BrigadeForm({
  brigade,
  allBrigades,
  allUsers,
  allVehicles,
  handleClose
}) {
  const dispatch = useDispatch()
  const usersData = useDynamicFetch('user', getUsers)
  const [currentBrigade] = useState(initialValues(brigade, allUsers))

  const formik = useFormik({
    initialValues: currentBrigade,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      const newUsers = values.users.map(({ value }) => value)
      const prevUsers = usersData.data
        .filter((u) => u.brigade === brigade?._id)
        .map((u) => u._id)
      const instanceBody = { ...values, users: newUsers, prevUsers }
      if (brigade) {
        const payload = { newUsers, prevUsers, brigade: values._id }
        dispatch(resetUserBrigades(prevUsers))
        dispatch(updateUserBrigade(payload))
        return dispatch(
          putApi(instanceBody, `/brigade/${values._id}`, [
            putBrigade,
            handleClose
          ])
        )
      }
      const handlePostResponse = (newBrigade) => {
        const payload = { newUsers, brigade: newBrigade._id }
        dispatch(updateUserBrigade(payload))
        return postBrigade(newBrigade)
      }
      dispatch(
        postApi(instanceBody, '/brigade', [handlePostResponse, handleClose])
      )
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik
  const { name, users, vehicle, manager, driver, type } = values
  const handleSelect = (field) => (e) => setFieldValue(field, e.value)
  const handleCheckbox = ({ target }) => setFieldValue('type', target.value)
  const checkUser = (e) => {
    const usersMap = e.map((e) => e.value)
    if (manager && !usersMap.includes(manager)) setFieldValue('manager', null)
    if (driver && !usersMap.includes(driver)) setFieldValue('driver', null)
  }
  const handleSelectMulti = (field) => (e) => {
    field === 'users' && checkUser(e)
    setFieldValue(field, e)
  }

  // OPTIONS
  const assignedVehicles = allBrigades.reduce(
    (acc, brig) => (brig.vehicle ? [...acc, brig.vehicle] : acc),
    []
  )
  const availableVehicles = allVehicles.filter(
    (vehicle) => vehicle.active && !assignedVehicles.includes(vehicle._id)
  )

  const selectOptions = {
    users: allUsers
      .filter((u) => u.department?.name.toUpperCase().includes(values.type))
      .filter((u) => u.active && !u.brigade)
      .map((u) => ({
        label: `${u.name} ${u.lastname}`,
        value: u._id
      })),
    vehicles: availableVehicles.map(formatVehicleSelectOption),
    managers: values.users.filter(
      (u) => allUsers.find((user) => user._id === u.value)?.canBeManager
    ),
    drivers: values.users.filter(
      (u) => allUsers.find((user) => user._id === u.value)?.driverLicense
    )
  }

  return (
    <form onSubmit={handleSubmit} id="form">
      <Row>
        <div className="col-md-12">
          <h4>Tipo de brigada</h4>
          <div className="row my-3">
            <InputCheckboxSistel
              col="col-auto"
              type="radio"
              name="type"
              onChange={handleCheckbox}
              value={CELADOR}
              defaultChecked={type === CELADOR}
              label="Celadores"
            />
            <InputCheckboxSistel
              col="col-auto"
              name="type"
              type="radio"
              onChange={handleCheckbox}
              value={FUSIONADOR}
              defaultChecked={type === FUSIONADOR}
              label="Fusionadores"
            />
          </div>
        </div>
        <InputSistel
          type="text"
          label="Nombre"
          name="name"
          col="col-md-12"
          value={name}
          errors={errors.name}
          onChange={handleChange}
        />
        <InputSelectSistel
          label="Integrantes"
          name="users"
          col="col-md-12"
          placeholder="Selecciona los integrantes..."
          isMulti
          value={users}
          errors={errors.users}
          options={selectOptions.users}
          onChange={handleSelectMulti('users')}
        />
        <InputSelectSistel
          label="Vehículo"
          name="vehicle"
          col="col-md-6"
          placeholder="Selecciona el vehículo..."
          isVehicle
          value={formatVehicleSelectOption(
            populateInstance(allVehicles, vehicle)
          )}
          errors={errors.vehicle}
          options={selectOptions.vehicles}
          onChange={handleSelect('vehicle')}
        />
        <InputSelectSistel
          label="Conductor"
          name="driver"
          col="col-md-6"
          placeholder="Selecciona el conductor..."
          value={driver}
          errors={errors.driver}
          options={selectOptions.drivers}
          onChange={handleSelect('driver')}
        />
        <InputSelectSistel
          label="Responsable"
          name="manager"
          col="col-md-6"
          placeholder="Selecciona el responsable..."
          value={manager}
          errors={errors.manager}
          options={selectOptions.managers}
          onChange={handleSelect('manager')}
        />
      </Row>
    </form>
  )
}

function initialValues(brigade, allUsers) {
  const formattedUsers =
    brigade?.users?.map((u) => {
      if (typeof u !== 'string') {
        return {
          label: `${u.name} ${u.lastname}`,
          value: u._id
        }
      }
      const populatedUser = populateInstance(allUsers.data, u)
      return {
        label: `${populatedUser.name} ${populatedUser.lastname}`,
        value: populatedUser._id
      }
    }) || []

  return {
    _id: brigade?._id || undefined,
    driver: brigade?.driver?._id || undefined,
    name: brigade?.name || '',
    manager: brigade?.manager?._id || undefined,
    type: brigade?.type || undefined,
    users: formattedUsers,
    vehicle: brigade?.vehicle?._id || undefined
  }
}

function validationSchema() {
  return {
    driver: Yup.string().nullable(true),
    name: Yup.string().required('El nombre es obligatorio'),
    manager: Yup.string().nullable(),
    type: Yup.string(),
    users: Yup.array(),
    vehicle: Yup.string().nullable()
  }
}
