import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_CLIENTS,
  PATCH_CLIENT,
  POST_CLIENT,
  PUT_CLIENT
} from '../actions/clientActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return { data: [...action.payload], loaded: true }
    case POST_CLIENT:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_CLIENT:
      return {
        ...state,
        data: state.data.map((client) =>
          client._id === action.payload._id
            ? { ...client, ...action.payload }
            : client
        )
      }
    case PATCH_CLIENT:
      return {
        ...state,
        data: state.data.map((client) =>
          client._id === action.payload
            ? { ...client, active: !client.active }
            : client
        )
      }
    default:
      return state
  }
}
