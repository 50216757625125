import { useEffect, useState } from 'react'

export default function useStateWithDep(dependencyValue) {
  const [value, setValue] = useState(dependencyValue)

  useEffect(() => {
    setValue(dependencyValue)
  }, [dependencyValue, setValue])

  return [value, setValue]
}
