import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ButtonSistel, InputSistel } from 'npm-styles-sistel'
import Row from 'components/common/Row'
import { putApi } from 'redux/actions/fetchActions'
import { putUser } from 'redux/actions/userActions'

export default function UserPointsForm({ user, onClose, type, value }) {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: { [type]: value },
    validationSchema: validationSchema(type),
    validateOnChange: false,
    onSubmit: (values) => {
      return dispatch(
        putApi({ _id: user._id, ...values }, `/user/${user._id}`, [
          putUser,
          onClose
        ])
      )
    }
  })

  const label = type === 'pointPrice' ? 'Precio del punto' : 'Objetivo mensual'

  const { handleSubmit, errors, handleChange, values, isSubmitting } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <InputSistel
          type="number"
          min="0"
          step="0.01"
          label={label}
          name={type}
          col="col-md-12"
          value={values[type]}
          errors={errors[type]}
          onChange={handleChange}
        />
        <div className="col-md-12">
          <div className="d-flex justify-content-end">
            <ButtonSistel edit className="mr-2" type="button" onClick={onClose}>
              Cancelar
            </ButtonSistel>
            <ButtonSistel
              success={!isSubmitting}
              type="submit"
              disabled={isSubmitting}
              className="float-right"
            >
              Guardar
            </ButtonSistel>
          </div>
        </div>
      </Row>
    </form>
  )
}

function validationSchema(type) {
  return Yup.object({
    [type]: Yup.number().required('Este campo es obligatorio')
  })
}
