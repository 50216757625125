import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ClientForm } from 'components/forms'
import ClientsTable from 'components/Tables/ClientsTable'
import MainContainer from 'components/common/MainContainer'
import useModal from 'hooks/useModal'
import useFilter from 'hooks/useFilter'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { patchApi } from 'redux/actions/fetchActions'
import { getClients, patchClient } from 'redux/actions/clientActions'
import {
  HeaderFiltersSistel,
  LoaderSistel,
  ModalSistel,
  ConfirmationModalSistel
} from 'npm-styles-sistel'

export default function Clientes() {
  const dispatch = useDispatch()
  const clients = useDynamicFetch('client', getClients)
  const [selectedClient, setSelectedClient] = useState(undefined)

  const { actions, show, title } = useModal()
  const { handleShow, handleClose, onClose } = actions

  const confirmationModal = useModal()

  const activeClients = clients.data.filter((c) => c.active)
  const [handleChange, allClients] = useFilter(activeClients, ['name', 'cif'])

  if (!clients.loaded) return <LoaderSistel />

  const handleSelect = (client) => {
    setSelectedClient(client)
    handleShow('Editar cliente')
  }

  const toggleClientState = () => {
    dispatch(
      patchApi(selectedClient._id, `/client/${selectedClient._id}`, [
        patchClient,
        onClose
      ])
    )
  }

  const deleteProps = {
    secBtnText: 'Eliminar cliente',
    secBtnFunction: () =>
      confirmationModal.actions.handleShow('Eliminar cliente')
  }
  const showDeleteProps = selectedClient ? deleteProps : {}

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="clientes"
        title="Clientes"
        buttonText="Nuevo cliente"
        buttonAction={() => handleShow('Nuevo cliente')}
        handleSearch={handleChange}
      />
      <ClientsTable data={allClients} cb={handleSelect} />
      {show && (
        <ModalSistel
          {...{ show, title, ...showDeleteProps }}
          handleClose={handleClose(setSelectedClient)}
          iconTitle={selectedClient ? 'clientes' : 'clienteNuevo'}
          size="xs"
        >
          <ClientForm
            onClose={handleClose(setSelectedClient)}
            client={selectedClient}
          />
          {confirmationModal.show && (
            <ConfirmationModalSistel
              buttonType="button"
              modalContent="¿Seguro que quieres borrar el cliente?"
              modalFunction={toggleClientState}
              {...{ ...confirmationModal, ...confirmationModal.actions }}
            />
          )}
        </ModalSistel>
      )}
    </MainContainer>
  )
}
