import { useState } from 'react'
import Icon from '@mdi/react'
import { mdiArrowDownBoldBox, mdiArrowUpBoldBox } from '@mdi/js'
import styled from 'styled-components'

import { compare, reverseCompare } from 'utils/tableUtils'

const SimpleTableStyled = styled.div`
  margin-top: 0px !important;

  .table {
    thead {
      th {
        font-size: 14px;
        padding: 12px;
      }
    }

    td {
      font-size: 14px;
      padding: 8px 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.grey_mid};
    }
  }
`

export default function SimpleTable({ data, tableData, headers, cb }) {
  const [filter, setFilter] = useState({ field: '', up: true })

  const columnKeys = tableData.length ? Object.keys(tableData[0]) : []

  const renderHeaders = headers.map(
    ({ header, sort, sortField, reverse, className }, i) => (
      <th
        className={className}
        scope="col"
        key={header}
        style={{ cursor: `${sort ? 'pointer' : 'default'}` }}
        onClick={() =>
          sort && cb(sortData(sortField || columnKeys[i], reverse))
        }
      >
        {header}
        {(filter.field === sortField || filter.field === columnKeys[i]) &&
          (!filter.up ? (
            <Icon path={mdiArrowDownBoldBox} size={1} />
          ) : (
            <Icon path={mdiArrowUpBoldBox} size={1} />
          ))}
      </th>
    )
  )

  function sortData(field, reverse) {
    const copyDataArr = JSON.parse(JSON.stringify(data))
    const compareFnc = reverse ? reverseCompare : compare

    if (field === filter.field && filter.up) {
      setFilter((prev) => ({ ...prev, up: !prev.up }))
      return copyDataArr.sort((v1, v2) => compareFnc(v1[field], v2[field]))
    }

    if (field === filter.field && !filter.up) {
      setFilter((prev) => ({ ...prev, up: !prev.up }))
      return copyDataArr.sort((v1, v2) => compareFnc(v2[field], v1[field]))
    }

    setFilter(() => ({ field, up: false }))
    return copyDataArr.sort((v1, v2) => compareFnc(v1[field], v2[field]))
  }

  function renderData() {
    const tableAccessors = columnKeys.slice(0, -1)

    return tableData.map((row) => (
      <tr key={row.key} onClick={row.action}>
        {tableAccessors.map((selector, idx) => (
          <td
            className={
              `${row[selector].className} align-middle` || 'align-middle'
            }
            key={selector + idx}
          >
            {row[selector].value ?? row[selector]}
          </td>
        ))}
      </tr>
    ))
  }

  return (
    <SimpleTableStyled className="table-responsive my-3">
      <table className="table table-hover">
        <thead className="table-head">
          <tr>{renderHeaders}</tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
    </SimpleTableStyled>
  )
}
