import { useHistory } from 'react-router'
import { CELADOR, FUSIONADOR } from 'utils/constants'
import styled from 'styled-components'
import { ModalSistel } from 'npm-styles-sistel'

const ButtonType = styled.button`
  width: 100%;
  height: 120px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.purple_light};
  color: ${({ theme }) => theme.colors.purple};
  font-weight: 600;
  transition: all 0.5s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple};
    color: white;
  }
`

export default function NewOrderModal({ handleClose }) {
  const history = useHistory()
  const goCreateOrder = (type) => history.push('/ordenes/nueva', { type })

  return (
    <ModalSistel
      handleClose={handleClose}
      size="xs"
      iconTitle="ordenNueva"
      title="Tipo de orden"
      saveButtonText="Continuar"
      buttons={false}
    >
      <div className="row mb-4">
        <div className="col-md-6">
          <ButtonType onClick={() => goCreateOrder(CELADOR)}>
            Celador
          </ButtonType>
        </div>
        <div className="col-md-6">
          <ButtonType onClick={() => goCreateOrder(FUSIONADOR)}>
            Fusionador
          </ButtonType>
        </div>
      </div>
    </ModalSistel>
  )
}
