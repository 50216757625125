import { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'

import AvatarContainer from './AvatarContainer'
import ValidatedEmployeeDelivery from './ValidatedEmployeeDelivery'
import { EmployeeDeliveryForm } from 'components/forms'
import { FUSIONADOR } from 'utils/constants'

import { DeliveryCardStyled } from '../orderStyle'
import { BadgeSistel } from 'npm-styles-sistel'

export default function DeliveryDetails({
  delivery,
  isPending = false,
  isCancelled = false,
  type,
  boxList,
  refetch,
  users
}) {
  const [open, setOpen] = useState(false)

  const deliveryTimeStamp = new Date(delivery.createdAt)
  const timeOptions = { hour: '2-digit', minute: '2-digit' }
  const deliveryDate = deliveryTimeStamp.toLocaleDateString('es-ES')
  const deliveryTime = deliveryTimeStamp.toLocaleTimeString(
    'es-ES',
    timeOptions
  )

  const toggleCollapse = () => setOpen(!open)
  const box = boxList.find((b) => b._id === delivery.boxId)

  return (
    <DeliveryCardStyled open={open}>
      <div className="info" onClick={toggleCollapse}>
        <div className={'date' + (isPending ? ' pending' : '')}>
          <div>
            <h2 className="mr-4">
              {type === FUSIONADOR ? box.name : deliveryDate}
            </h2>
            {type === FUSIONADOR && <p className="uuii">{box.uuii} UUII</p>}
          </div>

          {isPending ? (
            <BadgeSistel warning>Validación pendiente</BadgeSistel>
          ) : isCancelled ? (
            <BadgeSistel danger>Cancelada</BadgeSistel>
          ) : (
            <BadgeSistel success>Validada</BadgeSistel>
          )}
        </div>

        <div className="user">
          <AvatarContainer
            user={delivery.createdBy}
            deliveryTime={
              type === FUSIONADOR ? (
                <>
                  <b>{deliveryDate}</b> {deliveryTime}
                </>
              ) : (
                deliveryTime
              )
            }
          />
        </div>
      </div>
      <CollapseComponent {...{ isPending, delivery, open, refetch, users }} />
    </DeliveryCardStyled>
  )
}

function CollapseComponent({ isPending, delivery, open, refetch, users }) {
  if (!isPending)
    return (
      <Collapse in={open}>
        <div className="delivery">
          <ValidatedEmployeeDelivery data={delivery} />
        </div>
      </Collapse>
    )

  return (
    <Collapse in={open}>
      <div className="delivery">
        <div className="pending">
          <EmployeeDeliveryForm
            data={delivery}
            refetch={refetch}
            users={users}
          />
        </div>
      </div>
    </Collapse>
  )
}
