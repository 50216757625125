import styled from 'styled-components'
import { Link } from 'react-router-dom'

import themeSistel from 'npm-styles-sistel/src/theme'
import { IconosSistel } from 'npm-styles-sistel'

const TitleStyled = styled.div`
  h4 {
    font-size: 19px !important;
    font-weight: 800;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text};
    line-height: 1em;
  }

  .project {
    ${themeSistel.fonts.text_s};
    display: flex;
    align-items: center;
    color: ${themeSistel.colors.text_light};
  }
`

export default function LastDeliveryTitle({ title, order }) {
  return (
    <TitleStyled>
      <Link to={`/ordenes/${order.ref}`}>
        <h4>{title}</h4>
        <p className="project">
          <IconosSistel icon="proyectos" width="12" color="#8D88A4" />
          {order.project?.title}
        </p>
      </Link>
    </TitleStyled>
  )
}
