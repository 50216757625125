import axios from 'services/axios'
import { useState, useEffect } from 'react'

export default function useGapOrders() {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState({ gapOrders: [], projects: [] })

  const fetchData = async () => {
    try {
      const response = await axios.get('/order/gap')
      const responseOk = response && response.status < 400
      if (!responseOk) throw new Error(response.data)

      setData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData().then(() => setFetching(false))
  }, [])

  return [data, fetching]
}
