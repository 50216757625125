import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { ThemeProvider } from 'styled-components'

import App from './App'
import store from './redux/store'

import themeSistel from 'npm-styles-sistel/src/theme'
import { MainStylesSistel } from 'npm-styles-sistel'
import 'npm-styles-sistel/src/bootstrap.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-image-lightbox/style.css'

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={themeSistel}>
      <MainStylesSistel>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </MainStylesSistel>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
