import ImageUploading from 'react-images-uploading'
import styled from 'styled-components'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

const ButtonAddImages = styled.button`
  width: 100px;
  height: 100px;
  border: 4px dashed ${({ theme }) => theme.colors.grey_mid};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .add {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-position: center center;
    filter: brightness(80%);
    border-radius: 20px;
  }

  .icon {
      position: absolute;
      svg {
          color: white;
      }
  }
`

export default function AddAvatar({ images, setImages, currentImage }) {
  const onChange = async (imageList, addUpdateIndex) => {
    setImages(imageList)
  }

  return (
    <>
      <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
        {({ onImageUpload, dragProps }) => (
          <>
            <div>
              <ButtonAddImages
                image={
                  images.length
                    ? images[0].data_url
                    : currentImage
                }
                type="button"
                onClick={onImageUpload}
                hasImage={images.length}
                {...dragProps}
              >
                <div className="add"></div>
                <div className="icon">
                  <Icon path={mdiPlus} size={1.5} />
                </div>
              </ButtonAddImages>
            </div>
          </>
        )}
      </ImageUploading>
    </>
  )
}
