import { GET_COMPANY, PUT_COMPANY } from '../actions/companyActions'

const initialState = {
  data: {},
  loaded: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return { data: { ...action.payload }, loaded: true }
    case PUT_COMPANY:
      return { data: { ...action.payload }, loaded: true }
    default:
      return state
  }
}
