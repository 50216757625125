import Table from './Table'

export default function VehicleHistoricTable({ historic }) {
  const tableData = historic.reverse().map((h, i) => {
    const date = new Date(h.date)
    return {
      date: date.toLocaleDateString(),
      user: `${h.user.name} ${h.user.lastname}`,
      key: i
    }
  })

  const headers = [
    { header: 'Día', sort: false },
    { header: 'Responsable', sort: false }
  ]

  return <Table {...{ data: historic, tableData, headers }} />
}
