import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'

import { InputSistel } from 'npm-styles-sistel'
import { putApi } from 'redux/actions/fetchActions'
import { putCompany } from 'redux/actions/companyActions'

export default function ConfigForm({ data, selector, cb }) {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: { ...data.config },
    validateOnChange: false,
    onSubmit: (values) => {
      const instanceBody = {
        ...data,
        config: { ...values }
      }
      return dispatch(putApi(instanceBody, '/company', [putCompany, cb]))
    }
  })

  const { values, handleSubmit, errors, handleChange } = formik

  return (
    <form onSubmit={handleSubmit} id="form">
      <div className="row">
        {selector === 'pointPrice' && (
          <InputSistel
            type="number"
            step={0.01}
            min={0}
            label="Precio del punto"
            name="pointPrice"
            col="col-12"
            value={values.pointPrice}
            errors={errors.pointPrice}
            onChange={handleChange}
          />
        )}
        {selector === 'closingDay' && (
          <InputSistel
            type="number"
            step={1}
            min={1}
            max={31}
            label="Día de cierre"
            name="closingDay"
            col="col-12"
            value={values.closingDay}
            errors={errors.closingDay}
            onChange={handleChange}
          />
        )}
      </div>
    </form>
  )
}
