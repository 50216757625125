import { CANCELLED, FUSIONADOR, PENDING } from 'utils/constants'

import BoxDetails from './BoxDetails'
import DeliveryDetails from './DeliveryDetails'

export default function EmployeeDeliveries({
  deliveries,
  type,
  boxList,
  refetch,
  users
}) {
  if (!deliveries.length && type !== FUSIONADOR) return null

  if (type === FUSIONADOR) {
    const displayDeliveries = boxList.map((box) => {
      const delivery = deliveries.find((d) => d.boxId === box._id)
      return delivery ? (
        getDeliveryDetails(delivery, type, boxList, refetch, users)
      ) : (
        <BoxDetails key={box._id} box={box} />
      )
    })
    return <div>{displayDeliveries}</div>
  }

  const displayDeliveries = deliveries.map((delivery) =>
    getDeliveryDetails(delivery, type, boxList, refetch, users)
  )
  return <div>{displayDeliveries}</div>
}

function getDeliveryDetails(delivery, type, boxList, refetch, users) {
  return (
    <DeliveryDetails
      {...{ delivery, refetch, type, boxList, users }}
      key={delivery._id}
      isPending={delivery.status === PENDING}
      isCancelled={delivery.status === CANCELLED}
    />
  )
}
