import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { putApi } from 'redux/actions/fetchActions'
import { CERTIFIED } from 'utils/constants'
import { IconosSistel } from 'npm-styles-sistel'
import {
  CertifiedCardStyled,
  CertifyOrderCard,
  OrderCardStyled
} from './orderStyle'

export default function FinishedOrders({ order, refetch }) {
  const me = useSelector((s) => s.me)
  const dispatch = useDispatch()
  const [uuii, setUuii] = useState()
  const [submitting, setSubmitting] = useState(false)

  const handleChange = (e) => setUuii(e.target.value)

  // certificar orden pasando uis certificadas, estado certificada y certifiedby
  const certifyOrder = () => {
    setSubmitting(true)

    const body = {
      _id: order._id,
      uuiiCertified: uuii,
      status: CERTIFIED,
      certifiedBy: me._id
    }

    // TODO: Mover la orden de columna en vez de hacer refetch
    dispatch(
      putApi(body, `/order/${order._id}`, [
        null,
        () => {
          setSubmitting(false)
          refetch()
        }
      ])
    )
  }

  return (
    <CertifiedCardStyled>
      <OrderCardStyled className="order-details" to={`/ordenes/${order.ref}`}>
        <div className="flex-between mr-5">
          <div>
            <h4>{order.cluster}</h4>
            <p className="project">
              <IconosSistel icon="proyectos" width="12" color="#9D98AE" />
              {order.project?.title}
            </p>
          </div>
          <div className="uuii">
            <h5>{order.uuiiBuilt}</h5>
            <p>
              UUII
              <br />
              construidas
            </p>
          </div>
        </div>
      </OrderCardStyled>
      <CertifyOrderCard>
        <div className="form">
          <input type="number" value={uuii} onChange={handleChange} min="0" />
        </div>
        <button disabled={!uuii} onClick={certifyOrder}>
          {submitting ? 'Certificando' : 'Certificar'}
        </button>
      </CertifyOrderCard>
    </CertifiedCardStyled>
  )
}
