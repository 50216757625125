import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_BAREMOS,
  PATCH_BAREMO,
  POST_BAREMO,
  PUT_BAREMO
} from '../actions/baremoActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_BAREMOS:
      return { data: [...action.payload], loaded: true }
    case POST_BAREMO:
      return { ...state, data: [...state.data, action.payload] }
    case PATCH_BAREMO:
      return {
        ...state,
        data: state.data.map((baremo) =>
          baremo._id === action.payload
            ? { ...baremo, active: !baremo.active }
            : baremo
        )
      }
    case PUT_BAREMO:
      return {
        ...state,
        data: state.data.map((baremo) =>
          baremo._id === action.payload._id
            ? { ...baremo, ...action.payload }
            : baremo
        )
      }
    default:
      return state
  }
}
