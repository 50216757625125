import { useState } from 'react'
import CardCluster from './CardCluster'
import NoResults from 'components/common/NoResults'
import { HeaderFiltersSistel } from 'npm-styles-sistel'

export default function ProjectDetails({ project }) {
  const [search, setSearch] = useState('')
  const handleChange = ({ target }) => setSearch(target.value)

  const searchRegex = new RegExp(search, 'i')

  const filteredClusters = project.clusters.filter((cluster) =>
    cluster.match(searchRegex)
  )

  if (!filteredClusters.length && search === '')
    return <NoResults text="Aún no hay entregas validadas en este proyecto" />

  const displayClusters = filteredClusters.map((cluster) => (
    <CardCluster key={cluster} project={project.ref} cluster={cluster} />
  ))

  return (
    <>
      <HeaderFiltersSistel
        iconTitle="documentos"
        title={project.title}
        handleSearch={handleChange}
      />

      <div className="d-flex flex-wrap">{displayClusters}</div>
    </>
  )
}
