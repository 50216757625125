import { AvatarImageSistel, ButtonSistel } from 'npm-styles-sistel'
import { useDispatch } from 'react-redux'

import { logout } from 'redux/actions/meActions'

import styled from 'styled-components'

const UserDropdownStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    ${({ theme }) => theme.fonts.h2};
    margin-bottom: 24px;
    margin-top: 8px;
  }
`

export default function UserAvatar({ user, goToProfile }) {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
    window.location.reload()
  }

  return (
    <>
      <div className="dropdown-sistel dropdown-sistel-right">
        <AvatarImageSistel image={user.avatar} width="46px" />
        <div className="dropdown-content">
          <div className="content">
            <UserDropdownStyled>
              <AvatarImageSistel image={user.avatar} width="80px" />
              <h4>¡Hola, {user.name}!</h4>
              <ButtonSistel edit block onClick={goToProfile} className="mb-2">
                Ajustes
              </ButtonSistel>
              <ButtonSistel danger block onClick={handleLogout}>
                Cerrar sesión
              </ButtonSistel>
            </UserDropdownStyled>
          </div>
        </div>
      </div>
    </>
  )
}
