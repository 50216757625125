import { capitalize } from './strings'

export function makeSelectOptions(optionsArray) {
  return optionsArray.map((e) => {
    return {
      label: capitalize(e),
      value: e
    }
  })
}

export function populateInstance(instanceArray, instance) {
  if (!instanceArray.length || !instance) return null
  return instanceArray.find(({ _id }) => _id === instance)
}

export function formatVehicleSelectOption(v) {
  if (!v) return { label: 'No asignado', value: null }

  return {
    label: `${v.brand} ${v.model} ${v.plate}`,
    value: v._id
  }
}
