import { BASE_PATH, TOKEN } from 'utils/constants'

class FileService {
  async _postDocumentApi(values) {
    try {
      const options = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem(TOKEN))
        },
        method: 'POST',
        body: values
      }

      const response = await fetch(`${BASE_PATH}/upload`, options)
      if (!response.ok) throw await response.text()
      const data = await response.json()
      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }

  /**
   *
   * @param {Array} files Array of files
   * @returns
   */
  async uploadFiles(files) {
    if (!files || !files.length) return []

    const formData = new FormData()

    if (files.length === 1) {
      formData.append('file', files[0], files[0].name)
      return await this._postDocumentApi(formData)
    }

    files.forEach((file) => formData.append('file', file, file.name))
    return await this._postDocumentApi(formData)
  }
}

export default new FileService()
