import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { postApi, putApi } from 'redux/actions/fetchActions'
import Row from 'components/common/Row'
import { postDepartment, putDepartment } from 'redux/actions/departmentActions'
import {
  ADMIN,
  DESIGNER,
  FIXED_DEPARMTENTS,
  RRHH,
  SUPERVISOR,
  WORKER
} from 'utils/constants'
import { InputSelectSistel, InputSistel } from 'npm-styles-sistel'

export default function DepartmentForm({ department, handleClose }) {
  const dispatch = useDispatch()
  const [currentBrigade] = useState(initialValues(department))

  const formik = useFormik({
    initialValues: currentBrigade,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      if (department)
        return dispatch(
          putApi(values, `/department/${values._id}`, [
            putDepartment,
            handleClose
          ])
        )

      dispatch(postApi(values, '/department', [postDepartment, handleClose]))
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik
  const handleSelect = (field) => (e) => setFieldValue(field, e.value)

  const { name, role, monthlyGoal } = values

  const roleOptions = [
    { label: 'Admin', value: ADMIN },
    { label: 'Supervisor', value: SUPERVISOR },
    { label: 'Trabajador', value: WORKER },
    { label: 'Diseñador', value: DESIGNER },
    { label: 'RRHH', value: RRHH }
  ]

  const isFixedDepartment = FIXED_DEPARMTENTS.includes(name)

  return (
    <form onSubmit={handleSubmit} id="form">
      <Row>
        {isFixedDepartment && (
          <label className="col-md-10">
            El nombre de este departamento no se puede cambiar
          </label>
        )}
        <InputSistel
          type="text"
          label="Nombre"
          name="name"
          col="col-12"
          disabled={isFixedDepartment}
          value={name}
          errors={errors.name}
          onChange={handleChange}
        />
        <InputSelectSistel
          label="Rol"
          name="role"
          col="col-12"
          placeholder="Selecciona el rol..."
          isDisabled={isFixedDepartment}
          value={role}
          errors={errors.role}
          options={roleOptions}
          onChange={handleSelect('role')}
        />
        <InputSistel
          type="number"
          min="0"
          step="1"
          label="Objetivo mensual"
          name="monthlyGoal"
          col="col-12"
          value={monthlyGoal}
          errors={errors.monthlyGoal}
          onChange={handleChange}
        />
      </Row>
    </form>
  )
}

function initialValues(department) {
  return {
    _id: department?._id || undefined,
    name: department?.name || '',
    monthlyGoal: department?.monthlyGoal || '',
    role: department?.role || ''
  }
}

function validationSchema() {
  return {
    name: Yup.string().required('El nombre es obligatorio'),
    monthlyGoal: Yup.number(),
    role: Yup.string()
  }
}
