import { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import ConfigForm from 'components/forms/ConfigForm'
import GeneralConfigForm from 'components/forms/GeneralConfigForm'
import { Departments, BaremoFilters } from 'components/Tabs'

import useCompany from 'hooks/useCompany'
import { euroFormat } from 'utils/strings'
import {
  DarkCardSistel,
  IconosSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'
import MainContainer from 'components/common/MainContainer'

export default function Configuracion() {
  const me = useSelector((s) => s.me)
  const [show, setShow] = useState(false)
  const [showDept, setShowDept] = useState(null)
  const [currentElement, setCurrentElement] = useState(undefined)
  const { loaded, data } = useCompany(me)

  if (!loaded) return <LoaderSistel />

  const { closingDay, pointPrice } = data.config

  const tabs = [
    {
      key: 'departments',
      title: 'Departamentos',
      children: <Departments setShow={setShowDept} />
    },
    {
      key: 'baremos',
      title: 'Filtros de baremos',
      children: <BaremoFilters />
    },
    {
      key: 'generalConfig',
      title: 'Configuración general',
      children: <GeneralConfigForm company={data} />
    }
  ]

  const displayTabs = (defaultActive) => (
    <Tabs className="d-flex mb-4" defaultActiveKey={defaultActive}>
      {tabs.map((t) => (
        <Tab key={t.key} eventKey={t.key} title={t.title}>
          {t.children}
        </Tab>
      ))}
    </Tabs>
  )

  const handleClose = () => setShow(false)

  const handleShowForm = (selector) => {
    setCurrentElement(selector)
    setShow(true)
  }

  return (
    <MainContainer>
      <div className="d-flex mb-5">
        <IconosSistel icon="configuracion" />
        <h1 className="ml-3">Configuración de empresa</h1>
      </div>

      <div className="row">
        <div className="col-md-4">
          <DarkCardSistel onClick={() => handleShowForm('pointPrice')}>
            <h4>{euroFormat(pointPrice)}</h4>
            <p>Precio del punto</p>
          </DarkCardSistel>
          <br />
          <DarkCardSistel onClick={() => handleShowForm('closingDay')}>
            <h4>{closingDay}</h4>
            <p>Día de cierre</p>
          </DarkCardSistel>
        </div>
        <div className="col-md-8">{displayTabs('departments')}</div>
      </div>

      {show && (
        <ModalSistel
          handleClose={handleClose}
          title="Editar configuración empresa"
          iconTitle="configuracion"
          size="xs"
        >
          <ConfigForm data={data} selector={currentElement} cb={handleClose} />
        </ModalSistel>
      )}
      {showDept}
    </MainContainer>
  )
}
