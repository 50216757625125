// CONSTANTS
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
// ACTIONS

export const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: token
})

export const login = ({ token, user }) => ({
  type: LOGIN,
  token,
  user
})

export const logout = () => ({
  type: LOGOUT,
  payload: null
})
