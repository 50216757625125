import { useEffect, useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { populateInstance } from 'utils/maps'
import { getPointsOfMonth } from 'utils/filters'
import { getPointHistoryMonths } from 'utils/monthlyClose'
import { getLastClose } from 'utils/dates'
import useDynamicFetch from 'hooks/useDynamicFetch'
import useFilter from 'hooks/useFilter'
import useCompany from 'hooks/useCompany'
import { getUsers } from 'redux/actions/userActions'
import { getDepartments } from 'redux/actions/departmentActions'

import MainContainer from 'components/common/MainContainer'
import MonthlyCloseTable from 'components/Tables/MonthlyCloseTable'
import PointsSummaryTable from 'components/Tables/PointsSummaryTable'

import { HeaderFiltersSistel, LoaderSistel } from 'npm-styles-sistel'

export default function PuntosResumen() {
  const me = useSelector((s) => s.me)
  const company = useCompany(me)
  const users = useDynamicFetch('user', getUsers)
  const departments = useDynamicFetch('department', getDepartments)

  const lastClose = getLastClose(company?.data)

  const [monthList, setMonthList] = useState([])

  useEffect(() => {
    if (users.loaded && !monthList.length) {
      const months = getPointHistoryMonths(users.data)
      setMonthList(months)
    }
  }, [users])

  const formatedData = users.data
    .filter((u) => u.active)
    .map((user) => ({
      ...user,
      department: populateInstance(departments.data, user.department)
    }))
    .map((user) => {
      const currentPoints = user.points
        .filter(getPointsOfMonth(lastClose))
        .reduce((prev, curr) => prev + (curr.qty || 0), 0)
      const target = user.department?.monthlyGoal || 0
      const extraPoints = currentPoints > target ? currentPoints - target : 0
      return {
        ...user,
        extraPoints,
        points: currentPoints,
        progress: currentPoints / target,
        target,
        total: company.data.config?.pointPrice * extraPoints
      }
    })

  const [handleChange, foundUsers] = useFilter(formatedData, [
    'name',
    'lastname'
  ])

  if (!users.loaded || !departments.loaded || !company.loaded)
    return <LoaderSistel />

  const tabs = [
    {
      key: 'mes-actual',
      title: 'Mes actual',
      children: <PointsSummaryTable data={foundUsers} company={company.data} />
    },
    {
      key: 'cierres',
      title: 'Cierres',
      children: (
        <MonthlyCloseTable
          data={foundUsers}
          company={company.data}
          {...{ monthList }}
        />
      )
    }
  ]

  const displayTabs = (defaultActive) => (
    <Tabs className="d-flex mb-4" defaultActiveKey={defaultActive}>
      {tabs.map((t) => (
        <Tab key={t.key} eventKey={t.key} title={t.title}>
          {t.children}
        </Tab>
      ))}
    </Tabs>
  )

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="puntos"
        title="Resumen mensual de puntos"
        handleSearch={handleChange}
        filters={
          <>
            <h4>Departamento</h4>
          </>
        }
      />
      {displayTabs('mes-actual')}
    </MainContainer>
  )
}
