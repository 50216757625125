import { useState } from 'react'
import { useDispatch } from 'react-redux'

import BrigadeCard from 'components/Brigades/BrigadeCard'
import BrigadesInfo from 'components/Brigades/BrigadesInfo'
import MainContainer from 'components/common/MainContainer'
import WithoutBrigadeColumn from 'components/Brigades/WithoutBrigadeColumn'
import { BrigadaAction } from 'pages'

import useModal from 'hooks/useModal'
import useFilter from 'hooks/useFilter'
import useDynamicFetch from 'hooks/useDynamicFetch'
import useCheckboxFilter from 'hooks/useCheckbox'
import { patchApi } from 'redux/actions/fetchActions'
import { getVehicles } from 'redux/actions/vehicleActions'
import { getDepartments } from 'redux/actions/departmentActions'
import { getBrigades, patchBrigade } from 'redux/actions/brigadeActions'
import { getUsers, resetUserBrigades } from 'redux/actions/userActions'
import { populateInstance } from 'utils/maps'
import { FIXED_DEPARMTENTS, BAREMO_TYPES } from 'utils/constants'

import {
  ModalSistel,
  LoaderSistel,
  HeaderFiltersSistel,
  ConfirmationModalSistel
} from 'npm-styles-sistel'

export default function Brigadas() {
  const dispatch = useDispatch()
  const users = useDynamicFetch('user', getUsers)
  const vehicles = useDynamicFetch('vehicle', getVehicles)
  const brigades = useDynamicFetch('brigade', getBrigades)
  const departments = useDynamicFetch('department', getDepartments)

  const [selectedBrigade, setSelectedBrigade] = useState(undefined)

  const { actions, show, title } = useModal()
  const { handleShow, handleClose } = actions

  const confirmationModal = useModal()

  const usersWithoutBrigade = users.data.filter(({ brigade, department }) => {
    const userDpmt = departments.data.find((d) => d._id === department)
    const isValid = FIXED_DEPARMTENTS.includes(userDpmt?.name)
    return !brigade && isValid
  })

  const activeBrigades = brigades.data.filter(({ active }) => active)
  const populatedData = activeBrigades.map((brigade) => ({
    ...brigade,
    driver: populateInstance(users.data, brigade.driver),
    manager: populateInstance(users.data, brigade.manager),
    users: users.data.filter((u) => u.active && u.brigade === brigade._id),
    vehicle: populateInstance(vehicles.data, brigade.vehicle)
  }))

  const [handleChange, foundBrigades] = useFilter(populatedData, ['name'])

  const departmentChecks = BAREMO_TYPES.map((e) => ({ _id: e, name: e }))
  const [displayBrigadeFilter, filteredBrigades] = useCheckboxFilter(
    [{ key: 'type' }],
    departmentChecks,
    foundBrigades
  )

  const handleSelect = (brigade) => {
    setSelectedBrigade(brigade)
    handleShow('Editar brigada')
  }

  const displayBrigades = filteredBrigades.map((brigade) => {
    return (
      <BrigadeCard
        brigade={brigade}
        editBrigade={handleSelect}
        key={brigade._id}
      />
    )
  })

  if (
    !brigades.loaded ||
    !users.loaded ||
    !vehicles.loaded ||
    !departments.loaded
  )
    return <LoaderSistel />

  const toggleBrigadeState = () => {
    const updateUserAndBrigade = (value) => {
      const brigadeUsers = users.data
        .filter((u) => u.brigade === selectedBrigade._id)
        .map((u) => u._id)
      dispatch(resetUserBrigades(brigadeUsers))
      return patchBrigade(value._id)
    }
    dispatch(
      patchApi(
        {
          ...selectedBrigade,
          active: !selectedBrigade.active,
          driver: null,
          manager: null
        },
        `/brigade/${selectedBrigade._id}`,
        [updateUserAndBrigade, handleClose(setSelectedBrigade)]
      )
    )
  }

  const deleteProps = {
    secBtnText: 'Eliminar brigada',
    secBtnFunction: () =>
      confirmationModal.actions.handleShow('Eliminar brigada')
  }
  const showDeleteProps = selectedBrigade ? deleteProps : {}

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="brigadas"
        title="Brigadas"
        buttonText="Nueva brigada"
        handleSearch={handleChange}
        buttonAction={() => handleShow('Nueva brigada')}
        filters={
          <>
            <h4>Departamento</h4>
            {displayBrigadeFilter}
          </>
        }
      />

      <div className="row my-5">
        <div className="col-md-3">
          <BrigadesInfo />
          <WithoutBrigadeColumn users={usersWithoutBrigade} />
        </div>

        <div className="col-md-9">
          <div className="row">{displayBrigades}</div>
        </div>
      </div>
      {show && (
        <ModalSistel
          {...{ show, title, ...showDeleteProps }}
          handleClose={handleClose(setSelectedBrigade)}
          iconTitle={selectedBrigade ? 'brigada' : 'brigadaNuevo'}
        >
          <BrigadaAction
            handleClose={handleClose(setSelectedBrigade)}
            brigade={selectedBrigade}
          />
          {confirmationModal.show && (
            <ConfirmationModalSistel
              buttonType="button"
              modalContent="¿Seguro que quieres borrar la brigada?"
              modalFunction={toggleBrigadeState}
              {...{ ...confirmationModal, ...confirmationModal.actions }}
            />
          )}
        </ModalSistel>
      )}
    </MainContainer>
  )
}
