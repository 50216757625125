import { IconosSistel } from 'npm-styles-sistel'

export default function NoResults({ text }) {
  return (
    <div className="no-results">
      <IconosSistel icon="buscar" width="52" color="#E1CFEA" />
      <p className="mt-3">{text}</p>
    </div>
  )
}
