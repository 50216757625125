import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useSocket from './useSocket'

const defaultCenter = {
  lat: 41.401543,
  lng: 2.171822
}

export default function useCoords(plate) {
  const me = useSelector((s) => s.me)

  const [coords, setCoords] = useState(undefined)
  const [center, setCenter] = useState(undefined)

  const [listening, setListening] = useState(false)

  const { socketRef } = useSocket({ room: me.company })

  const requestBody = {
    companyId: me.company,
    plate
  }

  useEffect(() => {
    if (!coords) {
      socketRef.current.emit('getCoords', requestBody)
    }

    const interval = setInterval(() => {
      socketRef.current.emit('getCoords', requestBody)
    }, 10000)

    return () => clearInterval(interval)
  }, [listening])

  useEffect(() => {
    socketRef.current.on('getCoords', (data) => {
      if (!listening) setListening(true)
      setCoords(data)
    })
  }, [])

  useEffect(() => {
    if (typeof center === 'undefined' && coords) {
      setCenter(coords)
    }
  }, [coords])

  return {
    center: center || defaultCenter,
    coords
  }
}
