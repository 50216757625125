import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE
} from '../actions/fetchActions'

const initialState = {
  loading: false,
  error: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, loading: true }
    case FETCH_SUCCESS:
      return {
        loading: false,
        error: ''
      }
    case FETCH_FAILURE:
      return {
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}
