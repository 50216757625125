import { useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import ImageUploading from 'react-images-uploading'

import Row from 'components/common/Row'
import BoxList from './Orders/BoxList'
import UUIIInput from './Orders/UUIIInput'
import { InputBaremos } from 'components/Orders/orderStyle'

import FileService from 'services/FileService'
import { postApi, putApi } from 'redux/actions/fetchActions'
import { checkBoxList } from 'utils/orders'
import { makeSelectOptions } from 'utils/maps'
import { FUSIONADOR, WORK_TYPES } from 'utils/constants'

import {
  ButtonSistel,
  InputCheckboxSistel,
  InputSelectSistel,
  InputSistel,
  TextAreaSistel
} from 'npm-styles-sistel'
import { StyledAttachment } from 'components/Orders/AttachmentPreview'

/**
 * TODO: Hace falta refactor. Hay +500 líneas.
 * Extraer componentes y usar fromik context hook.
 */

export default function OrderForm({
  order,
  allBrigades,
  allProjects,
  allBaremos
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentOrder] = useState(initialValues(order))

  const formik = useFormik({
    initialValues: currentOrder,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (values) => {
      const attachments = [
        ...values.attachments,
        ...(await FileService.uploadFiles(
          values.files.map(({ file }) => file)
        )),
        ...(await FileService.uploadFiles(values.pdf))
      ]

      const { pdf, files, images, ...restValues } = values

      const instanceBody = {
        ...restValues,
        attachments,
        baremoList: values.baremoList.map((b) => ({
          baremo: b.value,
          estimated: b.estimated
        }))
      }

      if (order._id) {
        if (order.type === FUSIONADOR) {
          const isBoxListUpdated = checkBoxList(order.boxList, values.boxList)
          instanceBody.isBoxListUpdated = isBoxListUpdated
          instanceBody.status = order.status
        }
        const goToOrder = () => history.push(`/ordenes/${order.ref}`)
        return dispatch(
          putApi(instanceBody, `/order/${instanceBody._id}`, [null, goToOrder])
        )
      }

      const goToOrders = () => history.push('/ordenes')
      dispatch(postApi(instanceBody, '/order', [null, goToOrders]))
    }
  })

  const {
    handleSubmit,
    errors,
    handleChange,
    values,
    setFieldValue,
    isSubmitting
  } = formik

  const handleSelect = (field) => (e) => setFieldValue(field, e.value)
  const handleSelectMulti = (field) => (e) => setFieldValue(field, e)
  const handleCheckbox = ({ target }) => setFieldValue('zone', target.value)
  const handleFile = (imageFiles) => setFieldValue('files', imageFiles)
  const deleteFile = (imageUrl) => {
    const updatedAttachments = values.attachments.filter(
      (url) => url !== imageUrl
    )
    setFieldValue('attachments', updatedAttachments)
  }
  const handlePDF = (e) => setFieldValue('pdf', e.target.files)
  const onClickLoadBaremos = () =>
    setFieldValue('baremoList', getBaremoOptions())

  const {
    baremoList,
    boxQty,
    brigade,
    cluster,
    priority,
    project,
    supervisorComment,
    uuiiDesigned,
    workType,
    zone
  } = values

  const projectOptions = allProjects.map((p) => ({
    label: `${p.ref} - ${p.title}`,
    value: p._id
  }))

  const brigadeOptions = allBrigades.map((b) => ({
    label: b.name,
    value: b._id
  }))

  const zoneOptions = (() => {
    if (!project) return []

    const currentProject = allProjects.find(({ _id }) => _id === project)
    const formattedZones = currentProject?.zone.map((z) => {
      return {
        label: z.title,
        value: z.title
      }
    })
    if (!zone) setFieldValue('zone', formattedZones[0]?.value)
    return formattedZones
  })()

  const getBaremoOptions = () => {
    function findBaremo(e) {
      return (
        currentProject && e.client === currentProject.client && e.pointValue
      )
    }

    const currentProject = allProjects.find(({ _id }) => _id === project)
    const baremoFiltered = allBaremos.filter((baremo) =>
      baremo.clients.find(findBaremo)
    )

    const baremoFilteredByOrderType = baremoFiltered.filter(
      (baremo) =>
        !baremo.types || baremo.types.includes(values.type.toUpperCase())
    )

    const formattedOptions = baremoFilteredByOrderType.map((b) => {
      return {
        label: b.description,
        value: b._id,
        estimated: 0
      }
    })

    return formattedOptions.sort((a, b) => a.label.localeCompare(b.label))
  }

  const generateBoxModel = (cluster, number) => ({
    uuii: '',
    name: `${cluster}.${number}`,
    status: ''
  })

  const addBox = () =>
    setFieldValue('boxList', [
      ...values.boxList,
      generateBoxModel(cluster, values.boxList.length + 1)
    ])

  const addMultipleBoxes = () => {
    const boxList = Array(boxQty)
      .fill({})
      .map((_, i) => generateBoxModel(cluster, i + 1))
    setFieldValue('boxList', boxList)
  }

  const removeBox = (boxIndex) => {
    setFieldValue(
      'boxList',
      values.boxList.filter((_, i) => i !== boxIndex)
    )
  }

  const submitButtonText = isSubmitting ? 'Guardando' : 'Guardar orden'

  return (
    <FormikProvider value={formik}>
      <form className="mt-5" onSubmit={handleSubmit}>
        <Row>
          <InputSelectSistel
            label="Proyecto"
            col="col-md-4"
            name="project"
            placeholder="Selecciona..."
            options={projectOptions}
            value={project}
            errors={errors.project}
            onChange={handleSelect('project')}
          />
          <InputSelectSistel
            label="Tipo de trabajo"
            col="col-md-4"
            placeholder="Selecciona..."
            name="workType"
            options={makeSelectOptions(WORK_TYPES)}
            value={workType}
            errors={errors.workType}
            onChange={handleSelect('workType')}
          />
          <div className="col-md-4">
            <h3>Zona</h3>
            <div className="row my-3">
              {zoneOptions.length ? (
                zoneOptions.map((z, i) => (
                  <InputCheckboxSistel
                    key={z.label + i}
                    col="col-auto"
                    type="radio"
                    name="zone"
                    onChange={handleCheckbox}
                    value={z.value}
                    defaultChecked={i === 0}
                    label={z.label}
                    checked={zone === z.value}
                  />
                ))
              ) : (
                <p>Se debe seleccionar un proyecto</p>
              )}
            </div>
          </div>
          <InputSistel
            type="text"
            label="Árbol o cluster"
            col="col-md-4"
            name="cluster"
            value={cluster}
            errors={errors.cluster}
            onChange={handleChange}
          />
          <UUIIInput
            orderType={order.type}
            handleChange={handleChange}
            uuiiDesigned={uuiiDesigned}
            errors={errors.uuiiDesigned}
          />

          <BoxQty
            isFusionador={values.type === FUSIONADOR}
            {...{ boxQty, handleChange }}
          />
          <InputSelectSistel
            label="Brigada"
            col="col-md-4"
            placeholder="Selecciona..."
            name="brigade"
            options={brigadeOptions}
            value={brigade}
            errors={errors.brigade}
            onChange={handleSelect('brigade')}
          />
          <InputSelectSistel
            label="Prioridad"
            col="col-md-4"
            name="priority"
            placeholder="Selecciona..."
            options={makeSelectOptions(['P01', 'P02', 'P03', 'P04'])}
            value={priority}
            errors={errors.priority}
            onChange={handleSelect('priority')}
          />
          <Attachments {...{ deleteFile, values, handleFile, handlePDF }} />
          <div className="col-md-6">
            <TextAreaSistel
              label="Comentarios del supervisor"
              name="supervisorComment"
              value={supervisorComment}
              errors={errors.supervisorComment}
              onChange={handleChange}
            />
          </div>
          <BoxList
            type={values.type}
            boxList={values.boxList}
            handleChange={handleChange}
            {...{
              addBox,
              addMultipleBoxes,
              removeBox,
              handleChange,
              cluster,
              boxQty: values.boxQty
            }}
          />
          <div className="col-md-12">
            <p className="subtitle-form mb-3">Tareas</p>
            <Row>
              <div className="col-md-6">
                <ButtonPresetBaremos
                  action={onClickLoadBaremos}
                  hasProject={project}
                  orderType={values.type}
                />

                <InputSelectSistel
                  label="Baremos"
                  name="baremoList"
                  placeholder="Selecciona..."
                  isMulti
                  options={getBaremoOptions()}
                  value={baremoList}
                  errors={errors.priority}
                  onChange={handleSelectMulti('baremoList')}
                />
              </div>
              <div className="col-md-6">
                {baremoList.length ? (
                  <BaremoInputs
                    baremos={baremoList}
                    handleChange={handleChange}
                  />
                ) : null}
              </div>
            </Row>
          </div>
        </Row>

        <Row className="mt-5">
          <div className="col-md-12">
            <div className="float-right">
              <ButtonSistel
                edit
                type="button"
                className="mr-2"
                onClick={() => history.goBack()}
              >
                Cancelar
              </ButtonSistel>
              <ButtonSistel success type="submit" disabled={isSubmitting}>
                {submitButtonText}
              </ButtonSistel>
            </div>
          </div>
        </Row>
      </form>
    </FormikProvider>
  )
}

function BoxQty({ boxQty, handleChange, isFusionador }) {
  if (!isFusionador) return null

  return (
    <InputSistel
      type="number"
      label="Cantidad de cajas"
      col="col-md-4"
      min="0"
      name="boxQty"
      value={boxQty}
      onChange={handleChange}
    />
  )
}

function ButtonPresetBaremos({ hasProject, action, orderType }) {
  if (!hasProject) return null
  return (
    <ButtonSistel primary onClick={action} type="button" className="my-4">
      Cargar baremos de {orderType.toLowerCase()}
    </ButtonSistel>
  )
}

function BaremoInputs({ baremos, handleChange }) {
  if (!baremos.length) return null

  const displayBaremos = baremos.map((b, i) => (
    <tr key={i}>
      <td>{b.label}</td>
      <td className="text-right">
        <InputBaremos
          type="number"
          onChange={handleChange}
          name={`baremoList[${i}].estimated`}
          value={`${b.estimated}`}
        />
      </td>
    </tr>
  ))

  return (
    <div className="col-md-12">
      <table className="table">
        <thead>
          <tr>
            <th>Baremo</th>
            <th className="text-right">Cantidad</th>
          </tr>
        </thead>
        <tbody>{displayBaremos}</tbody>
      </table>
    </div>
  )
}

function Attachments({ values, handleFile, handlePDF, deleteFile }) {
  return (
    <>
      <ImageUploading
        multiple
        label="adjuntos"
        value={values.images}
        onChange={handleFile}
        maxNumber={10}
        dataURLKey="data_url"
      >
        {({ onImageUpload, onImageRemove, dragProps }) => (
          <div className="justify-content-between col-md-4">
            <div className="d-flex align-items-center form-group">
              <label>Imágenes</label>
            </div>
            <div className="upload__image-wrapper">
              <ButtonSistel
                onClick={onImageUpload}
                type="button"
                {...dragProps}
              >
                + Subir fotos
              </ButtonSistel>
              <div className="mt-2">
                {values.files?.map((image, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => onImageRemove(index)}
                    className="mx-1"
                  >
                    <img src={image.data_url} width="100" />
                  </button>
                ))}
              </div>
              <div className="mt-2">
                {values.attachments.map((image) => (
                  <StyledAttachment
                    key={image}
                    src={image}
                    width="100"
                    height="100"
                    onClick={() => deleteFile(image)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </ImageUploading>
      <InputSistel
        type="file"
        col="col-md-6"
        label="Subir PDF"
        name="pdf"
        onChange={handlePDF}
      />
    </>
  )
}

function initialValues(order) {
  const formattedBaremoList = order?.baremoList?.map((b) => ({
    label: b.baremo.description,
    value: b.baremo._id,
    estimated: b.estimated || 0
  }))

  const boxList = order.type === FUSIONADOR ? order?.boxList || [] : undefined

  return {
    _id: order?._id,
    attachments: order?.attachments || [],
    baremoList: formattedBaremoList || [],
    boxList,
    boxQty: order?.boxList?.length || '',
    brigade: order?.brigade || undefined,
    cluster: order?.cluster || '',
    files: [],
    images: '',
    pdf: [],
    priority: order?.priority || 'P04',
    project: order?.project || '',
    supervisorComment: order?.supervisorComment || '',
    type: order?.type || '',
    uuiiDesigned: order?.uuiiDesigned || '',
    workType: order?.workType || '',
    zone: order?.zone || ''
  }
}

function validationSchema(orderType) {
  return {
    project: Yup.string().required(),
    type: Yup.string().required(),
    workType: Yup.string().required(),
    uuiiDesigned:
      orderType === FUSIONADOR ? Yup.number().required() : Yup.number(),
    cluster: Yup.string().required(),
    brigade: Yup.string().nullable(),
    priority: Yup.string().required(),
    attachments: Yup.array(),
    baremoList: Yup.array(),
    supervisorComment: Yup.string()
  }
}
