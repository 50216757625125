export const GET_BAREMOS = 'GET_BAREMOS'
export const PUT_BAREMO = 'PUT_BAREMO'
export const POST_BAREMO = 'POST_BAREMO'
export const PATCH_BAREMO = 'PATCH_BAREMO'

// ACTIONS
export const getBaremos = (data) => ({
  type: GET_BAREMOS,
  payload: data
})

export const postBaremo = (data) => ({
  type: POST_BAREMO,
  payload: data
})

export const putBaremo = (data) => ({
  type: PUT_BAREMO,
  payload: data
})

export const patchBaremo = (data) => ({
  type: PATCH_BAREMO,
  payload: data
})
