import React from 'react'
import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'

const Bar = styled.div`
  height: 6px;
  position: relative;
  width: 100%;
  margin: 12px 0 6px 0;
  background-color: ${themeSistel.colors.grey_mid};
  border-radius: 6px;
  overflow: hidden;
`
const ValidatedBar = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ validated }) => (validated > 100 ? 100 : validated)}%;
  background: ${themeSistel.colors.success_mid};
  border-radius: ${({ pending }) => (pending ? '6px 0 0 6px' : '6px')};
`

const PendingBar = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: ${({ validated }) => validated}%;
  width: ${({ pending }) => pending}%;
  background: ${themeSistel.colors.warning_mid};
  border-radius: ${({ validated }) => (validated ? '0 6px 6px 0' : '6px')};
`

const OrderProgressBar = ({ validated = 0, pending = 0 }) => {
  return (
    <Bar>
      <ValidatedBar validated={validated} pending={pending} />
      <PendingBar validated={validated} pending={pending} />
    </Bar>
  )
}

export default OrderProgressBar
