import useStateWithDep from 'hooks/useStateWithDep'
import { BadgeSistel } from 'npm-styles-sistel'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import Table from './Table'

export default function ProjectsTable({ data }) {
  const [projects, setProjects] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      projects.map((project) => ({
        ref: (
          <Link className="table-link" to={`/proyectos/${project.ref}`}>
            {project.ref}
          </Link>
        ),
        title: project.title,
        client: project.client.name,
        startDate: new Date(project.startDate).toLocaleDateString(),
        state: {
          value: project.inProgress ? (
            <BadgeSistel blue>En proceso</BadgeSistel>
          ) : (
            <BadgeSistel grey>Sin empezar</BadgeSistel>
          ),
          className: 'text-right'
        },
        key: project._id
      })),
    [projects]
  )

  const headers = useMemo(
    () => [
      { header: 'Referencia', sort: true, reverse: true },
      { header: 'Título', sort: false },
      { header: 'Cliente', sort: false },
      { header: 'Inicio', sort: true, reverse: true },
      { header: 'Estado', sort: false, className: 'text-right' }
    ],
    []
  )

  return <Table {...{ data: projects, tableData, headers, cb: setProjects }} />
}
