export function mergeSumObjects(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  const hasCommonProp = keys2.some((e) => keys1.includes(e))

  if (!hasCommonProp) return { ...obj1, ...obj2 }

  const mergedObject = { ...obj1 }

  for (const prop in obj2) {
    mergedObject[prop]
      ? (mergedObject[prop] = mergedObject[prop] + obj2[prop])
      : (mergedObject[prop] = obj2[prop])
  }

  return mergedObject
}
