import axios from 'services/axios'
import { useEffect, useState } from 'react'

export default function useCompleteOrder(orderRef) {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState({})

  const fetchData = async () => {
    try {
      const response = await axios.get(`/order/ref/${orderRef}`)
      const responseOk = response && response.status < 400
      if (!responseOk) throw new Error(response.data)

      setData(response.data.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData().then(() => setFetching(false))
  }, [])

  const refetch = async () => {
    setFetching(true)
    fetchData().then(() => setFetching(false))
  }

  return {
    data,
    fetching,
    refetch
  }
}
