export default function MainContainer({ children, className, style }) {
  return (
    <div
      className={`container margin-main-container ${className}`}
      style={style}
    >
      {children}
    </div>
  )
}
