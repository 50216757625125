import Header from 'components/common/Header'
import MainContainer from 'components/common/MainContainer'
import { VehicleForm } from 'components/forms'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { LoaderSistel } from 'npm-styles-sistel'
import { getVehicles } from 'redux/actions/vehicleActions'
import { isEditRoute } from 'utils/strings'

export default function VehiculoAction({ match }) {
  const { loaded, data } = useDynamicFetch('vehicle', getVehicles)

  if (!loaded) return <LoaderSistel />

  if (isEditRoute(match)) {
    const vehicleFound = data.find((v) => v.plate === match.params.plate)
    return (
      <MainContainer>
        <Header title="Editar vehículo" />
        <VehicleForm vehicle={vehicleFound} />
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      <div className="mw-900">
        <Header title="Nuevo vehículo" />
        <VehicleForm />
      </div>
    </MainContainer>
  )
}
