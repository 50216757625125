import { IconosSistel } from 'npm-styles-sistel'
import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'

const Button = styled.button`
  width: 50px;
  height: 50px;
  background: ${themeSistel.colors.grey_mid};
  border-radius: 10px;
`

const DeleteButton = styled(Button)`
  background: ${themeSistel.colors.danger_light};
`

export default function ActionButton({ isDelete, isEdit, ...rest }) {
  if (isDelete)
    return (
      <DeleteButton {...rest}>
        <IconosSistel icon="eliminar" color="#CD0038" width="20"/>
      </DeleteButton>
    )

  return (
    <Button {...rest}>
      <IconosSistel icon="edit" color="#221C38" width="20"/>
    </Button>
  )
}
