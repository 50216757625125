import axios from 'services/axios'
import { useState, useEffect } from 'react'

export default function useDocumentsProject(projectRef) {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState(null)

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/project/documents-project/${projectRef}`
      )
      const responseOk = response && response.status < 400
      if (!responseOk) throw new Error(response.data)

      setData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchData().then(() => setFetching(false))
  }, [])

  return [data, fetching]
}
