import { IconosSistel } from 'npm-styles-sistel'
import { Link } from 'react-router-dom'
import { OrderCardStyled } from './orderStyle'

export default function CertifiedOrders({ order }) {
  return (
    <>
      <OrderCardStyled>
        <Link to={`/ordenes/${order.ref}`}>
          <div className="flex-between mr-5">
          <div>
            <h4>{order.cluster}</h4>
            <p className="project">
              <IconosSistel icon="proyectos" width="12" color="#9D98AE" />
              {order.project?.title}
            </p>
          </div>
          <div className="uuii">
            <h5>{order.uuiiBuilt}</h5>
            <p>UUII<br/>construidas</p>
          </div>
          <div className="uuii">
            <h5>{order.uuiiCertified}</h5>
            <p>UUII<br/>certificadas</p>
          </div>
        </div>
        </Link>
        
      </OrderCardStyled>
    </>
  )
}
