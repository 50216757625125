import { useMemo } from 'react'
import Table from './Table'
import { Link } from 'react-router-dom'

export default function UserPointsTable({ points }) {
  const tableData = useMemo(
    () =>
      points.map((p) => {
        const date = new Date(p.createdAt)
        const extraPoints = p.qty > 0 ? 'Bonificación' : 'Penalización'

        return {
          date: date.toLocaleDateString('es-ES'),
          order: p.order ? (
            <Link to={`/ordenes/${p.order.ref}`} className="table-link">
              {p.order.cluster}
            </Link>
          ) : (
            extraPoints
          ),
          qty: { value: p.qty.toFixed(2), className: 'text-right' },
          key: p._id
        }
      }),
    []
  )

  const headers = useMemo(
    () => [
      { header: 'Día', sort: false },
      { header: 'Orden de trabajo', sort: false },
      { header: 'Puntos', sort: false, className: 'text-right' }
    ],
    []
  )

  return <Table {...{ data: points, tableData, headers }} />
}
