import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ADMIN } from 'utils/constants'
import ProtectedRoute from './ProtectedRoute'

export default function AdminRoute({ path, exact, component }) {
  const me = useSelector((s) => s.me)

  if (!me) return <Redirect to="/login" />

  if (me.role !== ADMIN) {
    return <Redirect to="/" />
  }

  return <ProtectedRoute {...{ path, component }} exact={exact} />
}
