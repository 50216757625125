import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import { IconosSistel } from 'npm-styles-sistel'
import Icon from '@mdi/react'
import { mdiChevronDown } from '@mdi/js'

export default function NavbarLink({ path, label, icon, submenu, ...rest }) {
  const { pathname } = useLocation()

  const activeColor = '#5F35FF'
  const defaultColor = '#33205C'

  const determineIconColor = submenu
    ? pathname.includes(path)
      ? activeColor
      : defaultColor
    : pathname === path
    ? activeColor
    : defaultColor

  return (
    <NavLink
      to={path}
      className="nav-item"
      activeClassName="nav-item nav-item-active"
      isActive={() => (submenu ? pathname.includes(path) : pathname === path)}
    >
      <div className="icon">
        <IconosSistel icon={icon} color={determineIconColor} {...rest} />
      </div>
      {label}
      {submenu && <Icon path={mdiChevronDown} size={0.8} className="ml-1" />}
    </NavLink>
  )
}
