import { useEffect, useState } from 'react'
import axios from 'axios'

import { BAREMO_TYPES, WORK_TYPES } from 'utils/constants'
import { capitalize } from 'utils/strings'

export default function useGetOrderFilters() {
  const [isLoading, setIsLoading] = useState(true)
  const [loadedFilters, setLoadedFilters] = useState({})

  const getFilters = async () => {
    try {
      const response = await axios.get('/order/filters')
      if (response?.status < 400) {
        return response.data
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getFilters().then((filters) => {
      if (filters) {
        setLoadedFilters(filters)
        setIsLoading(false)
      }
    })
  }, [])

  const orderTypeChecks = BAREMO_TYPES.map((wt) => ({
    _id: wt,
    name: capitalize(wt.toLowerCase())
  }))
  const workTypeChecks = WORK_TYPES.map((wt) => ({ _id: wt, name: wt }))

  const selectors = {
    type: orderTypeChecks,
    workType: workTypeChecks,
    brigade: loadedFilters.activeBrigades || [],
    project: loadedFilters.activeProjects || []
  }

  return [isLoading, selectors]
}
