import { saveAs } from 'file-saver'
import * as ZipJS from 'jszip'

const filenameTypes = {
  panoramic: 'panoramica',
  closedBox: 'caja_cerrad',
  openBox: 'caja_abierta',
  cableLabeling: 'etiquetado',
  powerMeasure: 'potencia'
}

function getFileName(box, fileNameType, num) {
  return num
    ? `${box}_${fileNameType}_${num}.png`
    : `${box}_${fileNameType}.png`
}

export async function downloadImgZip(boxPhotos, boxName) {
  const types = Object.keys(boxPhotos)
  const imgs = Object.values(boxPhotos).filter((e) => e)

  const filenamesArr = imgs
    .map((imgGroup, idx) =>
      imgGroup.map((_, num, arr) =>
        arr.length > 1
          ? getFileName(boxName, filenameTypes[types[idx]], num + 1)
          : getFileName(boxName, filenameTypes[types[idx]])
      )
    )
    .flat()

  const imgArr = imgs.flat()
  const blobsArr = await getFilesFromUrl(imgArr)
  fileToBase64(blobsArr, filenamesArr, boxName, exportToZip)
}

async function getFilesFromUrl(arr) {
  const promisesArr = arr.map(async (url) => {
    const response = await fetch(url)
    return response.blob()
  })
  return await Promise.all(promisesArr)
}

function fileToBase64(files, filenames, boxName, callback) {
  const filesArr = []
  files.forEach((file, i) => {
    const isLastFile = i === files.length - 1
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      let auxArray = []
      const base64 = reader.result
      auxArray = base64.split(',')
      filesArr.push({ base64: auxArray[1], name: filenames[i] })
      isLastFile && callback(filesArr, boxName)
    }
  })
}

function exportToZip(filesArr, boxName) {
  const zip = ZipJS()
  filesArr.forEach((file) => {
    zip.file(file.name, file.base64, { base64: true })
  })
  zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, `${boxName}.zip`)
  })
}
