import { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import VehicleHistoricTable from 'components/Tables/VehicleHistoricTable'
import useModal from 'hooks/useModal'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { patchApi } from 'redux/actions/fetchActions'
import { getVehicles, patchVehicle } from 'redux/actions/vehicleActions'
import {
  IconosSistel,
  BreadcrumbsSistel,
  LoaderSistel,
  ConfirmationModalSistel,
  DataGroupSistel,
  ButtonSistel,
  ModalSistel
} from 'npm-styles-sistel'
import MainContainer from 'components/common/MainContainer'
import { VehicleForm } from 'components/forms'

import Map from 'components/Maps/Map'

export default function VehiculoDetalles({ match }) {
  const history = useHistory()
  const location = useLocation()

  const dispatch = useDispatch()
  const vehicles = useDynamicFetch('vehicle', getVehicles)

  const { actions, title, show } = useModal()
  const { onClose, handleShow } = actions

  const [showEdit, setShowEdit] = useState(null)

  if (!vehicles.loaded) return <LoaderSistel />

  const vehicle = vehicles.data.find((v) => v.plate === match.params.plate)

  const goBack = () => history.goBack()

  const handleDeleteVehicle = () => {
    dispatch(
      patchApi(vehicle._id, `/vehicle/${vehicle._id}`, [patchVehicle, goBack])
    )
  }

  return (
    <MainContainer>
      <div className="flex-between">
        <BreadcrumbsSistel
          baseTitle="Vehículos"
          baseLink={() => history.push('/vehiculos')}
          current={vehicle.plate}
        />
        <div className="mb-5">
          <ButtonSistel
            danger
            onClick={() => handleShow('Desactivar vehículo')}
            className="mr-2"
          >
            Eliminar vehículo
          </ButtonSistel>
          <ButtonSistel edit onClick={() => setShowEdit(true)}>
            Editar vehículo
          </ButtonSistel>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <VehicleInfo vehicle={vehicle} />
        </div>
        <div className="col-md-8">
          <div className="mb-4">
            <Map plate={vehicle.plate} externalCenter={location.state} />
          </div>
          <VehicleHistoricTable historic={vehicle.historic} />
        </div>
      </div>
      {show && (
        <ConfirmationModalSistel
          buttonType="button"
          modalFunction={handleDeleteVehicle}
          modalContent="¿Seguro que quieres borrar el vehículo?"
          {...{ title, onClose }}
        />
      )}
      {showEdit && (
        <ModalSistel
          title={`Editar ${vehicle.plate}`}
          iconTitle="vehiculos"
          buttons={false}
          handleClose={() => setShowEdit(null)}
        >
          <VehicleForm
            vehicle={vehicle}
            handleClose={() => setShowEdit(null)}
          />
        </ModalSistel>
      )}
    </MainContainer>
  )
}

function VehicleInfo({ vehicle }) {
  return (
    <>
      <div className="card">
        <div className="d-flex">
          <IconosSistel icon="vehiculos" width="22" />
          <h1 className="ml-3">{vehicle.plate}</h1>
        </div>

        <hr />
        <DataGroupSistel title="Marca" data={vehicle.brand} />
        <DataGroupSistel title="Modelo" data={vehicle.model} />
        <DataGroupSistel title="Plazas" data={vehicle.seats} />
        <DataGroupSistel title="KM de Recogida" data={vehicle.pickupKm} />
        <DataGroupSistel title="KM de Entrega" data={vehicle.deliveryKm} />
        <DataGroupSistel
          title="Coste mensual"
          data={vehicle.monthlyCost + '€'}
        />
      </div>
    </>
  )
}
