import { useSelector } from 'react-redux'

import UserNavbar from './components/Navbar/UserNavbar'

// Components
import Router from 'routes'

function App() {
  const me = useSelector((s) => s.me)

  const displayNavbar = me && <UserNavbar user={me} />
  return (
    <>
      {displayNavbar}
      <Router />
    </>
  )
}

export default App
