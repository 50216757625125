import { DarkCardSistel } from 'npm-styles-sistel'

export default function EmployeeDarkCards({
  showModal,
  pointPrice,
  monthlyGoal,
  monthlyPoints
}) {
  return (
    <div className="row mb-4">
      <div className="col-md-4">
        <DarkCardSistel onClick={() => showModal('pointPrice')}>
          <h4>
            {pointPrice}
            <span>€</span>
          </h4>
          <p>Precio del punto</p>
        </DarkCardSistel>
      </div>
      <div className="col-md-4">
        <DarkCardSistel onClick={() => showModal('monthlyGoal')}>
          <h4>{monthlyGoal}</h4>
          <p>Objetivo mensual</p>
        </DarkCardSistel>
      </div>
      <div className="col-md-4">
        <DarkCardSistel>
          <h4>{Math.round(monthlyPoints)}</h4>
          <p>Puntos conseguidos</p>
        </DarkCardSistel>
      </div>
    </div>
  )
}
