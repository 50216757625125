import { useState } from 'react'
import { InputCheckboxSistel } from 'npm-styles-sistel'
/**
 *
 * @param {Array.<String>} filterKey Array of object properties to find filter target
 * @param {Array.<Object>} selectorsArray Array of selectors
 * @param {Array.<Object>} data Array of data to be filtered
 *
 * @returns {<Array.<Object>} JSX array, display checkboxes
 * @returns {<Array.<Object>} Array param data filtered by checkbox selection
 */
export default function useCheckboxFilter(filterKey, selectorsArray, data) {
  const [filters, setFilters] = useState([])

  const handleCheck =
    (value) =>
    ({ target }) => {
      if (!target.checked) {
        return setFilters(filters.filter((id) => id !== value))
      }
      setFilters([...filters, value])
    }

  const displayCheckboxFilter = selectorsArray.map(({ _id, name }) => (
    <InputCheckboxSistel key={name} label={name} onChange={handleCheck(_id)} />
  ))

  if (!filters.length) return [displayCheckboxFilter, data]

  // TODO: Revisar funcionalidad por si se puede hacer mas fácil la lectura
  // Añadir esquemas de como tiene que ser los datos que recibe

  const filteredData =
    filterKey.length === 1
      ? data.filter((d) =>
          filterKey[0].subKey
            ? d[filterKey[0].key] &&
              filters.includes(d[filterKey[0].key][filterKey[0].subKey])
            : filters.includes(d[filterKey[0].key])
        )
      : data.filter((d) => {
          const filteredDataWithValue = d[filterKey[0].key].filter((e) => {
            // Caso 'object' true --> Ver filtro baremos
            // Caso 'object' false --> Ver filtro gaps
            return typeof e[filterKey[1].key] === 'object'
              ? filters.includes(e[filterKey[1].key][filterKey[1].subKey]) &&
                  e[filterKey[1].value]
              : filters.includes(e[filterKey[1].key])
          })
          return Boolean(filteredDataWithValue.length)
        })

  return [displayCheckboxFilter, filteredData]
}
