import { Route, Switch } from 'react-router-dom'
import * as P from 'pages'
import ProtectedRoute from 'components/Routes/ProtectedRoute'
import AdminRoute from 'components/Routes/AdminRoute'
import RRHHRoute from 'components/Routes/RRHHRoute'
import DesignerRoute from 'components/Routes/DesignerRoutes'
import SupervisorRoute from 'components/Routes/SupervisorRoute'

export default function Router() {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={P.Dashboard} />

      {/* Rutas de brigadas */}
      <RRHHRoute exact path="/brigadas" component={P.Brigadas} />

      {/* Rutas de baremos */}
      <SupervisorRoute exact path="/baremos" component={P.Baremos} />

      {/* Rutas de empresa */}
      <AdminRoute exact path="/empresa" component={P.Empresa} />
      <AdminRoute exact path="/configuracion" component={P.Configuracion} />

      {/* Rutas de notificaciones */}
      <ProtectedRoute
        exact
        path="/notificaciones"
        component={P.Notificaciones}
      />

      {/* Rutas de informes */}
      <SupervisorRoute exact path="/informes" component={P.Informes} />
      <SupervisorRoute
        exact
        path="/informes/ingenieria"
        component={P.InformesIngenieria}
      />
      <SupervisorRoute
        exact
        path="/informes/baremos"
        component={P.InformesBaremos}
      />

      {/* Rutas de clientes */}
      <SupervisorRoute exact path="/clientes" component={P.Clientes} />

      {/* Rutas de empleados */}
      <RRHHRoute exact path="/empleados" component={P.Empleados} />
      <RRHHRoute path="/empleados/:userId" component={P.EmpleadoDetalles} />
      {/* Rutas de ordenes */}
      <DesignerRoute exact path="/ordenes" component={P.Ordenes} />
      <DesignerRoute exact path="/ordenes/huecos" component={P.OrdenesHuecos} />
      <DesignerRoute
        exact
        path="/ordenes/finalizadas"
        component={P.OrdenesFinalizadas}
      />
      <DesignerRoute exact path="/ordenes/nueva" component={P.OrdenAction} />
      <DesignerRoute
        path="/ordenes/editar/:orderRef"
        component={P.OrdenAction}
      />
      <DesignerRoute path="/ordenes/:orderRef" component={P.OrdenDetalles} />
      {/* Rutas de proyectos */}
      <SupervisorRoute exact path="/proyectos" component={P.Proyectos} />
      <SupervisorRoute
        path="/proyectos/:projectRef"
        component={P.ProyectoDetalles}
      />
      {/* Rutas de puntos */}
      <RRHHRoute exact path="/puntos" component={P.PuntosResumen} />
      <SupervisorRoute
        exact
        path="/puntos-gestion"
        component={P.PuntosGestion}
      />

      {/* Rutas de vehiculos */}
      <RRHHRoute exact path="/vehiculos" component={P.Vehiculos} />
      <RRHHRoute exact path="/vehiculos/nuevo" component={P.VehiculoAction} />
      <RRHHRoute path="/vehiculos/editar/:plate" component={P.VehiculoAction} />
      <RRHHRoute
        exact
        path="/vehiculos/:plate"
        component={P.VehiculoDetalles}
      />
      {/* Rutas del gestor documental */}
      <DesignerRoute exact path="/documentos" component={P.GestorDocs} />
      <DesignerRoute
        exact
        path="/documentos/:projectRef"
        component={P.GestorDocsProject}
      />
      <DesignerRoute
        exact
        path="/documentos/:projectRef/:clusterRef"
        component={P.GestorDocsCluster}
      />
      <DesignerRoute exact path="/entregas" component={P.UltimasEntregas} />
      {/* Resto */}
      <ProtectedRoute exact path="/perfil" component={P.Perfil} />
      <Route exact path="/login" component={P.Login} />
      <Route path="*" component={P.NoMatch} />
    </Switch>
  )
}
