import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import Row from 'components/common/Row'

import { ButtonSistel, InputSistel } from 'npm-styles-sistel'
import { putApi } from 'redux/actions/fetchActions'
import { putUser } from 'redux/actions/userActions'

export default function PasswordForm() {
  const dispatch = useDispatch()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      currentPw: '',
      newPw: '',
      newPw2: ''
    },
    validateOnChange: false,
    validationSchema: Yup.object(validationSchema()),
    onSubmit: (values) => {
      const goBack = () => history.push('/')
      const { newPw, newPw2 } = values
      if (newPw !== newPw2) {
        const error = 'Los passwords deben coincidir'
        setErrors({ newPw: error, newPw2: error })
      } else {
        // TODO: Show error
        dispatch(putApi(values, '/user/updatepassword', [putUser, goBack]))
      }
    }
  })

  const { values, errors, handleChange, handleSubmit, setErrors } = formik

  return (
    <>
      <h2>Cambiar contraseña</h2>
      <form className="mt-5" onSubmit={handleSubmit}>
        <Row>
          <InputSistel
            type="password"
            label="Contraseña actual"
            col="col-md-7"
            name="currentPw"
            value={values.currentPw}
            errors={errors.currentPw}
            onChange={handleChange}
          />
          <InputSistel
            type="password"
            label="Nueva contraseña"
            col="col-md-7"
            name="newPw"
            value={values.newPw}
            errors={errors.newPw}
            onChange={handleChange}
          />
          <InputSistel
            type="password"
            label="Confirma tu nueva contraseña"
            col="col-md-7"
            name="newPw2"
            value={values.newPw2}
            errors={errors.newPw2}
            onChange={handleChange}
          />
        </Row>
        <ButtonSistel success>Cambiar contraseña</ButtonSistel>
      </form>
    </>
  )
}

function validationSchema() {
  return {
    currentPw: Yup.string().required('Campo obligatorio'),
    newPw: Yup.string().required('Campo obligatorio'),
    newPw2: Yup.string().required('Campo obligatorio')
  }
}
