import styled from 'styled-components'

export const LoginStyled = styled.div`
  background-image: url('/images/background-login.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .all-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25vh;

    @media (max-width: 1500px) {
      margin-top: 20vh;
    }

    img {
      width: 112px;
    }

    h1 {
      color: white;
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 24px;
    }

    form {
      display: flex;
      align-items: center;
      flex-direction: column;

      div {
        margin-bottom: 0;
      }

      input {
        text-align: center;
      }

      button {
        margin: 24px auto 0 auto;
      }
    }
  }

  .copyright {
    color: white;
    text-align: center;
  }
`
