import OrderProgressBar from '../OrderProgressBar'

import { PENDING, VALIDATED } from 'utils/constants'
import { mergeSumObjects } from 'utils/objects'

export default function CeladorProgressBar({ order }) {
  const baremoEstimation = order.baremoList.reduce(
    (acc, { baremo: b, estimated: e }) =>
      acc[b] ? { ...acc, [b]: acc[b] + e } : { ...acc, [b]: e },
    {}
  )
  const hasEstimation = Object.values(baremoEstimation).some((v) => v)

  const hasMandatoryData = order.brigade && order.status && hasEstimation
  if (!hasMandatoryData) return null

  const validatedDeliveries = order.employeeDeliveries
    .filter((ed) => ed.status === VALIDATED)
    .reduce(
      (acc, { baremoEntries: be }) =>
        be.length ? mergeSumObjects(acc, baremosReducer(be)) : acc,
      {}
    )

  const pendingDeliveries = order.employeeDeliveries
    .filter((ed) => ed.status === PENDING)
    .reduce(
      (acc, { baremoEntries: be }) =>
        be.length ? mergeSumObjects(acc, baremosReducer(be)) : acc,
      {}
    )

  const progressStatus = Object.keys(baremoEstimation)
    .map((b) => ({
      estimated: baremoEstimation[b] ?? 0,
      validated: validatedDeliveries[b] ?? 0,
      pending: pendingDeliveries[b] ?? 0
    }))
    .reduce((acc, v) => mergeSumObjects(acc, v), {
      estimated: 0,
      validated: 0,
      pending: 0
    })

  const validationStatus = Math.round(
    (100 * progressStatus.validated) / progressStatus.estimated
  )

  const pendingStatus = Math.round(
    (100 * progressStatus.pending) / progressStatus.estimated
  )

  return (
    <OrderProgressBar validated={validationStatus} pending={pendingStatus} />
  )
}

function baremosReducer(baremosArr) {
  return baremosArr.reduce(
    (acc, { baremo: b, qty }) =>
      acc[b._id]
        ? { ...acc, [b]: acc[b] + (qty || 0) }
        : { ...acc, [b]: qty || 0 },
    {}
  )
}
