export const GET_VEHICLES = 'GET_VEHICLES'
export const PATCH_VEHICLE = 'PATCH_VEHICLE'
export const POST_VEHICLE = 'POST_VEHICLE'
export const PUT_VEHICLE = 'PUT_VEHICLE'

// ACTIONS
export const getVehicles = (data) => ({
  type: GET_VEHICLES,
  payload: data
})

export const postVehicle = (data) => ({
  type: POST_VEHICLE,
  payload: data
})

export const putVehicle = (data) => ({
  type: PUT_VEHICLE,
  payload: data
})

export const patchVehicle = (data) => ({
  type: PATCH_VEHICLE,
  payload: data
})
