import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useStateWithDep from 'hooks/useStateWithDep'

import Table from './Table'

export default function VehiclesTable({ data }) {
  const [vehicles, setVehicles] = useStateWithDep(data)

  const tableData = useMemo(() => {
    return vehicles.map((vehicle) => ({
      plate: (
        <Link className="table-link" to={`/vehiculos/${vehicle.plate}`}>
          {vehicle.plate}
        </Link>
      ),
      model: `${vehicle.brand} ${vehicle.model}`,
      monthlyCost: vehicle.monthlyCost || '-',
      pickupKm: vehicle.pickupKm || '-',
      deliveryKm: vehicle.deliveryKm || '-',
      key: vehicle._id
    }))
  }, [vehicles])

  const headers = useMemo(
    () => [
      { header: 'Matrícula', sort: true },
      { header: 'Modelo', sort: true },
      { header: 'Coste', sort: true },
      { header: 'Km recogida', sort: true },
      { header: 'Km retorno', sort: true }
    ],
    []
  )

  return <Table {...{ data: vehicles, tableData, headers, cb: setVehicles }} />
}
