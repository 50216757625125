import { BadgeSistel } from 'npm-styles-sistel'

export default function GapReason({ gap }) {
  return gap === 'Permiso' ? (
    <BadgeSistel warning>{gap}</BadgeSistel>
  ) : gap === 'Obstrucción' ? (
    <BadgeSistel danger>{gap}</BadgeSistel>
  ) : (
    <BadgeSistel grey>{gap}</BadgeSistel>
  )
}
