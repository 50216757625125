import { useState } from 'react'
import Header from 'components/common/Header'
import BaremosFilterTable from 'components/Tables/BaremosFilterTable'
import { BaremoFilterForm } from 'components/forms'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { getBaremos } from 'redux/actions/baremoActions'
import { getFilters } from 'redux/actions/baremoFilterActions'
import { populateInstance } from 'utils/maps'
import { ModalSistel } from 'npm-styles-sistel'

export default function BaremoFilters() {
  const baremos = useDynamicFetch('baremo', getBaremos)
  const filters = useDynamicFetch('baremoFilter', getFilters)

  const [show, setShow] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [currentElement, setCurrentElement] = useState(undefined)

  if (!baremos.loaded || !filters.loaded) return <h1>Loading</h1>

  const formatedFilters = filters.data.map((f) => ({
    ...f,
    baremoList: f.baremoList.map((b) => populateInstance(baremos.data, b))
  }))

  const handleClose = () => {
    if (currentElement) setCurrentElement(undefined)
    setShow(false)
  }

  const handleShowPost = () => {
    setModalTitle('Nuevo filtro')
    setShow(true)
  }

  const handleShowPut = (element) => {
    setModalTitle('Editar filtro')
    setCurrentElement(element)
    setShow(true)
  }

  // TODO: delete filter
  // const handleDelete = () => {}

  return (
    <>
      <Header
        buttonText="Nuevo filtro"
        title="Filtros de baremos"
        buttonAction={handleShowPost}
      />
      <BaremosFilterTable data={formatedFilters} cb={handleShowPut} />
      {show && (
        <ModalSistel
          handleClose={handleClose}
          title={modalTitle}
        >
          <BaremoFilterForm
            data={currentElement}
            baremos={baremos.data}
            handleClose={handleClose}
          />
        </ModalSistel>
      )}
    </>
  )
}
