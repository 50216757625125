export default function NavbarDropdown({ menu, content }) {
  return (
    <div className="dropdown-sistel">
      {menu}
      <div className="dropdown-content">
        <div className="content">{content}</div>
      </div>
    </div>
  )
}
