import { Link } from 'react-router-dom'
import CeladorProgressBar from 'components/Orders/OrderTable/CeladorProgressBar'
import FusionadorProgressBar from 'components/Orders/OrderTable/FusionadorProgressBar'
import { CELADOR, FUSIONADOR } from 'utils/constants'
import { IconosSistel } from 'npm-styles-sistel'
import { DashboardOrderStyled } from './dashboardStyles'

export default function DashboardOrder({ order }) {
  return (
    <Link to={`/ordenes/${order.ref}`}>
      <DashboardOrderStyled>
        <div className="content-order">
          <p className="title">{order.cluster}</p>
          <p className="brigade">
            <IconosSistel icon="brigadas" width="12" />
            {order.brigade?.name}
          </p>
          <p className="project">
            <IconosSistel icon="proyectos" width="12" color="#9D98AE" />
            {order.project?.title}
          </p>
          <div className="progress">
            {order.type === CELADOR && <CeladorProgressBar {...{ order }} />}
            {order.type === FUSIONADOR && (
              <FusionadorProgressBar {...{ order }} />
            )}
          </div>
        </div>
      </DashboardOrderStyled>
    </Link>
  )
}
