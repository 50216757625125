import { useDispatch } from 'react-redux'
import {
  ButtonSistel,
  InputCheckboxSistel,
  InputSelectSistel,
  InputSistel
} from 'npm-styles-sistel'
import { useFormik } from 'formik'

import { postApi, putApi } from 'redux/actions/fetchActions'

import { makeSelectOptions } from 'utils/maps'
import { FINISHED, FUSIONADOR, GAP, GAP_REASONS } from 'utils/constants'

export default function ValidateOrderForm({ handleClose, order, refetch }) {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: initialValues(),
    validateOnChange: false,
    onSubmit: (values) => {
      const updateInstanceBody = {
        ...order,
        status: FINISHED,
        uuiiBuilt: values.uuiiBuilt
      }

      const closeAndReload = () => {
        handleClose()
        refetch()
      }

      if (values.hasGaps) {
        const newOrderBody = {
          ...order,
          boxList:
            order.type === FUSIONADOR
              ? values.gaps.map((g) => ({ name: g.box }))
              : [],
          brigade: null,
          createdAt: null,
          employeeDeliveries: [],
          gaps: values.gaps,
          originOrder: order._id,
          status: GAP,
          updatedAt: null,
          uuiiBuilt: 0,
          uuiiCertified: 0,
          _id: null
        }

        const createGapOrder = () =>
          dispatch(postApi(newOrderBody, '/order', [null, closeAndReload]))

        dispatch(
          putApi(updateInstanceBody, `/order/${order._id}`, [
            null,
            createGapOrder
          ])
        )
        return
      }

      dispatch(
        putApi(updateInstanceBody, `/order/${order._id}`, [
          null,
          closeAndReload
        ])
      )
    }
  })

  const gapSubSchema = {
    box: '',
    reason: '',
    comment: ''
  }

  const { handleSubmit, handleChange, setFieldValue, values } = formik
  const { hasGaps, gaps, uuiiBuilt } = values

  const handleCheckbox = ({ target }) => {
    if (!target.checked) {
      setFieldValue('gaps', [gapSubSchema])
      setFieldValue('hasGaps', target.checked)
      return
    }
    setFieldValue('hasGaps', target.checked)
  }

  const handleSelect = (field) => (e) => setFieldValue(field, e.value)

  const addGap = () => setFieldValue('gaps', [...gaps, gapSubSchema])
  const removeGap = (gapIndex) =>
    setFieldValue(
      'gaps',
      gaps.filter((_, i) => i !== gapIndex)
    )

  return (
    <form className="mt-5" onSubmit={handleSubmit} id="form">
      <div className="row">
        <InputSistel
          col="col-md-10"
          label="UUII construidas"
          name="uuiiBuilt"
          onChange={handleChange}
          type="text"
          value={uuiiBuilt}
        />
        <InputCheckboxSistel
          col="col-md-10"
          label="Tiene huecos?"
          name="hasGaps"
          onChange={handleCheckbox}
          value={hasGaps}
        />
        <GapSubForm
          {...{
            addGap,
            gaps,
            hasGaps,
            handleChange,
            handleSelect,
            removeGap
          }}
        />
      </div>
    </form>
  )
}

function GapSubForm({
  addGap,
  removeGap,
  hasGaps,
  gaps,
  handleChange,
  handleSelect
}) {
  if (!hasGaps) return null

  const reasonOptions = makeSelectOptions(GAP_REASONS)

  const displayGapForms = gaps.map((gap, i) => (
    <div className="row my-2" key={i}>
      <div className="col-md-4">
        <InputSistel
          label="Caja"
          type="text"
          col="col-md-10"
          value={gap.box}
          onChange={handleChange}
          name={`gaps[${i}].box`}
        />
      </div>
      <div className="col-md-7">
        <InputSelectSistel
          label="Motivo"
          type="text"
          col="col-md-12"
          placeholder="Selecciona un motivo..."
          name={`gaps[${i}].reason`}
          value={gap.reason}
          onChange={handleSelect(`gaps[${i}].reason`)}
          options={reasonOptions}
        />
      </div>
      <div className="col-md-12">
        <InputSistel
          label="Comentario"
          type="text"
          col="col-md-12"
          value={gap.comment}
          onChange={handleChange}
          name={`gaps[${i}].comment`}
        />
      </div>
      <div className="ml-3">
        <ButtonSistel danger type="button" onClick={() => removeGap(i)}>
          Quitar hueco
        </ButtonSistel>
      </div>
    </div>
  ))

  return (
    <div className="col-md-10">
      {displayGapForms}
      <ButtonSistel success type="button" onClick={addGap}>
        Añadir hueco
      </ButtonSistel>
    </div>
  )
}

function initialValues() {
  const gapSubSchema = {
    box: '',
    reason: '',
    comment: ''
  }

  return {
    uuiiBuilt: '',
    gaps: [gapSubSchema],
    hasGaps: false
  }
}
