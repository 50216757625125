import MainContainer from 'components/common/MainContainer'
import { Link } from 'react-router-dom'

export default function Informes() {
  return (
    <MainContainer>
      <h1>Informes</h1>
      <div className="d-flex flex-column">
        <Link to="/informes/ingenieria">Informe de Ingenieria</Link>
        <Link to="/informes/baremos">Informe por baremos</Link>
      </div>
    </MainContainer>
  )
}
