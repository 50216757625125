import { useSelector } from 'react-redux'

import useModal from 'hooks/useModal'
import useFilter from 'hooks/useFilter'
import useCompany from 'hooks/useCompany'
import useDynamicFetch from 'hooks/useDynamicFetch'
import useCheckboxFilter from 'hooks/useCheckbox'
import { getPointsOfMonth } from 'utils/filters'
import { populateInstance } from 'utils/maps'
import { getLastClose } from 'utils/dates'
import { getUsers } from 'redux/actions/userActions'
import { getBrigades } from 'redux/actions/brigadeActions'
import { getDepartments } from 'redux/actions/departmentActions'

import { EmployeeForm } from 'components/forms'
import MainContainer from 'components/common/MainContainer'
import EmployeesTable from 'components/Tables/EmployeeTable'

import {
  HeaderFiltersSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'

export default function Empleados() {
  const me = useSelector((s) => s.me)
  const departments = useDynamicFetch('department', getDepartments)
  const brigades = useDynamicFetch('brigade', getBrigades)
  const employees = useDynamicFetch('user', getUsers)
  const company = useCompany(me)
  const { show, setShow, actions } = useModal()
  const { onClose } = actions

  const lastClose = getLastClose(company?.data)

  const populatedData = employees.data
    .filter(({ active }) => active)
    .map((employee) => ({
      ...employee,
      department: populateInstance(departments.data, employee.department),
      brigade: populateInstance(brigades.data, employee.brigade),
      totalPoints: employee.points
        .filter(getPointsOfMonth(lastClose))
        .reduce((prev, curr) => prev + (curr.qty || 0), 0)
    }))

  const [handleChange, foundEmployees] = useFilter(populatedData, [
    'name',
    'lastname',
    'phoneNumber'
  ])

  const [displayDepartmentsFilter, filteredData] = useCheckboxFilter(
    [{ key: 'department', subKey: '_id' }],
    departments.data,
    foundEmployees
  )

  if (
    !employees.loaded ||
    !departments.loaded ||
    !brigades.loaded ||
    !company.loaded
  )
    return <LoaderSistel />

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="empleados"
        title="Empleados"
        handleSearch={handleChange}
        buttonAction={() => setShow(true)}
        buttonText="Crear empleado"
        filters={
          <>
            <h4>Departamentos</h4>
            {displayDepartmentsFilter}
          </>
        }
      />

      <EmployeesTable data={filteredData} />
      {show && (
        <ModalSistel
          show={show}
          handleClose={onClose}
          title="Nuevo empleado"
          iconTitle="empleadoNuevo"
          buttons={false}
        >
          <EmployeeForm handleClose={onClose} />
        </ModalSistel>
      )}
    </MainContainer>
  )
}
