import { useState } from 'react'
import Icon from '@mdi/react'
import { mdiArrowDownBoldBox, mdiArrowUpBoldBox } from '@mdi/js'

import { compare, reverseCompare } from 'utils/tableUtils'

export default function Table({ data, tableData, headers, cb, ...rest }) {
  const initialState = {
    currentPage: 1,
    itemsPerPage: 12
  }

  const [filter, setFilter] = useState({ field: '', up: true })
  const [pagination, setPagination] = useState(initialState)
  const { currentPage, itemsPerPage } = pagination

  const indexOfLastRow = currentPage * itemsPerPage
  const indexOfFirstRow = indexOfLastRow - itemsPerPage
  const currentRows = tableData.slice(indexOfFirstRow, indexOfLastRow)

  const columnKeys = tableData.length ? Object.keys(tableData[0]) : []

  const renderHeaders = headers.map(
    ({ header, sort, sortField, reverse, className }, i) => (
      <th
        className={className}
        scope="col"
        key={header}
        style={{ cursor: `${sort ? 'pointer' : 'default'}` }}
        onClick={() =>
          sort && cb(sortData(sortField || columnKeys[i], reverse))
        }
      >
        {header}
        {(filter.field === sortField || filter.field === columnKeys[i]) &&
          (!filter.up ? (
            <Icon path={mdiArrowDownBoldBox} size={1} />
          ) : (
            <Icon path={mdiArrowUpBoldBox} size={1} />
          ))}
      </th>
    )
  )

  const handleClick = (e) =>
    setPagination({ ...pagination, currentPage: Number(e.target.id) })

  const pageNumbers = new Array(Math.ceil(tableData.length / itemsPerPage))
    .fill(null)
    .map((_, i) => i + 1)

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <button
        key={number}
        id={number}
        onClick={handleClick}
        className={currentPage === number ? 'pagination active' : 'pagination'}
      >
        {number}
      </button>
    )
  })

  function sortData(field, reverse) {
    const copyDataArr = JSON.parse(JSON.stringify(data))
    const compareFnc = reverse ? reverseCompare : compare

    if (field === filter.field && filter.up) {
      setFilter((prev) => ({ ...prev, up: !prev.up }))
      return copyDataArr.sort((v1, v2) => compareFnc(v1[field], v2[field]))
    }

    if (field === filter.field && !filter.up) {
      setFilter((prev) => ({ ...prev, up: !prev.up }))
      return copyDataArr.sort((v1, v2) => compareFnc(v2[field], v1[field]))
    }

    setFilter(() => ({ field, up: false }))
    return copyDataArr.sort((v1, v2) => compareFnc(v1[field], v2[field]))
  }

  function renderData() {
    const tableAccessors = columnKeys.slice(0, -1)

    return currentRows.map((row) => (
      <tr key={row.key} onClick={row.action}>
        {tableAccessors.map((selector, idx) => (
          <td
            className={
              `${row[selector]?.className} align-middle` || 'align-middle'
            }
            key={selector + idx}
          >
            {row[selector]?.value ?? row[selector]}
          </td>
        ))}
      </tr>
    ))
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead className="table-head">
          <tr>{renderHeaders}</tr>
        </thead>
        <tbody>{renderData()}</tbody>
      </table>
      <div className="pagination-group">{renderPageNumbers}</div>
      <p className="table-results">{tableData.length} resultados</p>
    </div>
  )
}
