import axios from 'services/axios'
import { useState, useEffect } from 'react'

export default function useLatestDeliveries() {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState([])

  const fetchData = async () => {
    try {
      const response = await axios.get('/employeedelivery/latests')
      const responseOk = response && response.status < 400
      if (!responseOk) throw new Error(response.data)

      setData(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const refetch = async () => {
    setFetching(true)
    fetchData().then(() => setFetching(false))
  }

  useEffect(() => {
    fetchData().then(() => setFetching(false))
  }, [])

  return [data, fetching, refetch]
}
