export const BASE_PATH =
  String(process.env.NODE_ENV) !== 'development'
    ? process.env.REACT_APP_API_URL
    : 'http://localhost:5000/api'

export const VEHICLE_PATH =
  process.env.REACT_APP_VEHICLE_PATH || 'http://localhost:5002'

export const MAP_KEY = String(process.env.REACT_APP_MAP_KEY)

export const TOKEN = '@sistelEmpleadosAdmin'

export const USER = '@sistelEmpleadosUD'

// EMPLOYEE DELIVERY STATUS
export const PENDING = 'PENDING' // DEFAULT IN DB
export const CANCELLED = 'CANCELLED'
export const VALIDATED = 'VALIDATED'

export const DELIVERY_STATUS = { PENDING, CANCELLED, VALIDATED }

// ORDER STATUS
export const ASSIGNED = 'ASSIGNED'
export const CERTIFIED = 'CERTIFIED'
export const FINISHED = 'FINISHED'
export const GAP = 'GAP'
export const INCIDENCE = 'INCIDENCE'
export const IN_PROGRESS = 'IN_PROGRESS'
export const PENDING_VALIDATE = 'PENDING_VALIDATE'
export const UNASSIGNED = 'UNASSIGNED'
export const ORDER_STATUS = [
  ASSIGNED,
  CERTIFIED,
  FINISHED,
  GAP,
  INCIDENCE,
  IN_PROGRESS,
  PENDING_VALIDATE,
  UNASSIGNED
]

// DEPARTMENT TYPES
export const Celadores = 'Celadores'
export const Fusionadores = 'Fusionadores'
export const Alteros = 'Alteros'
export const FIXED_DEPARMTENTS = [Alteros, Celadores, Fusionadores]

// (BAREMO || ORDER || BRIGADE) TYPES
export const CELADOR = 'CELADOR'
export const FUSIONADOR = 'FUSIONADOR'
export const BAREMO_TYPES = [CELADOR, FUSIONADOR]

// WORK TYPES
export const DESPLIEGUE = 'Despliegue'
export const AVERIA_PEX = 'Avería PEX'
export const I_M = 'I+M'
export const AVERIA_I_M = 'Avería I+M'
export const WORK_TYPES = [DESPLIEGUE, AVERIA_PEX, I_M, AVERIA_I_M]

// ROLES
export const ADMIN = 'Admin'
export const SUPERVISOR = 'Supervisor'
export const WORKER = 'Worker'
export const RRHH = 'RRHH'
export const DESIGNER = 'Designer'
export const ROLES = [ADMIN, SUPERVISOR, WORKER, RRHH, DESIGNER]

// ORDERS PAGE
export const ORDER_COLUMN_LIMIT = 20

// GAP REASONS
export const GAP_REASONS = ['Obstrucción', 'Permiso', 'Otros']
