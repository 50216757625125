import Row from 'components/common/Row'
import OrderTableColumn from '../OrderTable/OrderTableColumn'

import {
  ASSIGNED,
  IN_PROGRESS,
  PENDING_VALIDATE,
  UNASSIGNED
} from 'utils/constants'

export default function ActiveOrdersTab({ setShowConfirm, filters }) {
  return (
    <Row>
      <OrderTableColumn
        setShowConfirm={setShowConfirm}
        size="s"
        text="Por asignar"
        orderStatus={UNASSIGNED}
        {...{ filters }}
      />
      <OrderTableColumn
        size="m"
        text="Asignadas"
        asignadas
        orderStatus={ASSIGNED}
        {...{ filters }}
      />
      <OrderTableColumn
        text="En curso"
        enProceso
        size="l"
        orderStatus={IN_PROGRESS}
        {...{ filters }}
      />
      <OrderTableColumn
        text="Validación pendiente"
        validacionPendiente
        size="l"
        orderStatus={PENDING_VALIDATE}
        {...{ filters }}
      />
    </Row>
  )
}
