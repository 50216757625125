import { useMemo } from 'react'
import SimpleTable from './SimpleTable'

export default function ZoneTable({ zones }) {
  const tableData = useMemo(
    () =>
      zones.map((z) => {
        return {
          title: z.title,
          description: z.description,
          key: z.title
        }
      }),
    []
  )

  const headers = useMemo(
    () => [
      { header: 'Zona', sort: true },
      { header: 'Descripción', sort: false }
    ],
    []
  )

  return <SimpleTable {...{ data: zones, tableData, headers }} />
}
