import { AvatarImageSistel } from 'npm-styles-sistel'
import styled from 'styled-components'

const AvatarContainerStyled = styled.div`
  display: flex;
  align-items: center;
  text-align: right;

  div {
    margin-left: 12px;
  }

  .name {
    ${({ theme }) => theme.fonts.text_s_bold};
    color: ${({ theme }) => theme.colors.text};
  }

  .time {
    ${({ theme }) => theme.fonts.text_xs};
    color: ${({ theme }) => theme.colors.text_light};
    font-weight: 400;
  }
`

export default function AvatarContainer({ user, deliveryTime }) {
  if (!user) return null
  const fullName = `${user.name} ${user.lastname}`

  return (
    <AvatarContainerStyled>
      <div>
        <p className="name">{fullName}</p>
        <p className="time">{deliveryTime}</p>
      </div>
      <AvatarImageSistel image={user.avatar} width="38px" />
    </AvatarContainerStyled>
  )
}
