import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import {
  CERTIFIED,
  FINISHED,
  INCIDENCE,
  IN_PROGRESS,
  PENDING_VALIDATE
} from 'utils/constants'

export default function OrdersChart({ orders }) {
  const chartData = [
    { name: 'En proceso', value: orders[IN_PROGRESS] },
    { name: 'Finalizada', value: orders[FINISHED] },
    { name: 'Validación pendiente', value: orders[PENDING_VALIDATE] },
    { name: 'Certificadas', value: orders[CERTIFIED] },
    { name: 'Incidencia', value: orders[INCIDENCE] }
  ]

  const COLORS = ['#20C0E4', '#9AE154', '#FD9A3E', '#40C46C', '#FC4264']

  return (
    <div className="card mt-4 centered-card">
      <PieChart width={220} height={220}>
        <Pie
          data={chartData}
          cx={100}
          cy={100}
          innerRadius={50}
          outerRadius={105}
          fill="#8884d8"
          stroke="transparent"
          paddingAngle={0}
          dataKey="value"
        >
          {Object.keys(orders).map((_, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
      <div className="data-orders mt-4">
        <FastViewOrdersInfo text="finalizadas" number={orders[FINISHED]} />
        <FastViewOrdersInfo
          text="pendientes"
          number={orders[PENDING_VALIDATE]}
        />
      </div>
    </div>
  )
}

function FastViewOrdersInfo({ text, number = 0 }) {
  return (
    <div>
      <p>
        Órdenes
        <br />
        {text}
      </p>
      <h6>{number}</h6>
    </div>
  )
}
