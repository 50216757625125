import { useState } from 'react'
import { getVehicles } from 'redux/actions/vehicleActions'
import useDynamicFetch from 'hooks/useDynamicFetch'
import VehiclesTable from 'components/Tables/VehiclesTable'
import useFilter from 'hooks/useFilter'

import MultipleMarkersMap from 'components/Maps/MultipleMarkersMap'

import {
  HeaderFiltersSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'
import MainContainer from 'components/common/MainContainer'
import { VehicleForm } from 'components/forms'

export default function Vehiculos() {
  const { data, loaded } = useDynamicFetch('vehicle', getVehicles)
  const [show, setShow] = useState(null)

  const modal = (
    <ModalSistel
      title="Nuevo vehículo"
      iconTitle="vehiculoNuevo"
      handleClose={() => setShow(null)}
      buttons={false}
    >
      <VehicleForm handleClose={() => setShow(null)} />
    </ModalSistel>
  )

  const filteredByActiveVehicles = data.filter(({ active }) => active)

  const [handleChange, foundVehicles] = useFilter(filteredByActiveVehicles, [
    'plate',
    'model'
  ])

  if (!loaded) return <LoaderSistel />

  const vehiclePlates = data.map((v) => v.plate)

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="vehiculos"
        title="Vehículos"
        buttonText="Nuevo vehículo"
        buttonAction={() => setShow(modal)}
        handleSearch={handleChange}
      />
      <MultipleMarkersMap plates={vehiclePlates} />
      <VehiclesTable data={foundVehicles} />
      {show}
    </MainContainer>
  )
}
