import { useDispatch } from 'react-redux'

import useDynamicFetch from 'hooks/useDynamicFetch'
import { DepartmentForm } from 'components/forms'
import Header from 'components/common/Header'
import DepartmentsTable from 'components/Tables/DepartmentsTable'
import {
  getDepartments,
  patchDepartment
} from 'redux/actions/departmentActions'
import { patchApi } from 'redux/actions/fetchActions'
import { ConfirmationModalSistel, ModalSistel } from 'npm-styles-sistel'

export default function Departments({ setShow }) {
  const dispatch = useDispatch()
  const departments = useDynamicFetch('department', getDepartments)

  if (!departments.loaded) return <h1>LOADING...</h1>

  const filteredDepartments = departments.data.filter((d) => d.active)

  const handleClose = () => setShow(null)

  const handleDeleteBtn = (element) => {
    const modalFunction = () =>
      dispatch(
        patchApi(element._id, `/department/${element._id}`, [
          patchDepartment,
          handleClose
        ])
      )

    setShow(
      <ConfirmationModalSistel
        modalContent="¿Seguro que quieres eliminar este departamento?"
        title="Eliminar departamento"
        onClose={handleClose}
        modalFunction={modalFunction}
      />
    )
  }

  const handleShowPost = () => {
    setShow(
      <ModalSistel
        handleClose={handleClose}
        title="Nuevo departamento"
        iconTitle="configuracion"
        size="xs"
      >
        <DepartmentForm handleClose={handleClose} />
      </ModalSistel>
    )
  }

  const handleShowPut = (element) => {
    setShow(
      <ModalSistel
        handleClose={handleClose}
        title="Editar departamento"
        secBtnText="Eliminar departamento"
        secBtnFunction={() => handleDeleteBtn(element)}
        size="xs"
      >
        <DepartmentForm department={element} handleClose={handleClose} />
      </ModalSistel>
    )
  }

  return (
    <>
      <Header
        buttonText="Nuevo departamento"
        title="Departamentos"
        buttonAction={handleShowPost}
      />
      <DepartmentsTable data={filteredDepartments} cb={handleShowPut} />
    </>
  )
}
