import { AvatarImageSistel, IconosSistel } from 'npm-styles-sistel'
import { EmployeeBrigadeStyled } from './brigadeStyles'

export default function EmployeeCardList({ users, manager, driver }) {
  function determineManagerIcon(user) {
    if (!manager) return null

    return manager._id === user._id ? (
      <div className="mini-icon">
        <IconosSistel icon="leader" width="12" />
      </div>
    ) : null
  }

  function determineDriverIcon(user) {
    if (!driver) return null

    return driver._id === user._id ? (
      <div className="mini-icon">
        <IconosSistel icon="vehiculos"  />
      </div>
    ) : null
  }

  const cards = users.filter(Boolean).map((user) => (
    <div key={user._id}>
      <EmployeeBrigadeStyled>
        <div className="flex-center">
        <div className="avatar-brigade">
          <AvatarImageSistel image={user.avatar} width="34px"  />
        </div>
        <p className="name">
          {user.name} {user.lastname}
        </p>
        </div>
        
        <div className="icons">
          {determineManagerIcon(user)}
          {determineDriverIcon(user)}
        </div>
      </EmployeeBrigadeStyled>
    </div>
  ))

  return <div>{cards}</div>
}
