import { Link } from 'react-router-dom'
// import { Dropdown } from 'react-bootstrap'

// import SelectBrigade from 'components/forms/FastActionForms/SelectBrigade'
import CeladorProgressBar from './CeladorProgressBar'
import FusionadorProgressBar from './FusionadorProgressBar'

import { CELADOR, FUSIONADOR, PENDING } from 'utils/constants'

import { IconosSistel } from 'npm-styles-sistel'
import { OrderCardStyled } from '../orderStyle'

export default function OrderCard({ order, size, setShowConfirm, getRef }) {
  const hasPendingValidationDelivery = order.employeeDeliveries?.filter(
    (deliv) => deliv?.status && deliv.status === PENDING
  ).length

  const isFusionador = order.type === FUSIONADOR
  const isCelador = order.type === CELADOR

  const brigade = (
    <p className="brigade">
      <IconosSistel icon="brigadas" width="12" color="#33205C" />
      {order.brigade?.name}
    </p>
  )

  const project = (
    <p className="project">
      <IconosSistel icon="proyectos" width="12" color="#8D88A4" />
      {order.project?.title}
    </p>
  )

  const orderInfo = (
    <div className="footer-info" ref={getRef ? (r) => getRef(r) : null}>
      {isFusionador ? (
        <span className="f">F</span>
      ) : isCelador ? (
        <span className="c">C</span>
      ) : (
        <span className="a">A</span>
      )}
      <p className="order-ref">{order.ref}</p>
    </div>
  )

  // TODO: mostrar dropdown (al cambiar las columnas no quedaba visible)
  if (!order.brigade) {
    return (
      <OrderCardStyled size={size} as="div" priority={order.priority}>
        <div className="flex-between">
          <Link to={`/ordenes/${order.ref}`}>
            <h4>{order.cluster}</h4>
            {project}
            {orderInfo}
          </Link>

          {/* <Dropdown>
            <Dropdown.Toggle>
              <IconosSistel icon="brigadas" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-brigade">
              <SelectBrigade {...{ order, setShowConfirm }} />
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </OrderCardStyled>
    )
  }

  return (
    <OrderCardStyled
      to={`/ordenes/${order.ref}`}
      priority={order.priority}
      size={size}
    >
      <div className="title-notifications">
        <div className="info">
          <h4>{order.cluster}</h4>
          {brigade}
          {project}
        </div>
        {Boolean(hasPendingValidationDelivery) && (
          <div className="notifications">
            <IconosSistel icon="notificaciones" width="18" />
            <span>{hasPendingValidationDelivery}</span>
          </div>
        )}
      </div>
      {order.type === CELADOR && <CeladorProgressBar {...{ order }} />}
      {order.type === FUSIONADOR && <FusionadorProgressBar {...{ order }} />}
      {orderInfo}
    </OrderCardStyled>
  )
}
