import { useState } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

import Row from 'components/common/Row'

import { postApi, putApi } from 'redux/actions/fetchActions'
import { postBaremo, putBaremo } from 'redux/actions/baremoActions'
import { CELADOR, FUSIONADOR } from 'utils/constants'
import {
  InputCheckboxSistel,
  InputRadioSistel,
  InputSistel
} from 'npm-styles-sistel'

export default function BaremoForm({ baremo, handleClose }) {
  const dispatch = useDispatch()
  const allClients = useSelector((s) => s.clients.data)
  const [currentBaremo] = useState(initialValues(baremo, allClients))

  const formik = useFormik({
    initialValues: currentBaremo,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      if (baremo)
        return dispatch(
          putApi(values, `/baremo/${values._id}`, [putBaremo, handleClose])
        )
      dispatch(postApi(values, '/baremo', [postBaremo, handleClose]))
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik
  const { description, pvp, unit, clients, types } = values

  const handleCheckbox = ({ target }) => {
    if (!target.checked) {
      setFieldValue(
        'types',
        types.filter((type) => type !== target.value)
      )
      return
    }
    setFieldValue('types', [...types, target.value])
  }

  return (
    <form className="mt-5" onSubmit={handleSubmit} id="form">
      <Row>
        <InputSistel
          type="text"
          label="Descripción"
          col="col-md-9"
          name="description"
          value={description}
          errors={errors.description}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          label="Precio de coste"
          col="col-md-3"
          name="pvp"
          value={pvp}
          errors={errors.pvp}
          onChange={handleChange}
        />
        <div className="col-md-6">
          <p className="subtitle-form">Tipo de baremo</p>
          <div className="row mt-3">
            <InputCheckboxSistel
              col="col-auto"
              name="type"
              onChange={handleCheckbox}
              value={CELADOR}
              defaultChecked={types.includes(CELADOR)}
              label="Celadores"
            />
            <InputCheckboxSistel
              col="col-auto"
              onChange={handleCheckbox}
              value={FUSIONADOR}
              defaultChecked={types.includes(FUSIONADOR)}
              label="Fusionadores"
            />
          </div>
        </div>
        <div className="col-md-6">
          <p className="subtitle-form">Unidad de medida</p>
          <div className="row mt-3">
            <InputRadioSistel
              options={['Unidad', 'Metro']}
              name="unit"
              col="col-auto"
              value={unit}
              onChange={handleChange}
            />
          </div>
        </div>
      </Row>
      <ClientsField
        allClients={allClients}
        options={clients}
        handleChange={handleChange}
      />
    </form>
  )
}

function ClientsField({ allClients, options, handleChange }) {
  const displayClientFields = options?.map((c, idx) => (
    <div key={c.client} className="col-md-6">
      <label>{allClients.find((cl) => cl._id === c.client).name}</label>
      <div className="row">
        <InputSistel
          col="col-md-6"
          type="number"
          label="Puntos/unidad"
          name={`clients[${idx}].pointValue`}
          onChange={handleChange}
          value={c.pointValue}
        />
        <InputSistel
          col="col-md-6"
          type="number"
          label="Pago"
          name={`clients[${idx}].amountPaid`}
          onChange={handleChange}
          value={c.amountPaid}
        />
      </div>
    </div>
  ))

  return (
    <div className="d-flex flex-column">
      <p className="subtitle-form">Valor del punto por cliente</p>
      <Row className="mt-4">{displayClientFields}</Row>
    </div>
  )
}

function initialValues(baremo, clients) {
  const clientFields = clients.map((client, idx) => {
    return {
      client: client._id,
      pointValue: baremo?.clients[idx]?.pointValue || '',
      amountPaid: baremo?.clients[idx]?.amountPaid || ''
    }
  })

  return {
    _id: baremo?._id,
    clients: [...clientFields],
    description: baremo?.description || '',
    pvp: baremo?.pvp || '',
    types: baremo?.types || [],
    unit: baremo?.unit || ''
  }
}

function validationSchema() {
  const clientPriceSubSchema = {
    client: '',
    pointValue: undefined
  }
  return {
    description: Yup.string().required('Descripción obligatoria'),
    pvp: Yup.number(),
    unit: Yup.string(),
    clients: Yup.array().of(Yup.object().shape(clientPriceSubSchema))
  }
}
