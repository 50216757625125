import styled from 'styled-components'
import { Link } from 'react-router-dom'

import useDocumentsProjects from 'hooks/useDocumentsProjects'
import useFilter from 'hooks/useFilter'
import MainContainer from 'components/common/MainContainer'

import {
  HeaderFiltersSistel,
  IconosSistel,
  LoaderSistel
} from 'npm-styles-sistel'

const ProjectStyled = styled(Link)`
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  transition: all 0.6s ease;
  border-radius: 20px;

  &:hover {
    background: rgb(236 231 255 / 40%);

    p {
      color: ${({ theme }) => theme.colors.purple};
    }
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    ${({ theme }) => theme.fonts.text};
    margin-top: 24px;
    text-align: center;
  }
`

export default function GestorDocumental() {
  const [projects, fetching] = useDocumentsProjects()

  const [handleChange, foundProjects] = useFilter(projects, ['title'])

  if (fetching) return <LoaderSistel />

  const displayProjects = foundProjects.map((p) => (
    <div className="col-md-3" key={p._id}>
      <ProjectStyled to={`/documentos/${p.ref}`}>
        <IconosSistel icon="carpetas" width="86" />
        <p>{p.title}</p>
      </ProjectStyled>
    </div>
  ))

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="documentos"
        title="Gestor documental"
        handleSearch={handleChange}
      />
      <div className="row">{displayProjects}</div>
    </MainContainer>
  )
}
