import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_VEHICLES,
  PATCH_VEHICLE,
  POST_VEHICLE,
  PUT_VEHICLE
} from '../actions/vehicleActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_VEHICLES:
      return { data: [...action.payload], loaded: true }
    case POST_VEHICLE:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_VEHICLE:
      return {
        ...state,
        data: state.data.map((vehicle) =>
          vehicle._id === action.payload._id
            ? { ...vehicle, ...action.payload }
            : vehicle
        )
      }
    case PATCH_VEHICLE:
      return {
        ...state,
        data: state.data.map((vehicle) =>
          vehicle._id === action.payload
            ? { ...vehicle, active: !vehicle.active }
            : vehicle
        )
      }
    default:
      return state
  }
}
