import axios from 'axios'

import { BASE_PATH, TOKEN } from 'utils/constants'

axios.defaults.baseURL = BASE_PATH
axios.defaults.headers.Authorization = JSON.parse(localStorage.getItem(TOKEN))

export const authorize = (token) => {
  if (token) {
    axios.defaults.headers.Authorization = token
  }
}

export default axios
