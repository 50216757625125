import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import Table from './Table'
import EmployeeFunctionButton from 'components/Employees/EmployeeFunctionButton'
import { AvatarImageSistel, BadgeSistel } from 'npm-styles-sistel'
import useStateWithDep from 'hooks/useStateWithDep'

export default function EmployeesTable({ data }) {
  const [employees, setEmployees] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      employees
        .sort((e1, e2) => e2.totalPoints - e1.totalPoints)
        .map((employee) => {
          const monthlyGoal =
            employee.monthlyGoal || employee.department?.monthlyGoal || 0

          const className = 'align-middle'
          const department = employee.department?.name

          const employeeDepartment =
            department === 'Celadores' ? (
              <BadgeSistel purple>Celadores</BadgeSistel>
            ) : department === 'Fusionadores' ? (
              <BadgeSistel blue>Fusionadores</BadgeSistel>
            ) : department === 'Alteros' ? (
              <BadgeSistel>Alteros</BadgeSistel>
            ) : (
              <BadgeSistel grey>{department}</BadgeSistel>
            )

          return {
            name: (
              <div className="flex-center">
                <AvatarImageSistel image={employee.avatar} width="42px" />
                <Link
                  to={`/empleados/${employee._id}`}
                  className="table-link mb-1 ml-3"
                >{`${employee.name} ${employee.lastname}`}</Link>
              </div>
            ),
            points: (
              <progress
                title={`${Math.round(employee.totalPoints)}/${monthlyGoal}`}
                value={employee.totalPoints}
                max={monthlyGoal}
              />
            ),
            phoneNumber: {
              value: employee.phoneNumber || '-',
              className
            },
            function: <EmployeeFunctionButton employee={employee} />,

            department: {
              value: employeeDepartment,
              className: 'text-right'
            },
            key: employee.dni
          }
        }),
    [employees]
  )

  const headers = useMemo(
    () => [
      { header: 'Empleado' },
      { header: 'Puntos' },
      { header: 'Teléfono' },
      { header: 'Funciones' },
      { header: 'Rol', className: 'text-right' }
    ],
    []
  )

  return (
    <Table {...{ data: employees, tableData, headers, cb: setEmployees }} />
  )
}
