import jwtDecode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { logout } from 'redux/actions/meActions'
import { TOKEN } from 'utils/constants'

export default function ProtectedRoute({ component: Component, ...rest }) {
  const me = useSelector((s) => s.me)
  const dispatch = useDispatch()

  const hasToken = localStorage.getItem(TOKEN)

  if (!hasToken) {
    dispatch(logout())
    return <Redirect to="/login" />
  }

  const isTokenExpired = jwtDecode(hasToken).exp < new Date().getTime() / 1000

  return (
    <Route
      {...rest}
      render={(props) => {
        if (me && !isTokenExpired) {
          return <Component {...rest} {...props} />
        } else {
          dispatch(logout())
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      }}
    />
  )
}
