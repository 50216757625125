import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormikProvider, useFormik } from 'formik'

import { putApi } from 'redux/actions/fetchActions'
import { CANCELLED, VALIDATED } from 'utils/constants'

import BoxAttachments from 'components/Orders/EmployeeDeliveries/BoxAttachments'
import {
  Attachments,
  BaremoEntries
} from 'components/Orders/EmployeeDeliveries'

import {
  ButtonSistel,
  DataGroupSistel,
  InputSelectSistel,
  InputSistel
} from 'npm-styles-sistel'

export default function EmployeeDeliveryForm({ data, refetch, users }) {
  const dispatch = useDispatch()
  const [currentDelivery] = useState(initialValues(data))

  const formik = useFormik({
    initialValues: currentDelivery,
    validateOnChange: false,
    onSubmit: (values) => {
      const instanceBody = {
        ...values,
        baremoEntries: values.baremoEntries.map((entry) => ({
          baremo: entry.baremo._id,
          qty: entry.qty,
          pointValue: entry.pointValue
        })),
        users: values.users.map(({ value }) => value)
      }

      dispatch(
        putApi(instanceBody, `/employeedelivery/${values._id}`, [null, refetch])
      )
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik

  const formUtils = {
    userOptions: users
      .filter((u) => u.active)
      .map((u) => ({
        label: `${u.name} ${u.lastname}`,
        value: u._id
      })),
    // change handlers
    handleSelect: (field) => (e) => setFieldValue(field, e),
    handleCheckbox:
      (name) =>
      ({ target }) => {
        if (!target.checked) {
          setFieldValue(
            name,
            values[name].filter((val) => val !== target.value)
          )
          return
        }
        setFieldValue(name, [...values[name], target.value])
      },
    // submit handlers
    cancelDelivery: () => {
      setFieldValue('status', CANCELLED)
      handleSubmit()
    },
    validateDelivery: () => {
      setFieldValue('status', VALIDATED)
      handleSubmit()
    }
  }

  const isBoxDelivery = Boolean(data.boxId)
  const determineAttachmentComponent = isBoxDelivery ? (
    <BoxAttachments data={data.boxPhotos} />
  ) : (
    <Attachments data={data.photos} />
  )

  return (
    <FormikProvider value={formik}>
      <form className="mt-3 row" onSubmit={handleSubmit}>
        <InputSelectSistel
          isMulti
          label="Usuarios"
          name="users"
          col="col-md-6"
          value={values.users}
          options={formUtils.userOptions}
          onChange={formUtils.handleSelect('users')}
        />
        <div className="col-md-6">
          <DataGroupSistel
            title="Comentario de la brigada"
            data={values.brigadeComment || 'Sin datos'}
          />
          <DataGroupSistel
            title="Comentario del empleado"
            data={values.employeeComment || 'Sin datos'}
          />
        </div>
        {determineAttachmentComponent}

        <BaremoEntries data={values} />

        <InputSistel
          label="Comentarios del supervisor"
          col="col-md-12"
          name="supervisorComment"
          value={values.supervisorComment}
          errors={errors.supervisorComment}
          onChange={handleChange}
        />
        <div className="col-md-12 d-flex justify-content-end">
          <ButtonSistel type="button" danger onClick={formUtils.cancelDelivery}>
            Retornar entrega
          </ButtonSistel>
          <ButtonSistel
            type="button"
            success
            className="ml-2"
            onClick={formUtils.validateDelivery}
          >
            Validar entrega
          </ButtonSistel>
        </div>
      </form>
    </FormikProvider>
  )
}

function initialValues(delivery) {
  const formattedUsers = delivery.users?.map((u) => ({
    label: `${u.name} ${u.lastname}`,
    value: u._id
  }))
  return {
    ...delivery,
    emojis: [],
    users: formattedUsers
  }
}
