import { useMemo, useState } from 'react'
import SimpleTable from 'components/Tables/SimpleTable'

export default function EstimatedBaremos({ order }) {
  const [currentOrder, setCurrentOrder] = useState(order)

  const headers = useMemo(
    () => [
      { header: 'Baremo', sort: false, className: 'text-left' },
      { header: 'Estimación', sort: false, className: 'text-right' }
    ],
    []
  )
  // Para que solo aparezcan los baremos que tienen estimación
  const onlyEstimated = order.baremoList.filter(
    ({ estimated }) => estimated > 0
  )

  const tableData = useMemo(
    () =>
      onlyEstimated.map(({ baremo, estimated }) => ({
        description: { value: baremo.description, className: 'text-left' },
        estimated: {
          value: estimated || 0,
          className: 'text-right'
        },
        key: baremo._id
      })),
    []
  )

  return (
    <SimpleTable
      tdClassNames="align-middle text-right"
      col="col-md-12"
      data={currentOrder}
      cb={setCurrentOrder}
      {...{ tableData, headers }}
    />
  )
}
