import { AvatarImageSistel, DataGroupSistel } from 'npm-styles-sistel'
import EmployeeFunctionButton from './EmployeeFunctionButton'
import { EmployeeCardStyled } from './employeeStyles'

export default function EmployeeData({ employee, handleShowResetPassword }) {
  return (
    <EmployeeCardStyled>
      <div className="card">
        <AvatarImageSistel image={employee.avatar} width="64px" />
        <h2 className="mt-3">
          {employee.name} {employee.lastname}
        </h2>
        <div className="icons">
          <EmployeeFunctionButton employee={employee} />
        </div>
        <hr />

        <DataGroupSistel title="DNI/NIE" data={employee.dni} />
        <DataGroupSistel title="Teléfono" data={employee.phoneNumber || '-'} />
        <DataGroupSistel
          title="Brigada"
          data={employee.brigade?.name || 'Sin asignar'}
        />
        <DataGroupSistel title="Experiencia" data={employee.experience} />
      </div>
      <button className="password" onClick={handleShowResetPassword}>
        Restablecer contraseña
      </button>
    </EmployeeCardStyled>
  )
}
