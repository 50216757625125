import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Row from 'components/common/Row'

import { postApi, putApi } from 'redux/actions/fetchActions'
import { postVehicle, putVehicle } from 'redux/actions/vehicleActions'
import ImageUploading from 'react-images-uploading'
import { ButtonSistel, InputSistel } from 'npm-styles-sistel'
import FileService from 'services/FileService'

export default function VehicleForm({ vehicle, handleClose }) {
  const dispatch = useDispatch()
  const [currentVehicle] = useState(initialValues(vehicle))

  const formik = useFormik({
    initialValues: currentVehicle,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async ({ newPickupImages, newDeliveryImages, ...values }) => {
      const newDeliveryURLs = await FileService.uploadFiles(
        newDeliveryImages.map(({ file }) => file)
      )

      const newPickupURLs = await FileService.uploadFiles(
        newPickupImages.map(({ file }) => file)
      )

      const instanceBody = {
        ...values,
        images: {
          delivery: [...values.images.delivery, ...newDeliveryURLs],
          pickup: [...values.images.pickup, ...newPickupURLs]
        }
      }

      if (vehicle) {
        return dispatch(
          putApi(instanceBody, `/vehicle/${values._id}`, [
            putVehicle,
            handleClose
          ])
        )
      }

      dispatch(postApi(instanceBody, '/vehicle', [postVehicle, handleClose]))
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik

  const handleFile = (field) => (imageFiles) => setFieldValue(field, imageFiles)

  const { plate, monthlyCost, brand, model, pickupKm, deliveryKm, seats } =
    values

  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <Row>
        <InputSistel
          type="text"
          label="Matrícula"
          col="col-md-4"
          name="plate"
          value={plate}
          errors={errors.plate}
          onChange={handleChange}
        />
        <InputSistel
          type="text"
          label="Marca"
          col="col-md-4"
          name="brand"
          value={brand}
          errors={errors.brand}
          onChange={handleChange}
        />
        <InputSistel
          type="text"
          label="Modelo"
          col="col-md-4"
          name="model"
          value={model}
          errors={errors.model}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          min="1"
          max="5"
          step="1"
          label="Número de plazas"
          col="col-md-3"
          name="seats"
          value={seats}
          errors={errors.seats}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          min="0"
          step="0.01"
          label="Coste mensual"
          col="col-md-3"
          name="monthlyCost"
          value={monthlyCost}
          errors={errors.monthlyCost}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          min="0"
          step="1"
          label="km recogida"
          col="col-md-3"
          name="pickupKm"
          value={pickupKm}
          errors={errors.pickupKm}
          onChange={handleChange}
        />
        <InputSistel
          type="number"
          min="0"
          step="1"
          label="km entrega"
          col="col-md-3"
          name="deliveryKm"
          value={deliveryKm}
          errors={errors.deliveryKm}
          onChange={handleChange}
        />
        <div className="col-md-6">
          <ImageUploader
            label="Imágenes de la recogida"
            values={values.newPickupImages}
            handleFile={handleFile('newPickupImages')}
            currentValues={values.images.pickup}
          />
        </div>
        <div className="col-md-6">
          {vehicle && (
            <ImageUploader
              label="Imágenes de la entrega"
              values={values.newDeliveryImages}
              handleFile={handleFile('newDeliveryImages')}
              currentValues={values.images.delivery}
            />
          )}
        </div>
        <div className="col-md-12">
          <div className="float-right">
            <ButtonSistel
              edit
              type="button"
              className="mr-2"
              onClick={handleClose}
            >
              Cancelar
            </ButtonSistel>
            <ButtonSistel success type="submit">
              {vehicle ? 'Guardar cambios' : 'Crear vehículo'}
            </ButtonSistel>
          </div>
        </div>
      </Row>
    </form>
  )
}

function ImageUploader({ handleFile, label, values, currentValues }) {
  const currentImages = currentValues.map((url) => (
    <img src={url} width="100" className="mr-2" key={url} />
  ))

  return (
    <ImageUploading
      multiple
      value={values.newPickupImages}
      onChange={handleFile}
      maxNumber={10}
      dataURLKey="data_url"
    >
      {({ onImageUpload, onImageRemove, dragProps }) => (
        <div className="justify-content-between col-md-6">
          <div className="d-flex align-items-center form-group">
            <label>{label}</label>
          </div>
          <div className="upload__image-wrapper">
            <div className="mb-4">
              <h3>Imágenes actuales</h3>
              {currentImages}
            </div>
            <ButtonSistel
              edit
              onClick={onImageUpload}
              type="button"
              {...dragProps}
            >
              + Subir fotos
            </ButtonSistel>
            <div className="mt-4">
              {values.length > 0 && <h3>Nuevas imágenes</h3>}
              {values.map((image, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => onImageRemove(index)}
                  className="mx-1"
                >
                  <img src={image.data_url || image} width="100" />
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </ImageUploading>
  )
}

function initialValues(vehicle) {
  const images = {
    pickup: [],
    delivery: []
  }

  return {
    _id: vehicle?._id || undefined,
    brand: vehicle?.brand || '',
    deliveryKm: vehicle?.deliveryKm || '',
    model: vehicle?.model || '',
    monthlyCost: vehicle?.monthlyCost || '',
    pickupKm: vehicle?.pickupKm || '',
    plate: vehicle?.plate || '',
    seats: vehicle?.seats || '',
    images: vehicle?.images || images,
    newPickupImages: [],
    newDeliveryImages: []
  }
}

function validationSchema() {
  return {
    plate: Yup.string().required('Matrícula obligatoria'),
    brand: Yup.string().required('Marca obligatoria'),
    model: Yup.string(),
    monthlyCost: Yup.number(),
    pickupKm: Yup.number(),
    deliveryKm: Yup.number(),
    seats: Yup.number()
  }
}
