export const GET_BRIGADES = 'GET_BRIGADES'
export const POST_BRIGADE = 'POST_BRIGADE'
export const PATCH_BRIGADE = 'PATCH_BRIGADE'
export const PUT_BRIGADE = 'PUT_BRIGADE'
export const UPDATE_BRIGADE_USERS = 'UPDATE_BRIGADE_USERS'

// ACTIONS
export const getBrigades = (data) => ({
  type: GET_BRIGADES,
  payload: data
})

export const postBrigade = (data) => ({
  type: POST_BRIGADE,
  payload: data
})

export const putBrigade = (data) => ({
  type: PUT_BRIGADE,
  payload: data
})

export const patchBrigade = (data) => ({
  type: PATCH_BRIGADE,
  payload: data
})

export const updateBrigadeUsers = (data) => ({
  type: UPDATE_BRIGADE_USERS,
  payload: data
})
