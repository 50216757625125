export function compareDate(timeStamp1, timeStamp2) {
  const date1 = new Date(timeStamp1)
  const date2 = new Date(timeStamp2)
  return date1 >= date2
}

export function getLastClose(company) {
  if (!company?.config) return new Date()

  const currentDate = new Date()

  if (company.closingDays?.length) {
    return company.closingDays[company.closingDays.length - 1]
  }

  const { closingDay } = company.config
  const currentDay = currentDate.getDate()

  currentDate.setDate(closingDay)

  if (currentDay < closingDay) {
    currentDate.setMonth(currentDate.getMonth() - 1)
  }
  return currentDate
}
