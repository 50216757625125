import styled from 'styled-components'

const ProgressStyled = styled.div`
  margin: 0 16px 0 8px;

  svg {
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 1000;
    transform: rotate(270deg);
      transform-origin: center center;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: ${({theme}) => theme.colors.grey_mid};
      stroke-width: 6;
      stroke-linecap: round;
      transform: translate(5px, 5px);
    }

    circle:nth-child(2) {
      stroke-dasharray: 252;
      stroke-dashoffset: ${({ progress }) => progress};
      stroke: ${({ theme, department }) =>
        department === 'Fusionadores'
          ? theme.colors.blue
          : department === 'Celadores'
          ? theme.colors.purple
          : theme.colors.dark_purple};
      animation: progressbar 2s;
      
    }
  }

  @keyframes progressbar {
    from {
      stroke-dashoffset: 252;
    }
    to {
      stroke-dashoffset: ${({ progress }) => progress};
    }
  }

  .percent {
    position: relative;

    .photo {
      position: absolute;
      top: 5px;
      left: 6px;
      img {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        object-fit: cover;
        filter: brightness(120%)
      }
    }
  }
`

export default function ProgressGoals({ avatar, points, goal, department }) {
  const porcentaje = (points * 100) / goal
  const temp = (252 - 100) * porcentaje / 100
  const final = 252 - temp

  return (
    <>
      <ProgressStyled progress={final} department={department}>
        <div className="percent">
          <svg>
            <circle cx="25" cy="25" r="25" />
            <circle cx="25" cy="25" r="25" />
          </svg>
          <div className="photo">
            <img src={avatar} alt="Avatar" />
          </div>
        </div>
      </ProgressStyled>
    </>
  )
}
