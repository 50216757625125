import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { postApi, putApi } from 'redux/actions/fetchActions'
import { postClient, putClient } from 'redux/actions/clientActions'

import { InputSistel } from 'npm-styles-sistel'
import Row from 'components/common/Row'

export default function ClientForm({ client, onClose }) {
  const dispatch = useDispatch()
  const [currentClient] = useState(initialValues(client))

  const formik = useFormik({
    initialValues: currentClient,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      if (client)
        return dispatch(
          putApi(values, `/client/${values._id}`, [putClient, onClose])
        )

      dispatch(postApi(values, '/client', [postClient, onClose]))
    }
  })

  const { handleSubmit, errors, handleChange, values } = formik

  const { name, cif } = values

  return (
    <form onSubmit={handleSubmit} id="form">
      <Row>
        <InputSistel
          type="text"
          label="Nombre"
          name="name"
          col="col-md-12"
          value={name}
          errors={errors.name}
          onChange={handleChange}
        />
        <InputSistel
          type="text"
          label="CIF"
          name="cif"
          col="col-md-12"
          value={cif}
          errors={errors.cif}
          onChange={handleChange}
        />
      </Row>
    </form>
  )
}

function initialValues(client) {
  return {
    _id: client?._id || undefined,
    name: client?.name || '',
    cif: client?.cif || ''
  }
}

function validationSchema() {
  return {
    name: Yup.string().required('El nombre es obligatorio'),
    cif: Yup.string().required('CIF obligatorio')
  }
}
