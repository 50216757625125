import { useEffect, useRef, useState } from 'react'
import { io } from 'socket.io-client'
import { VEHICLE_PATH } from 'utils/constants'

export default function useSocket({ room }) {
  const [joined, setJoined] = useState(undefined)
  const socketRef = useRef()

  useEffect(() => {
    // Starts socket connection
    if (!socketRef.current) {
      socketRef.current = io.connect(VEHICLE_PATH)
    }

    return () => {
      // Leaves socket room and close connection when unmount hook
      socketRef.current.disconnect()
    }
  }, [])

  useEffect(() => {
    // Join socket room
    if (socketRef.current && !joined) {
      socketRef.current.emit('join', room)
      socketRef.current.on('join', setJoined)
    }
  }, [])

  useEffect(() => {
    socketRef.current.on('error', ({ error }) => {
      console.error(error)
    })
  })

  return { socketRef }
}
