import { capitalize } from './strings'

export function getPointHistoryMonths(users = []) {
  if (!users.length) return []
  const pointHistory = users
    .flatMap((user) => user.pointHistory)
    .map((pH) => {
      const date = new Date(pH.createdAt)
      return JSON.stringify({
        label: `${capitalize(
          date.toLocaleString('default', {
            month: 'long',
            year: 'numeric'
          })
        )}`,
        value: date.toLocaleDateString('es-ES', {
          month: '2-digit',
          year: '2-digit'
        })
      })
    })
  return Array.from(new Set(pointHistory)).map((e) => JSON.parse(e))
}
