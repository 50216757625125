import { IconosSistel } from 'npm-styles-sistel'
import { ColumnDepartmentStlyed } from './dashboardStyles'
import ProgressGoals from './ProgressGoals'

export default function ColumnDepartment({ employees, department }) {
  const filteredEmployees = employees
    .map((e) => ({
      ...e,
      totalPoints: e.points.reduce((prev, curr) => prev + (curr.qty || 0), 0)
    }))
    .filter((e) => Boolean(e.totalPoints))
    .sort((e1, e2) => e2.totalPoints - e1.totalPoints)
    .slice(0, 3)

  const displayEmployees =
    Boolean(filteredEmployees.length) &&
    filteredEmployees.map((e) => (
      <EmployeeDashboardCard
        employee={e}
        department={department}
        key={Math.random()}
      />
    ))

  return (
    <div className="col-md-6">
      <ColumnDepartmentStlyed department={department}>
        <h1 className="title">
          <IconosSistel icon="puntos" width="18" color="white" />
          Top {department}
        </h1>
        {displayEmployees || (
          <p className="mt-4">No hay trabajadores con puntos asignados</p>
        )}
      </ColumnDepartmentStlyed>
    </div>
  )
}

function EmployeeDashboardCard({ employee, department }) {
  const { monthlyGoal } = employee.department

  return (
    <div className="employee">
      <ProgressGoals
        avatar={employee.avatar}
        points={employee.totalPoints}
        goal={monthlyGoal}
        department={department}
      />

      <div>
        <p className="name">
          {employee.name} {employee.lastname}
        </p>
        <p className="puntos">{Math.round(employee.totalPoints)}</p>
      </div>
    </div>
  )
}
