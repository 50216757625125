import { defaultState } from 'redux/utils/stateCreator'
import { GET_POINTS } from '../actions/pointActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_POINTS:
      return { data: [...action.payload], loaded: true }
    default:
      return state
  }
}
