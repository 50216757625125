import { combineReducers, createStore, applyMiddleware  } from 'redux'
import thunk from 'redux-thunk'

import * as reducers from './reducers'

// FIXME: Descomentar al hacer build.
export default createStore(
    combineReducers({ ...reducers }),
   applyMiddleware(thunk)
 )


// FIXME: Comentar al hacer build
//export default createStore(
 //combineReducers({ ...reducers }),
  //compose(applyMiddleware(thunk), reduxDevtools())
//)

//function reduxDevtools() {
  //if (process.env.NODE_ENV === 'development') {
//   return (
//      window.__REDUX_DEVTOOLS_EXTENSION__ &&
//      window.__REDUX_DEVTOOLS_EXTENSION__()
//    )
//  }
//}
