import styled from 'styled-components'
import Icon from '@mdi/react'
import { mdiCheckBold } from '@mdi/js'

import { downloadImgZip } from 'utils/zip'

import { IconosSistel } from 'npm-styles-sistel'

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;

  button.download {
    alignitems: center;
    background-color: #f8f5f8;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    padding: 6px 12px;
  }

  button.validate {
    background-color: #ceffe2;
    border-radius: 8px;
    height: 32px;
    margin-left: 16px;
    width: 32px;

    svg {
      color: #00d87d;
    }

    :hover {
      background-color: #00d87d;

      svg {
        color: white;
      }
    }
  }
`

export default function DocumentationActions({
  downloadData,
  downloadName,
  handleClick
}) {
  return (
    <ActionsStyled>
      <button
        className="download"
        onClick={() => downloadImgZip(downloadData, downloadName)}
      >
        <IconosSistel icon="download" />
        <span style={{ marginLeft: '6px' }}>Descargar imágenes</span>
      </button>
      <button className="validate" onClick={handleClick}>
        <Icon path={mdiCheckBold} size={0.7} />
      </button>
    </ActionsStyled>
  )
}
