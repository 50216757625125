import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import Row from 'components/common/Row'
import ActionButton from 'components/common/ActionButton'

import { postApi, putApi } from 'redux/actions/fetchActions'
import { ButtonSistel, InputSelectSistel, InputSistel } from 'npm-styles-sistel'

export default function ProjectForm({
  project,
  handleClose,
  clients,
  refetch
}) {
  const dispatch = useDispatch()
  const [currentProject] = useState(initialValues(project))

  const formik = useFormik({
    initialValues: currentProject,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      if (project) {
        const editCb = () => {
          handleClose()
          refetch()
        }
        dispatch(putApi(values, `/project/${values._id}`, [null, editCb]))
        return
      }
      const postCb = () => {
        handleClose()
        refetch()
      }
      dispatch(postApi(values, '/project', [null, postCb]))
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik

  const { ref, title, client, startDate, city, postalCode } = values

  const clientOptions = clients.map((c) => {
    return {
      label: c.name,
      value: c._id
    }
  })

  const handleSelect = (field) => (e) => setFieldValue(field, e.value)

  return (
    <FormikProvider value={formik}>
      <form className="mt-5" onSubmit={handleSubmit}>
        <Row className="mb-4">
          <InputSistel
            type="text"
            label="Referencia"
            col="col-md-6"
            name="ref"
            value={ref}
            errors={errors.ref}
            onChange={handleChange}
            disabled={Boolean(project)}
          />
          <InputSistel
            type="text"
            label="Título"
            col="col-md-6"
            name="title"
            value={title}
            errors={errors.title}
            onChange={handleChange}
          />
          <InputSelectSistel
            label="Cliente"
            name="client"
            col="col-md-6"
            placeholder="Selecciona un cliente..."
            errors={errors.client}
            options={clientOptions}
            value={client}
            onChange={handleSelect('client')}
          />
          <InputSistel
            type="date"
            label="Fecha de inicio"
            col="col-md-6"
            name="startDate"
            value={startDate.split('T')[0]}
            errors={errors.startDate}
            onChange={handleChange}
          />
          <InputSistel
            type="text"
            label="Población"
            col="col-md-6"
            name="city"
            value={city}
            errors={errors.city}
            onChange={handleChange}
          />
          <InputSistel
            type="text"
            label="Código postal"
            col="col-md-6"
            name="postalCode"
            value={postalCode}
            errors={errors.postalCode}
            onChange={handleChange}
          />
        </Row>
        <p className="subtitle-form mb-3">Zonas</p>
        <FieldArray
          validateOnChange={false}
          name="zone"
          render={(arrHelper) => (
            <>
              {values.zone.map((zone, idx) => (
                <Row key={idx} className="align-items-center">
                  <InputSistel
                    col="col-md-3"
                    type="text"
                    label="Título"
                    name={`zone[${idx}].title`}
                    onChange={handleChange}
                    value={zone.title}
                  />

                  <InputSistel
                    type="text"
                    label="Descripción"
                    col="col-md-8"
                    name={`zone[${idx}].description`}
                    onChange={handleChange}
                    value={zone.description}
                  />
                  <ActionButton
                    isDelete
                    onClick={() => arrHelper.remove(zone[idx])}
                  />
                </Row>
              ))}

              <ButtonSistel
                secondary
                type="button"
                onClick={() => arrHelper.push('')}
              >
                Añadir zona
              </ButtonSistel>
            </>
          )}
        />

        <Row>
          <div className="col-md-12">
            <div className="float-right">
              <ButtonSistel
                edit
                type="button"
                className="mr-2"
                onClick={handleClose}
              >
                Cancelar
              </ButtonSistel>
              <ButtonSistel success type="submit">
                {project ? 'Guardar cambios' : 'Crear proyecto'}
              </ButtonSistel>
            </div>
          </div>
        </Row>
      </form>
    </FormikProvider>
  )
}

function initialValues(project) {
  return {
    _id: project?._id || undefined,
    ref: project?.ref || '',
    title: project?.title || '',
    client: project?.client || undefined,
    startDate: project?.startDate || '',
    city: project?.city || '',
    postalCode: project?.postalCode || '',
    zone: project?.zone ? [...project.zone] : [{ title: '', description: '' }]
  }
}

function validationSchema() {
  const zoneSubSchema = {
    title: Yup.string().required('Titulo de la zona obligatorio'),
    description: Yup.string()
  }

  return {
    ref: Yup.string(),
    title: Yup.string(),
    client: Yup.string(),
    startDate: Yup.string(),
    city: Yup.string(),
    postalCode: Yup.string(),
    zone: Yup.array().of(Yup.object().shape(zoneSubSchema))
  }
}
