import { BadgeSistel } from 'npm-styles-sistel'
import {
  CERTIFIED,
  FINISHED,
  INCIDENCE,
  IN_PROGRESS,
  PENDING_VALIDATE
} from 'utils/constants'
import styled from 'styled-components'

const BadgeGroup = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  p {
    margin-right: 8px;
    ${({ theme }) => theme.fonts.text_s};
  }
`

export default function LeyendaProyecto({ orders }) {
  const ordersData = {
    CERTIFIED: orders.filter((o) => o.status === CERTIFIED).length,
    FINISHED: orders.filter((o) => o.status === FINISHED).length,
    INCIDENCE: orders.filter((o) => o.status === INCIDENCE).length,
    IN_PROGRESS: orders.filter((o) => o.status === IN_PROGRESS).length,
    PENDING_VALIDATE: orders.filter((o) => o.status === PENDING_VALIDATE).length
  }

  return (
    <div>
      <BadgeGroup>
        <p>{ordersData.IN_PROGRESS}</p>
        <BadgeSistel blue>En proceso</BadgeSistel>
      </BadgeGroup>
      <BadgeGroup>
        <p>{ordersData.FINISHED}</p>
        <BadgeSistel success>Finalizada</BadgeSistel>
      </BadgeGroup>
      <BadgeGroup>
        <p>{ordersData.PENDING_VALIDATE}</p>
        <BadgeSistel warning>Validación pendiente</BadgeSistel>
      </BadgeGroup>
      <BadgeGroup>
        <p>{ordersData.CERTIFIED}</p>
        <BadgeSistel purple>Certificadas</BadgeSistel>
      </BadgeGroup>
      <BadgeGroup>
        <p>{ordersData.INCIDENCE}</p>
        <BadgeSistel danger>Incidencia</BadgeSistel>
      </BadgeGroup>
    </div>
  )
}
