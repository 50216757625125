import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBaremos } from 'redux/actions/baremoActions'
import { getCompany } from 'redux/actions/companyActions'
import { getDepartments } from 'redux/actions/departmentActions'
import { getApi } from 'redux/actions/fetchActions'

/**
 * @param {{}} me User data provided for redux
 * @returns {{ data: {}, loading: boolean}} Returns data object and loader boolean
 */
export default function useCompany(me) {
  const dispatch = useDispatch()

  const { loaded, data } = useSelector((s) => s.company)

  useEffect(() => {
    if (!me || loaded) return
    dispatch(getApi(`/baremo`, getBaremos))
    dispatch(getApi(`/department`, getDepartments))
    dispatch(getApi(`/company`, getCompany))
  }, [me])

  return { data, loaded }
}
