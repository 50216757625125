import { IconosSistel } from 'npm-styles-sistel'
import { Link } from 'react-router-dom'
import ColumnDepartment from './ColumnDepartment'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

const departments = ['Fusionadores', 'Celadores']

export default function TopEmployees({ employees }) {
  const getEmployeesByDepartment = (departmentName) =>
    employees?.filter(
      (employee) => employee.department?.name === departmentName
    )

  const displayDepartments = departments.map((dep, i) => (
    <ColumnDepartment
      employees={getEmployeesByDepartment(dep)}
      department={dep}
      key={dep + i}
    />
  ))

  return (
    <div className="card mt-4">
      <div className="card-title">
        <h3>
          <IconosSistel icon="empleados" width="20" /> Objetivos mensuales
        </h3>
        <Link to="/puntos">
          Ver resumen de puntos <Icon path={mdiChevronRight} size={0.8} />
        </Link>
      </div>
      <div className="row mt-4">{displayDepartments}</div>
    </div>
  )
}
