import { useMemo } from 'react'
import useStateWithDep from 'hooks/useStateWithDep'

import Table from './Table'
import styled from 'styled-components'
import { AvatarImageSistel } from 'npm-styles-sistel'

const MultipleUsers = styled.div`
  display: flex;
  flex-wrap: wrap;
  .user {
    display: flex;
    align-items: center;
    background: #f4f2f9;
    padding: 4px 16px 4px 4px;
    border-radius: 30px;
    margin: 4px 12px 4px 0;

    .name {
      margin-left: 10px;
      font-size: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.text_dark};
    }
  }
`

export default function NotificationsTable({ data }) {
  const [notifications, setNotifications] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      notifications.map((not) => {
        const creationDate = new Date(not.createdAt)
        return {
          createdAt: creationDate.toLocaleDateString(),
          comments: not.comments,
          users: (
            <MultipleUsers>
              {not.users.map((user) => (
                <div key={user._id} className="user">
                  <AvatarImageSistel image={user.avatar} width="32px" />
                  <p className="name">
                    {user.name} {user.lastname}
                  </p>
                </div>
              ))}
            </MultipleUsers>
          ),
          qty: {
            value: not.qty,
            className: 'text-right'
          },
          key: not._id
        }
      }),
    [notifications]
  )

  const headers = useMemo(
    () => [
      { header: 'Fecha', sort: true },
      { header: 'Motivo', sort: false },
      { header: 'Trabajadores', sort: false },
      { header: 'Puntos', sort: true, className: 'text-right' }
    ],
    []
  )

  return (
    <Table
      {...{ data: notifications, tableData, headers, cb: setNotifications }}
    />
  )
}
