import { useMemo } from 'react'
import useStateWithDep from 'hooks/useStateWithDep'
import Table from './Table'

export default function ClientsTable({ data, cb }) {
  const [clients, setClients] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      clients.map((client) => {
        return {
          name: (
            <p
              className="table-link"
              onClick={() => cb(client)}
              key={client.name}
            >
              {client.name}
            </p>
          ),
          cif: client.cif,
          projects: {
            value: client.projects.length,
            className: 'text-right'
          },
          key: client._id
        }
      }),
    [clients]
  )

  const headers = useMemo(
    () => [
      { header: 'Cliente', sort: true },
      { header: 'CIF', sort: true },
      { header: 'Proyectos', sort: true, className: 'text-right' }
    ],
    []
  )

  return <Table {...{ data: clients, tableData, headers, cb: setClients }} />
}
