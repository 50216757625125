import { useState } from 'react'

import MainContainer from 'components/common/MainContainer'
import TableGapOrders from 'components/Orders/TableGapOrders'
import useGapOrders from 'hooks/useGapOrders'
import useFilter from 'hooks/useFilter'
import useCheckboxFilter from 'hooks/useCheckbox'
import { GAP_REASONS } from 'utils/constants'

import { HeaderFiltersSistel, LoaderSistel } from 'npm-styles-sistel'

export default function OrdenesHuecos() {
  const [, setShowConfirm] = useState(null)

  const [data, fetching] = useGapOrders()
  const { gapOrders, projects } = data

  const [handleChange, foundOrders] = useFilter(gapOrders, [
    'cluster',
    'brigade.name',
    'workType',
    'project.title',
    'ref'
  ])

  const projectChecks = projects
    .filter((p) => gapOrders.find((o) => o.project._id === p._id))
    .map((p) => ({
      _id: p._id,
      name: p.title
    }))
  const [displayProjectChecks, filteredOrdersCB1] = useCheckboxFilter(
    [{ key: 'project', subKey: '_id' }],
    projectChecks,
    foundOrders
  )

  const typeChecks = GAP_REASONS.map((t) => ({ _id: t, name: t }))
  const [displayTypeChecks, filteredOrdersCB2] = useCheckboxFilter(
    [{ key: 'gaps' }, { key: 'reason' }],
    typeChecks,
    filteredOrdersCB1
  )

  if (fetching) return <LoaderSistel />

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="ordenesHuecos"
        title="Órdenes de trabajo con huecos"
        handleSearch={handleChange}
        filters={
          <>
            <h4>Tipo de hueco</h4>
            {displayTypeChecks}
            <h4>Proyecto</h4>
            {displayProjectChecks}
            <hr />
          </>
        }
      />
      <TableGapOrders data={filteredOrdersCB2} {...{ setShowConfirm }} />
    </MainContainer>
  )
}
