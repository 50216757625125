import {
  CERTIFIED,
  FINISHED,
  FUSIONADOR,
  PENDING,
  VALIDATED
} from 'utils/constants'

import { ButtonSistel } from 'npm-styles-sistel'

export default function ValidationButton({ order, cb }) {
  const isValidationPossible =
    Boolean(order.employeeDeliveries.length) &&
    Boolean(!order.employeeDeliveries.find((d) => d.status === PENDING)) &&
    Boolean(order.status !== CERTIFIED) &&
    Boolean(order.status !== FINISHED)

  const hasAllBoxesValidated =
    order.type === FUSIONADOR &&
    order.boxList.every((box) =>
      order.employeeDeliveries.find(
        (od) => od.boxId === box._id && od.status === VALIDATED
      )
    )

  return isValidationPossible || hasAllBoxesValidated ? (
    <ButtonSistel success onClick={cb} className="mt-5">
      Validar orden
    </ButtonSistel>
  ) : null
}
