export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const POST_NOTIFICATION = 'GET_NOTIFICATION'

// ACTIONS
export const getNotifications = (data) => ({
  type: GET_NOTIFICATIONS,
  payload: data
})

export const postNotifications = (data) => ({
  type: POST_NOTIFICATION,
  payload: data
})
