import styled from 'styled-components'
import { IconosSistel, LightboxImageSistel } from 'npm-styles-sistel'

const BoxStyled = styled.div`
  margin-bottom: 32px;
  .title {
    background: ${({ theme }) => theme.colors.text_light};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    border-radius: 20px 20px 0 0;

    button {
      background-color: transparent;
    }
  }

  .main-content {
    padding: 24px;
    box-shadow: 0px 0px 30px rgba(192, 185, 198, 0.3);
    border-radius: 0 0 20px 20px;
  }
`

export default function BoxCard({ box, delivery, cb }) {
  const boxPhotos = delivery && Object.values(delivery.boxPhotos).flat()
  return (
    <BoxStyled>
      <div className="title">
        <h1 className="name">{box.name}</h1>
        {delivery && boxPhotos && (
          <button onClick={() => cb(delivery.boxPhotos, box.name)}>
            <IconosSistel icon="download"/>
          </button>
        )}
      </div>
      <div className="main-content">
        {delivery ? (
          boxPhotos ? (
            <LightboxImageSistel images={boxPhotos} />
          ) : (
            <div className="no-results">
              <p>No hay fotos en la entrega</p>
            </div>
          )
        ) : (
          <div className="no-results">
            <img src="/images/no-results.png" alt="" />
            <p>No hay fotos en la entrega</p>
          </div>
        )}
      </div>
    </BoxStyled>
  )
}
