export const GET_USERS = 'GET_USERS'
export const POST_USER = 'POST_USER'
export const PUT_USER = 'PUT_USER'
export const PATCH_USER = 'PATCH_USER'
export const UPDATE_USER_BRIGADE = 'UPDATE_USER_BRIGADE'
export const RESET_USER_BRIGADES = 'RESET_USER_BRIGADES'

// ACTIONS
export const getUsers = (data) => ({
  type: GET_USERS,
  payload: data
})

export const postUser = (data) => ({
  type: POST_USER,
  payload: data
})

export const putUser = (data) => ({
  type: PUT_USER,
  payload: data
})

export const patchUser = (data) => ({
  type: PATCH_USER,
  payload: data
})

export const updateUserBrigade = (data) => ({
  type: UPDATE_USER_BRIGADE,
  payload: data
})

export const resetUserBrigades = (data) => ({
  type: RESET_USER_BRIGADES,
  payload: data
})
