import { IconosSistel } from 'npm-styles-sistel'
import styled from 'styled-components'
import themeSistel from 'npm-styles-sistel/src/theme'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { getBrigades } from 'redux/actions/brigadeActions'

const GroupEmployee = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 6px;
  }
`
export default function EmployeeFunctionButton({ employee }) {
  const brigades = useDynamicFetch('brigade', getBrigades)

  const isDriver = brigades.data.find((brigade) =>
    brigade.driver ? brigade.driver === employee._id : undefined
  )
  const isManager = brigades.data.find((brigade) =>
    brigade.manager ? brigade.manager === employee._id : undefined
  )
  return (
    <GroupEmployee>
      {employee.driverLicense && (
        <IconosSistel
          icon="vehiculos"
          width="24"
          color={
            isDriver
              ? themeSistel.colors.dark_purple
              : themeSistel.colors.dark_purple_light
          }
        />
      )}

      {employee.canBeManager && (
        <IconosSistel
          icon="leader"
          width="20"
          color={
            isManager
              ? themeSistel.colors.dark_purple
              : themeSistel.colors.dark_purple_light
          }
        />
      )}
    </GroupEmployee>
  )
}
