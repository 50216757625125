import { ButtonSistel } from 'npm-styles-sistel'
import { useHistory } from 'react-router'
import styled from 'styled-components'

const Styled404 = styled.div`
  background-image: url('/images/404.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;

  p {
    ${({ theme }) => theme.fonts.text};
  }

  h1 {
    font-size: 160px;
    color: white;
    line-height: 1em;
    margin-bottom: 60px;
  }

  h2 {
    font-size: 30px;
    font-weight: 400;
    max-width: 600px;
    text-align: center;
    margin-bottom: 24px;
  }
`

export default function NoMatch() {
  const history = useHistory()

  return (
    <Styled404>
      <p>Página no encontrada</p>
      <h1>404</h1>
      <h2>Parece que tienes el poder de la superfuerza. Contrólate :)</h2>
      <ButtonSistel onClick={() => history.goBack()}>Volver atrás</ButtonSistel>
    </Styled404>
  )
}
