import { useState } from 'react'
import { useDispatch } from 'react-redux'

import BaremosTable from 'components/Tables/BaremosTable'
import MainContainer from 'components/common/MainContainer'
import { BaremoForm } from 'components/forms'

import useModal from 'hooks/useModal'
import useFilter from 'hooks/useFilter'
import useDynamicFetch from 'hooks/useDynamicFetch'
import useCheckboxFilter from 'hooks/useCheckbox'
import { getBaremos, patchBaremo } from 'redux/actions/baremoActions'
import { patchApi } from 'redux/actions/fetchActions'
import { getClients } from 'redux/actions/clientActions'
import { populateInstance } from 'utils/maps'

import {
  ModalSistel,
  LoaderSistel,
  HeaderFiltersSistel,
  ConfirmationModalSistel
} from 'npm-styles-sistel'

export default function Baremos() {
  const dispatch = useDispatch()
  const baremos = useDynamicFetch('baremo', getBaremos)
  const clients = useDynamicFetch('client', getClients)
  const [selectedBaremo, setSelectedBaremo] = useState(undefined)

  const { actions, show, title } = useModal()
  const { handleShow, handleClose } = actions

  const confirmationModal = useModal()

  const populatedData = baremos.data
    .filter((baremo) => baremo?.active)
    .map((baremo) => ({
      ...baremo,
      clients: baremo.clients.map((c) => ({
        ...c,
        client: populateInstance(clients.data, c.client)
      }))
    }))

  const [handleChange, foundBaremos] = useFilter(populatedData, [
    'ref',
    'description'
  ])

  const [displayClientsFilter, filteredData] = useCheckboxFilter(
    [{ key: 'clients' }, { key: 'client', subKey: '_id', value: 'pointValue' }],
    clients.data,
    foundBaremos
  )

  if (!baremos.loaded || !clients.loaded) return <LoaderSistel />

  const handleSelect = (baremo) => {
    setSelectedBaremo(baremo)
    handleShow('Editar baremos')
  }

  const toggleBaremoState = () => {
    dispatch(
      patchApi(selectedBaremo._id, `/baremo/${selectedBaremo._id}`, [
        patchBaremo,
        handleClose
      ])
    )
  }

  const deleteProps = {
    secBtnText: 'Eliminar baremo',
    secBtnFunction: () =>
      confirmationModal.actions.handleShow('Eliminar baremo')
  }
  const showDeleteProps = selectedBaremo ? deleteProps : {}

  return (
    <MainContainer>
      <HeaderFiltersSistel
        iconTitle="baremos"
        title="Baremos"
        handleSearch={handleChange}
        buttonText="Nuevo Baremo"
        buttonAction={() => handleShow('Nuevo cliente')}
        filters={
          <>
            <h4>Clientes</h4>
            {displayClientsFilter}
          </>
        }
      />
      <BaremosTable data={filteredData} cb={handleSelect} />
      {show && (
        <ModalSistel
          {...{ show, title, ...showDeleteProps }}
          handleClose={handleClose(setSelectedBaremo)}
          iconTitle={selectedBaremo ? 'baremos' : 'baremoNuevo'}
        >
          <BaremoForm
            handleClose={handleClose(setSelectedBaremo)}
            baremo={selectedBaremo}
          />
          {confirmationModal.show && (
            <ConfirmationModalSistel
              buttonType="button"
              modalContent="¿Seguro que quieres borrar el baremo?"
              modalFunction={toggleBaremoState}
              {...{ ...confirmationModal, ...confirmationModal.actions }}
            />
          )}
        </ModalSistel>
      )}
    </MainContainer>
  )
}
