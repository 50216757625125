export const GET_FILTERS = 'GET_FILTERS'
export const POST_FILTER = 'POST_FILTER'
export const PUT_FILTER = 'PUT_FILTER'

// ACTIONS
export const getFilters = (data) => ({
  type: GET_FILTERS,
  payload: data
})

export const postFilter = (data) => ({
  type: POST_FILTER,
  payload: data
})

export const putFilter = (data) => ({
  type: PUT_FILTER,
  payload: data
})
