import styled from 'styled-components'
import { Link } from 'react-router-dom'

const BreadcrumbsStyled = styled.div`
  display: flex;
  margin-bottom: 50px;
  color: ${({ theme }) => theme.colors.text_dark};
  font-weight: 500;

  a {
    border-bottom: 2px solid ${({ theme }) => theme.colors.text_dark};
  }

  p {
    margin: 0 12px;
  }

  .current {
    margin: 0;
    font-weight: 400;
  }
`

export default function BreadCrumbs({ projectTitle, projectRef, cluster }) {
  return (
    <BreadcrumbsStyled>
      <Link to="/documentos">Gestor Documental</Link>
      <p>/</p>

      {cluster ? (
        <>
          <Link to={`/documentos/${projectRef}`}>{projectTitle}</Link>
          <p>/</p>
          <p className="current">{cluster}</p>
        </>
      ) : (
        <>
          <p className="current">{projectTitle}</p>
        </>
      )}
    </BreadcrumbsStyled>
  )
}
