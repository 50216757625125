import { LightboxImageSistel } from 'npm-styles-sistel'
import styled from 'styled-components'

const GrupoAdjuntos = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
`

export default function Attachments({ data }) {
  return (
    <div className="col-md-12">
      {data && (
        <>
          <h2 className="title-fotos">Imágenes adjuntas</h2>
          <GrupoAdjuntos>
            <LightboxImageSistel images={data} />
          </GrupoAdjuntos>
        </>
      )}
    </div>
  )
}
