import { BrigadeForm } from 'components/forms'
import useDynamicFetch from 'hooks/useDynamicFetch'
import { populateInstance } from 'utils/maps'
import { getUsers } from 'redux/actions/userActions'
import { getBrigades } from 'redux/actions/brigadeActions'
import { getVehicles } from 'redux/actions/vehicleActions'
import { getDepartments } from 'redux/actions/departmentActions'
import { LoaderSistel } from 'npm-styles-sistel'

export default function BrigadaAction({ brigade, handleClose }) {
  const users = useDynamicFetch('user', getUsers)
  const vehicles = useDynamicFetch('vehicle', getVehicles)
  const brigades = useDynamicFetch('brigade', getBrigades)
  const departments = useDynamicFetch('department', getDepartments)

  if (
    !brigades.loaded ||
    !users.loaded ||
    !vehicles.loaded ||
    !departments.loaded
  )
    return <LoaderSistel />

  const populatedUsers = [...users.data].map((user) => ({
    ...user,
    department: populateInstance(departments.data, user.department)
  }))

  return (
    <BrigadeForm
      {...{ brigade }}
      allBrigades={brigades.data}
      allUsers={populatedUsers}
      allVehicles={vehicles.data}
      handleClose={handleClose}
    />
  )
}
