import { useCallback, useRef } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import OrderCard from './OrderCard'

import useOrders from 'hooks/useOrders'
import { IN_PROGRESS, ORDER_COLUMN_LIMIT } from 'utils/constants'

import {
  ColumnStyled,
  OrderCardStyled,
  StateColumnTitleStyled
} from '../orderStyle'

export default function OrderTableColumn({
  text,
  size,
  orderStatus = IN_PROGRESS,
  ...rest
}) {
  const {
    paginate,
    page,
    fetching,
    data: orders,
    totalResults
  } = useOrders(orderStatus, rest.filters)

  const observer = useRef()

  const lastOrderElement = useCallback(
    (htmlNode) => {
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (
            !fetching &&
            totalResults > ORDER_COLUMN_LIMIT &&
            orders.length < totalResults
          ) {
            paginate()
          }
        }
      })

      if (htmlNode) observer.current.observe(htmlNode)
    },
    [fetching, page]
  )

  return (
    <div className="col-md-3" key={orderStatus}>
      <StateColumnTitleStyled {...rest}>
        <h3>
          {text} <span>{totalResults}</span>
        </h3>
      </StateColumnTitleStyled>
      <ColumnStyled>
        {fetching && !orders.length ? (
          <LoaderCard />
        ) : (
          orders.map((order, index) => {
            if (orders.length === index + 1) {
              return (
                <div key={`order_${orderStatus}_${index}`}>
                  <OrderCard
                    getRef={lastOrderElement}
                    order={order}
                    size={size}
                    key={order._id}
                    {...rest}
                  />
                  {fetching ? (
                    <OrderCardStyled
                      to="#"
                      size="l"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <ClipLoader loading={fetching} />
                    </OrderCardStyled>
                  ) : null}
                </div>
              )
            }

            return (
              <OrderCard order={order} size={size} key={order._id} {...rest} />
            )
          })
        )}
      </ColumnStyled>
    </div>
  )
}

function LoaderCard() {
  return (
    <OrderCardStyled
      to="#"
      size="l"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ClipLoader loading={true} />
    </OrderCardStyled>
  )
}
