import { useState } from 'react'
import { Link } from 'react-router-dom'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'

import NewOrderModal from 'components/common/NewOrderModal'
import DashboardOrder from 'components/Dashboard/DashboardOrder'
import DashboardProgressBar from 'components/Dashboard/DashboardProgressBar'
import OrdersChart from 'components/Dashboard/OrdersChart'
import TopEmployees from 'components/Dashboard/TopEmployees'
import useDashboard from 'hooks/useDashboard'

import { DashboardStyled } from 'components/Dashboard/dashboardStyles'
import { IconosSistel, LoaderSistel } from 'npm-styles-sistel'

export default function Dashboard() {
  const [data, loading] = useDashboard()
  const [show, setShow] = useState(null)

  if (loading) return <LoaderSistel />

  const { deliveries, employees, orders } = data

  return (
    <DashboardStyled>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9">
            <div className="card">
              <div className="card-title">
                <h3>
                  <IconosSistel icon="ordenes" width="20" /> Órdenes por validar
                </h3>
                <Link to="/ordenes">
                  Ver todas <Icon path={mdiChevronRight} size={0.8} />
                </Link>
              </div>
              {deliveries.length ? (
                deliveries.map((delivery, i) => (
                  <DashboardOrder key={i} order={delivery.order} />
                ))
              ) : (
                <div className="all-done">
                  <img src="/images/done.png" alt="" />
                  <p>¡Todas las órdenes están validadas!</p>
                </div>
              )}
              <button className="nueva-orden" onClick={() => setShow(true)}>
                + Crear nueva orden
              </button>
            </div>
            <TopEmployees employees={employees} />
          </div>
          <div className="col-md-3">
            <DashboardProgressBar employees={employees} />
            <OrdersChart orders={orders} />
          </div>
        </div>
      </div>
      {show && <NewOrderModal handleClose={() => setShow(null)} />}
    </DashboardStyled>
  )
}
