import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useSocket from './useSocket'

const defaultCenter = {
  lat: 41.401543,
  lng: 2.171822
}

export default function useMultipleCoords(plates) {
  const me = useSelector((s) => s.me)

  const [coords, setCoords] = useState([])

  const [listening, setListening] = useState(false)

  const { socketRef } = useSocket({ room: me.company })

  useEffect(() => {
    if (!coords.length) {
      socketRef.current.emit('getAllVehicles', {
        companyId: me.company,
        plates
      })
    }

    const interval = setInterval(() => {
      socketRef.current.emit('getAllVehicles', {
        companyId: me.company,
        plates
      })
    }, 10000)

    return () => clearInterval(interval)
  }, [listening])

  useEffect(() => {
    socketRef.current.on('getAllVehicles', (data) => {
      if (!listening) setListening(true)
      setCoords(data)
    })
  }, [])

  return {
    center: defaultCenter,
    coords
  }
}
