import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_DEPARTMENTS,
  PATCH_DEPARTMENT,
  POST_DEPARTMENT,
  PUT_DEPARTMENT
} from '../actions/departmentActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return { data: [...action.payload], loaded: true }
    case POST_DEPARTMENT:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_DEPARTMENT:
      return {
        ...state,
        data: state.data.map((department) =>
          department._id === action.payload._id
            ? { ...department, ...action.payload }
            : department
        )
      }
    case PATCH_DEPARTMENT:
      return {
        ...state,
        data: state.data.map((department) =>
          department._id === action.payload
            ? { ...department, active: !department.active }
            : department
        )
      }
    default:
      return state
  }
}
