import { matchSorter } from 'match-sorter'
import { useState } from 'react'
/**
 *
 * @param {Array.<Object>} data Array of instances to search
 * @param {string} search String to compare
 * @param {Array.<String>} fields Array with data instances to search
 */
export default function useFilter(data, fields) {
  const [textFilter, setTextFilter] = useState('')

  const handleChange = ({ target }) => setTextFilter(target.value)

  const filteredData = textFilter
    ? matchSorter(data, textFilter, { keys: fields })
    : data

  return [handleChange, filteredData]
}
