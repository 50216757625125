import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_USERS,
  PATCH_USER,
  POST_USER,
  PUT_USER,
  UPDATE_USER_BRIGADE,
  RESET_USER_BRIGADES
} from '../actions/userActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_USERS:
      return { data: [...action.payload], loaded: true }
    case POST_USER:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_USER:
      return {
        ...state,
        data: state.data.map((user) =>
          user._id === action.payload._id
            ? { ...user, ...action.payload }
            : user
        )
      }
    case PATCH_USER:
      return {
        ...state,
        data: state.data.map((user) =>
          user._id === action.payload ? { ...user, active: !user.active } : user
        )
      }
    case UPDATE_USER_BRIGADE: {
      const { prevUsers = [], newUsers, brigade } = action.payload
      const extractedUsers = prevUsers.filter(
        (user) => !newUsers.includes(user)
      )

      return {
        ...state,
        data: state.data.map((user) => {
          if (extractedUsers.includes(user._id)) {
            return { ...user, brigade: null }
          }

          if (newUsers.includes(user._id)) {
            return { ...user, brigade }
          }

          return user
        })
      }
    }
    case RESET_USER_BRIGADES:
      return {
        ...state,
        data: state.data.map((user) => {
          if (action.payload.includes(user._id))
            return { ...user, brigade: null }

          return user
        })
      }

    default:
      return state
  }
}
