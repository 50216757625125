import { Link } from 'react-router-dom'
import { FUSIONADOR } from 'utils/constants'
import { DetailsOrderStyled } from './orderStyle'
import {
  BadgeSistel,
  DataGroupSistel,
  IconosSistel,
  LightboxImageSistel
} from 'npm-styles-sistel'

export default function OrderDetails({ order }) {
  const determineBrigadeField = order.brigade ? (
    <Link to="/brigadas">{order.brigade.name}</Link>
  ) : (
    'No asignada'
  )

  return (
    <>
      <div className="card">
        <DetailsOrderStyled priority={order.priority === 'P01'}>
          <h1>{order.cluster}</h1>
          <h4>
            <IconosSistel icon="proyectos" width="12" />
            {order.project?.title || '-'}
          </h4>
          <h5>
            <IconosSistel icon="brigadas" width="12" color="#8D88A4" />
            {determineBrigadeField}
          </h5>
        </DetailsOrderStyled>

        <hr />
        <DataGroupSistel
          title="Tipo de orden"
          data={
            order.type === FUSIONADOR ? (
              <BadgeSistel blue>Fusionador</BadgeSistel>
            ) : (
              <BadgeSistel purple>Celador</BadgeSistel>
            )
          }
        />
        <DataGroupSistel title="Zona" data={order.zone} />
        <DataGroupSistel
          title="Comentario del supervisor"
          data={order.supervisorComment ? order.supervisorComment : 'Sin datos'}
        />
        <DataGroupSistel title="UUII diseñadas" data={order.uuiiDesigned} />
        <DataGroupSistel title="UUII construidas" data={order.uuiiBuilt} />
        <DataGroupSistel title="UUII certificadas" data={order.uuiiCertified} />
        <DataGroupSistel
          last
          title="Orden validada por:"
          data={
            order.finishedBy
              ? `${order.finishedBy.name} ${order.finishedBy.lastname}`
              : '-'
          }
        />
        <LightboxImageSistel images={order.attachments} />
      </div>
    </>
  )
}
