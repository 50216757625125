import { useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

import Row from 'components/common/Row'
import MainContainer from 'components/common/MainContainer'
import NoResults from 'components/common/NoResults'
import LeyendaProyecto from 'components/Projects/LeyendaProyecto'
import ZoneTable from 'components/Tables/ZoneTable'
import { ProjectForm } from 'components/forms'

import useModal from 'hooks/useModal'
import useProject from 'hooks/useProject'
import { patchApi } from 'redux/actions/fetchActions'
import {
  CERTIFIED,
  FINISHED,
  INCIDENCE,
  IN_PROGRESS,
  PENDING_VALIDATE
} from 'utils/constants'

import {
  BadgeSistel,
  BreadcrumbsSistel,
  ButtonSistel,
  ConfirmationModalSistel,
  DataGroupSistel,
  IconosSistel,
  LoaderSistel,
  ModalSistel
} from 'npm-styles-sistel'

export default function ProyectoDetalles({ match }) {
  const [showEdit, setShowEdit] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const { actions, title, show } = useModal()
  const { onClose, handleShow } = actions

  const [data, loading, refetch] = useProject(match.params.projectRef)
  const { project, clients } = data

  if (loading) return <LoaderSistel />

  const handleDeleteProject = () => {
    const goToProjects = () => history.push('/proyectos')
    try {
      dispatch(
        patchApi(project._id, `/project/${project._id}`, [null, goToProjects])
      )
    } catch (error) {
      console.error(error)
    }
  }

  const hasOrders = project.orders.length

  return (
    <MainContainer>
      <div className="d-flex text-align-center justify-content-between mb-4">
        <div className="d-flex align-items-start">
          <BreadcrumbsSistel
            baseLink={() => history.push('/proyectos')}
            baseTitle="Proyectos"
            current={project.title}
          />
          {hasOrders ? (
            <BadgeSistel className="ml-3" blue>
              En proceso
            </BadgeSistel>
          ) : (
            <BadgeSistel className="ml-3" grey>
              Sin empezar
            </BadgeSistel>
          )}
        </div>

        <div>
          {!hasOrders && (
            <ButtonSistel
              danger
              onClick={() => handleShow('Eliminar proyecto')}
              className="mr-2"
            >
              Eliminar proyecto
            </ButtonSistel>
          )}

          <ButtonSistel edit onClick={() => setShowEdit(true)}>
            Editar proyecto
          </ButtonSistel>
        </div>
      </div>

      <Row>
        <div className="col-md-4">
          <ProjectInfo project={project} />
        </div>
        <div className="col-md-8">
          <div className="card mb-5">
            <div className="d-flex">
              <IconosSistel icon="ordenes" />
              <h1 className="ml-2">Órdenes de trabajo</h1>
            </div>

            {hasOrders ? (
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <DataGroupSistel
                    title="Total órdenes"
                    data={project.orders.length}
                  />
                  <DataGroupSistel title="UUII diseñadas" data="FALTA HACER" />
                  <DataGroupSistel
                    title="UUII construidas"
                    data="FALTA HACER"
                  />
                </div>
                <OrdersChart orders={project.orders} />
                <LeyendaProyecto orders={project.orders} />
              </div>
            ) : (
              <NoResults text="No hay datos" />
            )}
          </div>
          <ZoneTable zones={project.zone} />
        </div>
      </Row>
      {show && (
        <ConfirmationModalSistel
          buttonType="button"
          modalFunction={handleDeleteProject}
          modalContent="¿Seguro que quieres eliminar el proyecto?"
          {...{ title, onClose }}
        />
      )}
      {showEdit && (
        <ModalSistel
          title={`Editar ${project.title}`}
          iconTitle="proyectos"
          handleClose={() => setShowEdit(false)}
          buttons={false}
        >
          <ProjectForm
            project={{ ...project, client: project.client._id }}
            handleClose={() => setShowEdit(false)}
            clients={clients}
            refetch={refetch}
          />
        </ModalSistel>
      )}
    </MainContainer>
  )
}

function ProjectInfo({ project }) {
  const startDate = new Date(project.startDate)
  const data = [
    {
      title: 'Referencia',
      data: project.ref
    },
    {
      title: 'Cliente',
      data: project.client.name
    },
    {
      title: 'Fecha de inicio',
      data: startDate.toLocaleDateString()
    },
    {
      title: 'Población',
      data: project.city
    },
    {
      title: 'Código postal',
      data: project.postalCode
    }
  ]
  return (
    <div className="card">
      <div className="d-flex">
        <IconosSistel icon="proyectos" />
        <h1 className="ml-3">{project.title}</h1>
      </div>
      <hr />
      {data.map((d) => (
        <DataGroupSistel key={d.title} title={d.title} data={d.data} />
      ))}
    </div>
  )
}

// TODO: Hacer un componente único con el que aparece en el dashboard?

function OrdersChart({ orders }) {
  const ordersData = {
    CERTIFIED: orders.filter((o) => o.status === CERTIFIED).length,
    FINISHED: orders.filter((o) => o.status === FINISHED).length,
    INCIDENCE: orders.filter((o) => o.status === INCIDENCE).length,
    IN_PROGRESS: orders.filter((o) => o.status === IN_PROGRESS).length,
    PENDING_VALIDATE: orders.filter((o) => o.status === PENDING_VALIDATE).length
  }

  const chartData = [
    { name: 'En proceso', value: ordersData[IN_PROGRESS] },
    { name: 'Finalizada', value: ordersData[FINISHED] },
    { name: 'Validación pendiente', value: ordersData[PENDING_VALIDATE] },
    { name: 'Certificadas', value: ordersData[CERTIFIED] },
    { name: 'Incidencia', value: ordersData[INCIDENCE] }
  ]

  const COLORS = ['#20C0E4', '#9AE154', '#FD9A3E', '#40C46C', '#FC4264']

  return (
    <div>
      <PieChart width={220} height={220}>
        <Pie
          data={chartData}
          cx={100}
          cy={100}
          innerRadius={50}
          outerRadius={105}
          fill="#8884d8"
          stroke="transparent"
          paddingAngle={0}
          dataKey="value"
        >
          {orders.map((_, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  )
}
