import OrderProgressBar from '../OrderProgressBar'

import { PENDING, VALIDATED } from 'utils/constants'

export default function FusionadorProgressBar({ order }) {
  const hasMandatoryData = order.brigade && order.status
  if (!hasMandatoryData) return null

  const boxEstimation = order.boxList.reduce(
    (acc, b) => acc + (parseInt(b.uuii) || 0),
    0
  )

  const getDeliveriesData = (status) =>
    order.employeeDeliveries
      .filter((ed) => ed.status === status)
      .reduce((acc, ed) => {
        const uuiis = order.boxList.find((b) => b._id === ed.boxId)?.uuii
        return uuiis ? acc + parseInt(uuiis) : acc
      }, 0)

  const getDefaultData = (status) =>
    order.employeeDeliveries.filter((ed) => ed.status === status).length

  const validatedDeliveries = boxEstimation
    ? getDeliveriesData(VALIDATED)
    : getDefaultData(VALIDATED)
  const pendingDeliveries = boxEstimation
    ? getDeliveriesData(PENDING)
    : getDefaultData(PENDING)

  const validationStatus = Math.round(
    (100 * validatedDeliveries) / (boxEstimation || order.boxList.length)
  )
  const pendingStatus = Math.round(
    (100 * pendingDeliveries) / (boxEstimation || order.boxList.length)
  )

  return (
    <OrderProgressBar validated={validationStatus} pending={pendingStatus} />
  )
}
