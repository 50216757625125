import { Link, useHistory } from 'react-router-dom'
import UserAvatar from './UserAvatar'
import NavbarLink from './NavbarLink'
import { NavbarSistel } from 'npm-styles-sistel'
import NavbarDropdown from './NavbarDropdown'
import { ADMIN, DESIGNER, RRHH, SUPERVISOR } from 'utils/constants'

export default function UserNavbar({ user }) {
  const history = useHistory()
  const goToProfile = () => history.push('/perfil')

  const routesHashmap = {
    [DESIGNER]: (
      <>
        <OrderDropdown />
        <DocumentationDropdown />
      </>
    ),
    [RRHH]: (
      <>
        <CompanyDropdown />
        <PointsDropdown />
      </>
    ),
    [SUPERVISOR]: (
      <>
        <OrderDropdown />
        <PointsDropdown />
        <CompanyDropdown role={user.role} />
        {/* <NavbarLink path="/informes" label="Informes" icon="informes" /> */}
        <DocumentationDropdown />
      </>
    )
  }

  const determineDisplay = user.role === ADMIN ? SUPERVISOR : user.role

  return (
    <NavbarSistel
      logo={
        <Link to="/">
          <img src="/images/logo.png" width="114" alt="Sistel Empleados" />
        </Link>
      }
      notifications={
        <UserAvatar image={user.avatar} user={user} {...{ goToProfile }} />
      }
    >
      {routesHashmap[determineDisplay]}
    </NavbarSistel>
  )
}

function OrderDropdown() {
  return (
    <NavbarDropdown
      menu={
        <NavbarLink path="/ordenes" icon="ordenes" label="Órdenes" submenu />
      }
      content={
        <>
          <NavbarLink path="/ordenes" label="Órdenes activas" icon="ordenes" />
          <NavbarLink
            path="/ordenes/huecos"
            label="Órdenes con huecos"
            icon="ordenesHuecos"
          />
          <NavbarLink
            path="/ordenes/finalizadas"
            label="Órdenes finalizadas"
            icon="ordenesFinalizadas"
          />
        </>
      }
    />
  )
}

function PointsDropdown() {
  return (
    <NavbarDropdown
      menu={
        <NavbarLink
          path="/puntos"
          label="Puntos"
          width="20"
          icon="puntos"
          submenu
        />
      }
      content={
        <>
          <NavbarLink
            path="/puntos"
            label="Resumen mensual"
            width="20"
            icon="puntos"
          />
          <NavbarLink
            path="/puntos-gestion"
            label="Gestión de puntos"
            width="20"
            icon="puntos"
          />
        </>
      }
    />
  )
}

function CompanyDropdown({ role }) {
  return (
    <NavbarDropdown
      menu={
        <NavbarLink path="/empresa" label="Empresa" icon="empresa" submenu />
      }
      content={
        <>
          <NavbarLink
            path="/empleados"
            icon="empleados"
            width="14"
            label="Empleados"
          />
          <NavbarLink path="/brigadas" icon="brigadas" label="Brigadas" />
          <NavbarLink path="/vehiculos" icon="vehiculos" label="Vehículos" />
          <NavbarLink path="/proyectos" icon="proyectos" label="Proyectos" />
          <NavbarLink path="/clientes" icon="clientes" label="Clientes" />
          <NavbarLink path="/baremos" icon="baremos" label="Baremos" />
          <AdminRoutes display={role === ADMIN} />
        </>
      }
    />
  )
}

function DocumentationDropdown() {
  return (
    <NavbarDropdown
      menu={
        <NavbarLink
          path="/documentos"
          label="Documentos"
          icon="documentos"
          submenu
        />
      }
      content={
        <>
          <NavbarLink
            path="/documentos"
            label="Gestor documental"
            icon="documentos"
          />
          <NavbarLink
            path="/entregas"
            label="Últimas entregas"
            icon="documentos"
          />
        </>
      }
    />
  )
}

function AdminRoutes({ display }) {
  if (!display) return null
  return (
    <>
      <NavbarLink
        path="/configuracion"
        label="Configuración"
        icon="configuracion"
      />
    </>
  )
}
