import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { DESIGNER } from 'utils/constants'
import ProtectedRoute from './ProtectedRoute'

export default function RRHHRoute({ path, exact, component }) {
  const me = useSelector((s) => s.me)

  if (!me) return <Redirect to="/login" />

  const isAuthorized = me.role !== DESIGNER

  if (isAuthorized)
    return <ProtectedRoute {...{ path, component }} exact={exact} />

  return <Redirect to="/" />
}
