import { useMemo } from 'react'

import Table from './Table'
import LastDeliveryTitle from 'components/DocsManager/LastDeliveryTitle'
import DocumentationActions from 'components/DocsManager/DocumentationActions'

import useStateWithDep from 'hooks/useStateWithDep'

import { BadgeSistel } from 'npm-styles-sistel'

export default function LastDeliveriesTable({ data, handleDocument }) {
  const [deliveries, setDeliveries] = useStateWithDep(data)

  const getTypeData = (type, delivery) => {
    const dataHashMap = {
      FUSIONADOR: {
        badge: <BadgeSistel blue>{type}</BadgeSistel>,
        downloadData: delivery.boxPhotos
      },
      CELADOR: {
        badge: <BadgeSistel purple>{type}</BadgeSistel>,
        downloadData: { photos: delivery.photos }
      }
    }
    return dataHashMap[type]
  }

  const tableData = useMemo(
    () =>
      deliveries.map((delivery, i) => {
        const { badge, downloadData } = getTypeData(
          delivery.order.type,
          delivery
        )
        const validationDate = new Date(delivery.updatedAt)
        const handleClick = () => handleDocument(delivery._id)
        return {
          title: (
            <LastDeliveryTitle title={delivery.title} order={delivery.order} />
          ),
          validationDate: validationDate.toLocaleDateString('es-ES'),
          type: badge,
          documentAction: (
            <DocumentationActions
              {...{
                downloadName: delivery.title,
                downloadData,
                handleClick
              }}
            />
          ),
          key: i
        }
      }),
    [deliveries]
  )

  const headers = useMemo(
    () => [
      { header: 'Entrega', sort: true },
      { header: 'Fecha validación', sort: true },
      { header: 'Tipo' },
      { header: 'Documentar entrega', className: 'text-right pr-4' }
    ],
    []
  )

  return (
    <Table {...{ data: deliveries, tableData, headers, cb: setDeliveries }} />
  )
}
