import { IconosSistel } from 'npm-styles-sistel'
import { BrigadeColumnStyle } from './brigadeStyles'
import EmployeeCardList from './EmployeeCardList'

export default function BrigadeCard({ brigade, editBrigade }) {
  const { users, manager, driver, vehicle } = brigade

  return (
    <div className="col-md-4 ">
      <BrigadeColumnStyle brigade={brigade.type}>
        <div className="heading">
          <h3>{brigade.name}</h3>
          <button onClick={() => editBrigade(brigade)}>
            <IconosSistel icon="edit" color="white" />
          </button>
        </div>
        <div className="vehicle">
          <p>Vehículo asignado</p>
          <h4>
            <IconosSistel
              icon="vehiculos"
              color={
                brigade.type === 'FUSIONADOR'
                  ? '#177CC6'
                  : brigade.type === 'CELADOR'
                  ? '#5F35FF'
                  : brigade.type === 'ALTERO'
                  ? '#FD683E'
                  : '#221C38'
              }
            />
            {vehicle?.plate || '-'}
          </h4>
        </div>
        <div className="main-content">
          <EmployeeCardList {...{ users, manager, driver }} />
        </div>
      </BrigadeColumnStyle>
    </div>
  )
}
