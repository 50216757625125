import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import useCoords from 'hooks/useCoords'
import styled from 'styled-components'
import { MAP_KEY } from 'utils/constants'

export const StyledMap = styled.div`
  height: 400px;
  width: 100%;
  margin-bottom: 1.4em;
  border: 2px solid #221c3855;
  border-radius: 15px;
  overflow: hidden;
`

export const convertCoordsFromString = (coords) => ({
  lat: Number(coords.lat),
  lng: Number(coords.lng)
})

export default function Map({ plate, externalCenter }) {
  const { coords, center } = useCoords(plate)

  if (!coords) {
    return null
  }

  const defaultCenter = externalCenter
    ? convertCoordsFromString(externalCenter)
    : convertCoordsFromString(center)

  return (
    <StyledMap>
      <LoadScript googleMapsApiKey={MAP_KEY}>
        <GoogleMap
          center={defaultCenter}
          zoom={externalCenter ? 15 : 10}
          mapContainerStyle={{ height: '400px', width: '100%' }}
        >
          {coords ? (
            <Marker title={plate} position={convertCoordsFromString(coords)} />
          ) : null}
          <></>
        </GoogleMap>
      </LoadScript>
    </StyledMap>
  )
}
