import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import useMultipleCoords from 'hooks/useMultipleCoords'
import { useHistory } from 'react-router'
import { MAP_KEY } from 'utils/constants'
import { convertCoordsFromString, StyledMap } from './Map'

export default function MultipleMarkersMap({ plates }) {
  const history = useHistory()
  const { coords } = useMultipleCoords(plates)
  const defaultCenter = {
    lat: 41.451543,
    lng: 2.171822
  }

  const goDetails = (plate, coords) => {
    const { lat, lng } = coords
    history.push(`/vehiculos/${plate}`, { lat, lng })
  }

  return (
    <StyledMap>
      <LoadScript googleMapsApiKey={MAP_KEY}>
        <GoogleMap
          zoom={10}
          center={defaultCenter}
          mapContainerStyle={{ height: '400px', width: '100%' }}
        >
          {coords.map(({ coords, plate }, i) => {
            if (!coords || !coords.length) return null
            return (
              <Marker
                title={plate}
                position={convertCoordsFromString(coords)}
                key={i}
                onClick={() => goDetails(plate, coords)}
              />
            )
          })}
        </GoogleMap>
      </LoadScript>
    </StyledMap>
  )
}
