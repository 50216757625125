import { InputCheckboxSistel } from 'npm-styles-sistel'
import { useEffect, useState } from 'react'
import useDebounce from './useDebounce'

const initialFilters = {
  search: '',
  type: [],
  workType: [],
  brigade: [],
  project: [],
  force: false
}

const FILTER_KEYS = ['type', 'workType', 'brigade', 'project']

export default function useOrderFilters(selectors) {
  const [filters, setFilters] = useState(initialFilters)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    setFilters((prev) => ({ ...prev, search: debouncedSearch, force: true }))
  }, [debouncedSearch])

  useEffect(() => {
    if (filters.force) setFilters((prev) => ({ ...prev, force: false }))
  }, [filters.force])

  const handleSearch = ({ target }) => setSearch(target.value)

  const handleCheckbox =
    (filtersKey, value) =>
    ({ target }) => {
      if (!target.checked) {
        return setFilters((prev) => ({
          ...prev,
          force: true,
          [filtersKey]: prev[filtersKey].filter((id) => id !== value)
        }))
      }
      setFilters((prev) => ({
        ...prev,
        force: true,
        [filtersKey]: [...prev[filtersKey], value]
      }))
    }

  const [typeFilter, workFilter, brigadeFilter, projectFilter] =
    FILTER_KEYS.map((key) =>
      useOrderCheckbox(key, selectors[key], handleCheckbox)
    )

  const filtersComponent = (
    <div className="d-flex" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
      <div style={{ minWidth: '200px' }}>
        {typeFilter}
        {workFilter}
        {brigadeFilter}
      </div>
      <div>{projectFilter}</div>
    </div>
  )

  return [filtersComponent, handleSearch, filters]
}

function useOrderCheckbox(filterKey, selectorsArray, handleCheckbox) {
  const title = {
    type: 'Tipo de orden',
    workType: 'Tipo de trabajo',
    brigade: 'Brigadas',
    project: 'Proyecto'
  }[filterKey]

  const displayCheckboxFilter = selectorsArray.map(({ _id, name }) => (
    <InputCheckboxSistel
      key={name}
      label={name}
      onChange={handleCheckbox(filterKey, _id)}
    />
  ))
  return (
    <>
      <h4>{title}</h4>
      {displayCheckboxFilter}
    </>
  )
}
