import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { ButtonSistel, InputSistel } from 'npm-styles-sistel'

import { putApi } from 'redux/actions/fetchActions'
import { putCompany } from 'redux/actions/companyActions'

import Row from 'components/common/Row'

export default function ConfigForm({ company }) {
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: initialValues(company),
    validateOnChange: false,
    onSubmit: (values) => {
      const instanceBody = {
        ...company,
        cloudinary: values.cloudinary,
        contact: values.contact
      }
      dispatch(putApi(instanceBody, '/company', [putCompany, () => {}]))
    }
  })

  const { values, handleSubmit, errors, handleChange } = formik

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <InputSistel
          type="text"
          label="Cloudinary name"
          name="cloudinary.name"
          col="col-4"
          value={values.cloudinary.name}
          errors={errors.cloudinary?.name}
          onChange={handleChange}
        />
        <InputSistel
          type="text"
          label="Cloudinary API Key"
          name="cloudinary.AK"
          col="col-4"
          value={values.cloudinary.AK}
          errors={errors.cloudinary?.AK}
          onChange={handleChange}
        />
        <InputSistel
          type="password"
          label="Cloudinary API Secret"
          name="cloudinary.AS"
          col="col-4"
          value={values.cloudinary.AS}
          errors={errors.cloudinary?.AS}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <InputSistel
          type="email"
          label="Correo electrónico RRHH"
          name="contact.RRHH"
          col="col-4"
          value={values.contact.RRHH}
          errors={errors.contact?.RRHH}
          onChange={handleChange}
        />
        <InputSistel
          type="email"
          label="Correo electrónico dirección"
          name="contact.management"
          col="col-4"
          value={values.contact.management}
          errors={errors.contact?.management}
          onChange={handleChange}
        />
      </Row>
      <Row className="flex-row-reverse">
        <ButtonSistel success type="submit">
          Guardar
        </ButtonSistel>
      </Row>
    </form>
  )
}

function initialValues(company) {
  return {
    cloudinary: {
      name: company?.cloudinary?.name || '',
      AK: company?.cloudinary?.AK || '',
      AS: company?.cloudinary?.AS || ''
    },
    contact: {
      RRHH: company?.contact?.RRHH || '',
      management: company?.contact?.management || ''
    }
  }
}
