import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'

import { login } from 'redux/actions/meActions'
import { BASE_PATH } from 'utils/constants'
import { authorize } from 'services/axios'
import { ButtonSistel, InputSistel } from 'npm-styles-sistel'
import { LoginStyled } from './LoginStyle'

export default function Login() {
  const me = useSelector((state) => state.me)

  if (me) {
    return <Redirect to="/" />
  }

  return (
    <LoginStyled>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 all-content">
            <div className="content">
              <img src="/images/logo-login.png" alt="Sistel Empleados" />
              <h1>Let's work!</h1>
              <LoginForm />
            </div>
            <p className="copyright">Sistel®. Todos los derechos reservados</p>
          </div>
        </div>
      </div>
    </LoginStyled>
  )
}

function LoginForm() {
  const [form, setForm] = useState({ dni: '', password: '' })
  const history = useHistory()
  const dispatch = useDispatch()

  const handleChange = (e) =>
    setForm({ ...form, [e.target.name]: e.target.value })

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const data = await authFetch()
      authorize(data.token)
      dispatch(login(data))
    } catch (error) {
      console.error(error)
    } finally {
      history.push('/')
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <div className="row">
        <InputSistel
          type="text"
          placeholder="DNI"
          name="dni"
          col="col-md-12"
          value={form.dni}
          onChange={handleChange}
        />
        <InputSistel
          type="password"
          placeholder="Contraseña"
          name="password"
          col="col-md-12"
          value={form.password}
          onChange={handleChange}
        />
        <ButtonSistel secondary>Acceder</ButtonSistel>
      </div>
    </form>
  )

  async function authFetch() {
    const options = {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(form)
    }
    const response = await fetch(`${BASE_PATH}/login`, options)
    if (!response.ok) throw await response.text()
    const data = await response.json()
    return data
  }
}
