import { LoaderSistel } from 'npm-styles-sistel'

import ProjectDetails from 'components/DocsManager/ProjectDetails'
import BreadCrumbs from 'components/DocsManager/BreadCrumbs'

import MainContainer from 'components/common/MainContainer'
import useDocumentsProject from 'hooks/useDocumentsProject'

export default function GestorDocumentalProyecto({ match }) {
  const { projectRef } = match.params

  const [project, fetching] = useDocumentsProject(projectRef)

  if (fetching) return <LoaderSistel />

  return (
    <MainContainer>
      <BreadCrumbs projectTitle={project.title} projectRef={projectRef} />
      <ProjectDetails project={project} />
    </MainContainer>
  )
}
