import { mdiArrowLeft } from '@mdi/js'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { ButtonSistel } from 'npm-styles-sistel'

export default function Header({
  title,
  buttonText,
  buttonAction,
  path,
  buttonText2,
  buttonAction2
}) {
  const history = useHistory()

  const action = () => (buttonText ? history.push(`${path}`) : history.goBack())

  const classNames = buttonText
    ? 'd-flex align-items-center justify-content-between mb-5'
    : 'd-flex align-items-center mb-5'

  const goBack = !buttonText && (
    <ButtonSistel onClick={action} className="mr-4" square primary>
      <Icon path={mdiArrowLeft} size={1} />
    </ButtonSistel>
  )

  const buttonGreen = buttonText && (
    <ButtonSistel success onClick={buttonAction || action} className="ml-2">
      {buttonText}
    </ButtonSistel>
  )

  const buttonRed = buttonText2 && (
    <ButtonSistel danger onClick={buttonAction2 || action}>
      {buttonText2}
    </ButtonSistel>
  )
  //test
  return (
    <header className={classNames}>
      {goBack}
      <h1>{title}</h1>
      <div>
        {buttonRed}
        {buttonGreen}
      </div>
    </header>
  )
}

Header.propTypes = {
  buttonText: PropTypes.string,
  path: PropTypes.string
}
