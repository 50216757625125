import { useState } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import { BrigadeInfoStyled } from './brigadeStyles'
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import EmployeeCardList from './EmployeeCardList'

export default function WithoutBrigadeColumn({ users }) {
  const [open, setOpen] = useState(false)
  const toggleCollapse = () => setOpen(!open)

  return (
    <>
      <BrigadeInfoStyled
        employees
        open={open}
        aria-controls="empleados"
        aria-expanded={open}
      >
        <div className="title" onClick={toggleCollapse}>
          <h3>Empleados sin brigada</h3>
          <Icon path={open ? mdiChevronUp : mdiChevronDown} size={1} />
        </div>
        <Collapse in={open}>
          <div id="empleados" className="px-3 pb-3">
            <EmployeeCardList users={users} />
          </div>
        </Collapse>
      </BrigadeInfoStyled>
    </>
  )
}
