export const GET_COMPANY = 'GET_COMPANY'
export const PUT_COMPANY = 'PUT_COMPANY'

export const getCompany = (data) => ({
  type: GET_COMPANY,
  payload: data
})

export const putCompany = (data) => ({
  type: PUT_COMPANY,
  payload: data
})
