import ActionButton from 'components/common/ActionButton'
import { InputSistel, ButtonSistel } from 'npm-styles-sistel'
import { CELADOR } from 'utils/constants'

export default function BoxList({
  cluster,
  type,
  boxList,
  handleChange,
  addBox,
  addMultipleBoxes,
  removeBox,
  boxQty
}) {
  if (type === CELADOR || !cluster) return null

  const displayBoxListInputs = boxList.map((box, i) => (
    <div className="row" key={i}>
      <InputSistel
        label="Nombre de la caja"
        type="text"
        col="col-md-5"
        value={box.name}
        onChange={handleChange}
        name={`boxList[${i}].name`}
      />
      <InputSistel
        label="Cantidad de UUII"
        type="text"
        col="col-md-5"
        value={box.uuii}
        onChange={handleChange}
        name={`boxList[${i}].uuii`}
      />
      <div className="col-md-2 d-flex align-items-center">
        <ActionButton isDelete type="button" onClick={() => removeBox(i)} />
      </div>
    </div>
  ))

  return (
    <>
      <div className="col-md-12">
        <p className="subtitle-form">Cajas</p>
        <div className="my-3">
          <ButtonSistel success className="my-3" type="button" onClick={addBox}>
            Nueva caja
          </ButtonSistel>
          <ButtonSistel
            primary
            className="my-3 ml-3"
            type="button"
            onClick={addMultipleBoxes}
          >
            Añadir {boxQty} cajas
          </ButtonSistel>
        </div>
      </div>
      <div className="col-md-12">{displayBoxListInputs}</div>
    </>
  )
}
