import { useMemo } from 'react'
import Table from './Table'
import useStateWithDep from 'hooks/useStateWithDep'

export default function BaremosFilterTable({ data, cb }) {
  const [filters, setFilters] = useStateWithDep(data)

  const tableData = useMemo(
    () =>
      filters.map((f) => ({
        title: (
          <p className="table-link" onClick={() => cb(f)}>
            {f.title}
          </p>
        ),
        baremos: f.baremoList.map((b) => <p key={b._id}>{b.description}</p>),
        key: f._id
      })),
    [filters]
  )

  const headers = useMemo(
    () => [
      { header: 'Título', sort: false },
      { header: 'Baremos', sort: false }
    ],
    []
  )

  return (
    <Table
      {...{
        data: filters,
        tableData,
        headers,
        cb: setFilters,
        col: 'col-md-12'
      }}
    />
  )
}
