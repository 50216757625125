import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import Table from './Table'

import { euroFormat } from 'utils/strings'
import useStateWithDep from 'hooks/useStateWithDep'

export default function MonthlyCloseTable({ data, company, monthList }) {
  const [users, setUsers] = useStateWithDep(data)
  const [month, setMonth] = useState(null)
  const [isFormatted, setIsFormatted] = useState(false)

  useEffect(() => {
    if (monthList.length && month) {
      setIsFormatted(false)
      const formattedUsers = data
        .flatMap((user) => {
          const foundElement = user.pointHistory.find(
            (ph) =>
              new Date(ph.createdAt).toLocaleDateString('es-ES', {
                month: '2-digit',
                year: '2-digit'
              }) === month
          )
          return foundElement ? { ...user, pointFound: foundElement } : []
        })
        .map((user) => {
          const {
            points,
            monthlyGoal,
            pointPrice = company.config.pointPrice
          } = user.pointFound

          const extraPoints = points > monthlyGoal ? points - monthlyGoal : 0

          return {
            employee: (
              <Link className="table-link" to={`/empleados/${user._id}`}>
                {`${user.name} ${user.lastname}`}
              </Link>
            ),
            target: user.target,
            points: Math.round(points),
            extraPoints: Math.round(extraPoints),
            pointPrice: euroFormat(pointPrice),
            total: euroFormat(extraPoints * pointPrice),
            key: user._id
          }
        })
        .sort((u1, u2) => u2.extraPoints - u1.extraPoints)

      setUsers(formattedUsers)
      setIsFormatted(true)
    }
  }, [month])

  const headers = useMemo(
    () => [
      { header: 'Empleado' },
      { header: 'Objetivo' },
      { header: 'Total de puntos' },
      { header: 'Puntos extra' },
      { header: 'Precio/punto' },
      { header: 'Total' }
    ],
    []
  )

  const handleChange = (e) => setMonth(e.value)

  return monthList.length ? (
    <>
      <Select className="mb-4" options={monthList} onChange={handleChange} />
      {month && isFormatted && (
        <Table {...{ data: users, tableData: users, headers, cb: setUsers }} />
      )}
    </>
  ) : null
}
