export function checkBoxList(oldBoxes, newBoxes) {
  const hasSameOrLowerLength = newBoxes.length <= oldBoxes.length
  const hasSameBoxes = newBoxes.every((box) =>
    oldBoxes.find(
      (_box) =>
        box.uuii === _box.uuii &&
        box.name === _box.name &&
        box.status === _box.status &&
        box._id === _box._id
    )
  )
  return !(hasSameBoxes && hasSameOrLowerLength)
}
