import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_FILTERS,
  POST_FILTER,
  PUT_FILTER
} from '../actions/baremoFilterActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_FILTERS:
      return { data: [...action.payload], loaded: true }
    case POST_FILTER:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_FILTER:
      return {
        ...state,
        data: state.data.map((filter) =>
          filter._id === action.payload._id
            ? { ...filter, ...action.payload }
            : filter
        )
      }
    default:
      return state
  }
}
