import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { InputSelectSistel, InputSistel } from 'npm-styles-sistel'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postApi, putApi } from 'redux/actions/fetchActions'
import { postFilter, putFilter } from 'redux/actions/baremoFilterActions'

export default function BaremoFilterForm({ data, baremos, handleClose }) {
  const dispatch = useDispatch()
  const [currentBrigade] = useState(initialValues(data))

  const formik = useFormik({
    initialValues: currentBrigade,
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: (values) => {
      const instanceBody = {
        ...values,
        baremoList: values.baremoList.map((e) => e.value)
      }

      if (data)
        return dispatch(
          putApi(instanceBody, `/baremofilter/${values._id}`, [
            putFilter,
            handleClose
          ])
        )

      dispatch(
        postApi(instanceBody, '/baremofilter', [postFilter, handleClose])
      )
    }
  })

  const { handleSubmit, errors, handleChange, values, setFieldValue } = formik
  const handleSelectMulti = (field) => (e) => setFieldValue(field, e)

  const { title, baremoList } = values

  const baremoOptions = baremos.map((b) => ({
    label: b.description,
    value: b._id
  }))

  return (
    <form className="mt-5" onSubmit={handleSubmit} id="form">
      <div className="row">
        <InputSistel
          type="text"
          label="Título"
          name="title"
          col="col-10"
          value={title}
          errors={errors.title}
          onChange={handleChange}
        />
        <InputSelectSistel
          label="Baremos"
          isMulti
          name="baremoList"
          col="col-10"
          placeholder="Selecciona el rol..."
          value={baremoList}
          errors={errors.baremoList}
          options={baremoOptions}
          onChange={handleSelectMulti('baremoList')}
        />
      </div>
    </form>
  )
}

function initialValues(filter) {
  const formattedBaremoList = filter?.baremoList.map((b) => {
    return {
      label: b.description,
      value: b._id
    }
  })

  return {
    _id: filter?._id || undefined,
    title: filter?.title || '',
    baremoList: formattedBaremoList || []
  }
}

function validationSchema() {
  return {
    title: Yup.string(),
    baremoList: Yup.array().min(1)
  }
}
