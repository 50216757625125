import { IN_PROGRESS, PENDING_VALIDATE } from './constants'

export function getOrdersByStatus(ordersArray) {
  const unassignedOrders = ordersArray.filter(({ brigade }) => !brigade)
  const assignedOrders = ordersArray.filter(filterByStatus(undefined)) // Only assigned. With brigade but without status
  const ordersInProgress = ordersArray.filter(filterByStatus(IN_PROGRESS))
  const pendingValidationOrders = ordersArray.filter(
    filterByStatus(PENDING_VALIDATE)
  )

  return {
    unassignedOrders,
    assignedOrders,
    ordersInProgress,
    pendingValidationOrders
  }
}
// FIXME: Function required at getOrderByStatus.
export const filterByStatus = (statusType) => (order) =>
  order.brigade && order.status === statusType

// FIXME: Reusable function.
export function filterOrders(ordersArr, statusRequired) {
  if (!ordersArr.length) return []
  return ordersArr.filter(({ status }) => status === statusRequired)
}

export const getPointsOfMonth = (lastClose) => (point) => {
  const dateToCompare = new Date(point.createdAt).getTime()
  const referenceDate = new Date(lastClose)
  return dateToCompare > referenceDate
}
