import SimpleTable from 'components/Tables/SimpleTable'
import { useMemo } from 'react'
import GapReason from './GapReason'

export default function GapsDetails({ gaps }) {
  const headers = useMemo(
    () => [
      { header: 'Caja', sort: false, className: 'text-left' },
      { header: 'Comentario', sort: false },
      { header: 'Motivo', sort: false, className: 'text-right' }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      gaps.map((gap, i) => ({
        box: gap.box,
        comment: gap.comment,
        reason: {
          value: <GapReason gap={gap.reason} />,
          className: 'text-right'
        },
        key: i
      })),
    []
  )

  return <SimpleTable {...{ tableData, headers }} />
}
