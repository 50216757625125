import { Field, FieldArray } from 'formik'

export default function BaremoEntries({ data }) {
  return (
    <div className="col-md-12 baremos-delivery">
      <div className="baremo-title">
        <p>Baremos</p>
        <p>Cantidad</p>
      </div>
      <FieldArray
        name="baremoEntries"
        render={() =>
          data.baremoEntries.map(({ baremo }, index) => (
            <div
              className="baremo-list"
              key={baremo._id}
            >
              <label>{baremo.description}</label>
              <Field
                name={`baremoEntries[${index}].qty`}
                value={data.baremoEntries[index].qty}
                type="number"
              />
            </div>
          ))
        }
      />
    </div>
  )
}
