import { InputSistel } from 'npm-styles-sistel'
import { CELADOR } from 'utils/constants'

export default function UUIIInput({
  orderType,
  uuiiDesigned,
  errors,
  handleChange
}) {
  if (orderType === CELADOR) return null

  return (
    <InputSistel
      type="number"
      label="UUII diseñadas"
      col="col-md-4"
      min="0"
      name="uuiiDesigned"
      value={uuiiDesigned}
      errors={errors}
      onChange={handleChange}
    />
  )
}
