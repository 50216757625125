import { defaultState } from 'redux/utils/stateCreator'
import {
  GET_BRIGADES,
  PATCH_BRIGADE,
  POST_BRIGADE,
  PUT_BRIGADE,
  UPDATE_BRIGADE_USERS
} from '../actions/brigadeActions'

export default (state = defaultState(), action) => {
  switch (action.type) {
    case GET_BRIGADES:
      return { data: [...action.payload], loaded: true }
    case POST_BRIGADE:
      return { ...state, data: [...state.data, action.payload] }
    case PUT_BRIGADE:
      return {
        ...state,
        data: state.data.map((brigade) =>
          brigade._id === action.payload._id
            ? { ...brigade, ...action.payload }
            : brigade
        )
      }
    case UPDATE_BRIGADE_USERS: {
      const { brigade, newUsers } = action.payload
      return {
        ...state,
        data: state.data.map((br) => {
          // if (br._id === brigade) return br
          if (br._id !== brigade) return br

          return {
            ...br,
            users: newUsers
            // users: br.users.reduce(
            //   (prev, curr) =>
            //     newUsers.includes(curr) ? prev : [...prev, curr],
            //   []
            // )
          }
        })
      }
    }
    case PATCH_BRIGADE: {
      return {
        ...state,
        data: state.data.map((brigade) =>
          brigade._id === action.payload
            ? { ...brigade, active: !brigade.active }
            : brigade
        )
      }
    }
    default:
      return state
  }
}
